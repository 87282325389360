import React from 'react';
import AppFooter from '../components/footer/AppFooter';
import { useHistory } from 'react-router-dom';

const MainEntryPaymentAccepted = () => {
  const history = useHistory();

  /**
   * メインページへが押された時
   */
  const onClickToMain = () => {
    history.push('/main');
  };

  return (
    <body>
      <main>
        <article className="screen screen--message">
          <div className="message">
            <div className="message message__logo">
              <img src="/images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
            </div>

            <div className="message__title">
              <h1>選手情報登録・参加表明完了</h1>
            </div>

            <div className="message__icon animation--zoomIn">
              <img src="/images/okay.svg" alt="OK!" />
            </div>

            <div className="message__content">
              <p>
                開催されるツアー会場の場所、
                <br />
                日時の割り当てが決まるまで
                <br />
                しばらくお待ちください。
              </p>
            </div>

            <button className="button button__primary c-mt56 c-mb88" onClick={onClickToMain}>
              メインページへ
            </button>
          </div>
        </article>
      </main>

      <footer>
        <AppFooter />
      </footer>
    </body>
  );
};

export default MainEntryPaymentAccepted;
