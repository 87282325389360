import React, { useEffect, useState } from 'react';
import CopyRightFooter from '../components/footer/CopyRightFooter';
import MainFooter from '../components/footer/MainFooter';
import DesktopMenuHeader from '../components/header/DesktopMenuHeader';
import IconNext from '../components/icon/IconNext';
import Pagination from '../components/Pagination';
import { useHistory } from 'react-router-dom';
import { getNoticeCollection } from '../firebase/FireBaseManager';
import { ONE_PAGE_DISPLAY } from '../collections/NoticeCollection';

const NoticeInboxPage = () => {
  const history = useHistory();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  console.log(currentPageNumber);
  const [noticeList, setNoticeList] = useState([]);
  const [currentNoticeList, setCurrentNoticeList] = useState([]);
  const [noticeSize, setNoticeSize] = useState(0);
  const [unreadNoticeSize, setUnreadNoticeSize] = useState(0);

  /**
   * お知らせが選択された時
   */
  const onClickNoticeMessageButton = (data) => {
    history.push(`/notice/message/${data.id}`);
  };

  const handleSearchNotice = (page) => {
    return setCurrentNoticeList(noticeList.slice(page * ONE_PAGE_DISPLAY - ONE_PAGE_DISPLAY, page * ONE_PAGE_DISPLAY));
  };

  useEffect(() => {
    const collection = getNoticeCollection();
    setNoticeList(collection.get());
    setNoticeSize(collection.countAll());
    // 1ページ目を表示
    setCurrentNoticeList(collection.getCurrentNotice(1));
    setUnreadNoticeSize(collection.countUnread());
  }, []);

  return (
    <body className="main">
      <header className="main__section">
        <div className="main__content main__content--upper main__header">
          <div className="isologo isologo--main">
            <a href="/main">
              <img src="/images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
            </a>
          </div>
          <DesktopMenuHeader />
        </div>
      </header>

      <main className="main__section main__section--last">
        <section>
          <div className="strip strip--player">
            <div className="strip__container">
              <div className="strip__flow"></div>

              <div className="strip__decoration strip__decoration--upper"></div>

              <div className="strip__title">
                <h1 className="c-heading--1 c-heading--center">お知らせ</h1>
              </div>

              <div className="strip__decoration strip__decoration--lower"></div>
            </div>
          </div>

          <div className="main__content">
            <div className="notice-counter">
              <p className="c-subtitle c-pr--8">お知らせが</p>
              <span className="c-tag c-subtitle animation--jelly">{unreadNoticeSize}件</span>
              <p className="c-subtitle c-pl--8">届いてます</p>
            </div>

            {/* お知らせ見出し一覧 */}
            <ul className="notice-list c-mt24">
              {currentNoticeList.map((data, index) => {
                return (
                  <li className="notice-list__item" key={index}>
                    <button
                      className={
                        data.view_flag === 0
                          ? 'notice-list__message notice-list__message--unread'
                          : 'notice-list__message notice-list__message--read'
                      }
                      onClick={() => onClickNoticeMessageButton(data)}
                    >
                      <h2 className="notice-list__message-title">{data.title}</h2>
                      <figure className="c-icon c-icon--16">
                        <IconNext />
                      </figure>
                    </button>
                  </li>
                );
              })}
            </ul>

            {noticeSize > 0 && (
              <div className="c-pagination c-mt24">
                <Pagination
                  dataSize={noticeSize}
                  onePageDisplay={ONE_PAGE_DISPLAY}
                  handleSearch={handleSearchNotice}
                  setCurrentPageNumber={setCurrentPageNumber}
                />
              </div>
            )}
          </div>
        </section>
      </main>

      <footer>
        <CopyRightFooter />
        <MainFooter />
      </footer>

      <script
        dangerouslySetInnerHTML={{
          __html: `
            document.addEventListener("touchstart", function() {},false);
          `,
        }}
      />
    </body>
  );
};

export default NoticeInboxPage;
