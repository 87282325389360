import 'moment/locale/ja';
import moment from 'moment';
import { initializeFireBase } from './firebase/FireBaseManager';
import { tourGetParticipationTournamentService } from './services/TournamentService';
import { checkPaymentStatus } from './services/PaymentService';

/**
 * アプリケーションの初期設定を行う
 * @return {Promise<boolean>}
 */
export const initialize = async () => {
  // moment
  moment.locale('ja', {
    weekdays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
    weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
  });

  // initialize fire base
  await initializeFireBase();

  // get tournament data
  await tourGetParticipationTournamentService();
  await checkPaymentStatus();

  return true;
};
