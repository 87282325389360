import React from 'react';
import AppFooterLogin from '../components/footer/AppFooterLogin';

export default function MaintenancePage() {
  return (
    <body>
      <main>
        <article className="screen screen--message">
          <div className="message">
            <div className="message message__logo">
              <img src="images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
            </div>

            <div className="message__title">
              <h1>メンテナンス中です</h1>
            </div>

            <div className="message__icon animation--wobble">
              <img src="images/icon-maintenance.svg" alt="!" />
            </div>

            <div className="message__content">
              <p>アクセス過多によりサーバーがダウンしており、現在復旧作業を行っております。</p>
              <p>ご迷惑をおかけしまして誠に大変申し訳ありませんが、</p>
              <p>しばらく経ってからアクセス頂きますようよろしくお願い申し上げます。</p>
            </div>
          </div>
        </article>
      </main>

      <footer>
        <AppFooterLogin />
      </footer>

      <script></script>
    </body>
  );
}
