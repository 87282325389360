import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import LoadingScreen from '../components/LoadingScreen';
import { tourGetTournamentPaymentUserDataService } from '../services/TournamentService';
import { PAYMENT_STATUSES } from '../services/PaymentService';

const CheckRedirectPayment = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { tournament_id } = useParams();

  const handleGetPaymentStatus = async () => {
    setLoading(true);

    const paymentData = await tourGetTournamentPaymentUserDataService(tournament_id);
    // 決済成功時
    if (
      paymentData.payment_data.status === PAYMENT_STATUSES.CAPTURE ||
      paymentData.payment_data.status === PAYMENT_STATUSES.SALES
    ) {
      history.push('/main-entry-payment-successed');
    } else {
      history.push({
        pathname: '/main-entry-payment-rejected',
        state: {
          tournament_id: tournament_id,
        },
      });
    }
    // TODO: リファクタ(決済系の処理をserviceに移動)
    // getPaymentStatus({ OrderID: paymentData.payment_data.orderId })
    //   .then(async (res) => {
    //     console.log(res);
    //     // 正常に決済完了した場合
    //     if (
    //       res?.data?.message?.PaymentStatus === PAYMENT_STATUSES.CAPTURE ||
    //       res?.data?.message?.PaymentStatus === PAYMENT_STATUSES.SALES
    //     ) {
    //       await tourSetTournamentPaymentUserDataService(tournament_id, {
    //         status: res?.data?.message?.PaymentStatus,
    //       });
    //       await tourSetTournamentEntryFlgService(tournament_id, UserTournamentEntryStatus.ENTRY)
    //         .then(() => {
    //           history.push('/main-entry-payment-successed');
    //         })
    //         .catch(() => {
    //           history.push('/main-entry-payment-rejected');
    //         });
    //     } else {
    //       // orderIdを消す
    //       await tourSetTournamentPaymentUserDataService(tournament_id, {
    //         orderId: '',
    //         status: res?.data?.message?.PaymentStatus,
    //         failedOrderId: paymentData.payment_data.orderId,
    //       });
    //       history.push('/main-entry-payment-rejected');
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     setLoading(false);
    //   });
  };

  useEffect(() => {
    handleGetPaymentStatus();
  }, []);

  if (loading) return <LoadingScreen />;

  return null;
};

export default CheckRedirectPayment;
