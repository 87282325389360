import React from 'react';

const EmailConfirmRequestPage = () => {
  return (
    <body>
      <article className="screen screen--email">
        <div className="message">
          <div className="message message__logo">
            <img src="images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
          </div>

          <div className="message__title">
            <h1>登録確認メールを送信しました。</h1>
          </div>

          <div className="message__icon animation--shake">
            <img src="images/email-orange.svg" alt="email" />
          </div>

          <div className="message__content">
            <p>
              登録されたメールアドレスにメール
              <br />
              確認のメールを送信しましたので
              <br />
              認証をおこなってください。
            </p>
          </div>
        </div>
      </article>
    </body>
  );
};

export default EmailConfirmRequestPage;
