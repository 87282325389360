import React from 'react';

const IconPageNationNext = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 28">
    <defs />
    <path
      d="M14 28a1.994 1.994 0 01-1.414-.586l-12-12a2 2 0 010-2.828l12-12a2 2 0 012.828 2.828L4.828 14l10.586 10.586A2 2 0 0114 28z"
      data-name="Path 1754 - Outline"
    />
  </svg>
);

export default IconPageNationNext;
