import React from 'react';
import { useHistory } from 'react-router-dom';
import CopyRightFooter from '../../components/footer/CopyRightFooter';
import MainFooter from '../../components/footer/MainFooter';

export default function CommunicationErrorPage() {
  const history = useHistory();

  return (
    <body>
      <main>
        <article className="screen screen--message">
          <div className="message">
            <div className="message message__logo">
              <img src="images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
            </div>

            <div className="message__title">
              <h1>通信エラーが発生しました。</h1>
            </div>

            <div className="message__icon animation--wobble">
              <img src="images/alert.svg" alt="!" />
            </div>

            <div className="message__content">
              <p>もう一度やり直してください。</p>
            </div>

            <button
              className="button button__primary c-mt56 c-mb88"
              onClick={() => {
                history.push('/main');
              }}
            >
              戻る
            </button>
          </div>
        </article>
      </main>

      <footer>
        <CopyRightFooter />
        <MainFooter />
      </footer>

      <script></script>
    </body>
  );
}
