/**
 * Firebase側で管理されるユーザ情報の型
 */
export class FirebaseUserModel {
  constructor(init = {}) {
    this.displayName = '';
    this.email = '';
    this.emailVerified = false;
    this.phoneNumber = '';
    this.photoURL = '';
    this.uid = '';
    this.accessToken = '';
    this.prividerData = '';

    this.set(init);
  }

  /**
   * メールアドレスが認証済みかどうか
   * @return {boolean}
   */
  isVerifiedEmail() {
    return this.emailVerified;
  }

  /**
   * set
   * @param {Object} init
   */
  set(init) {
    // validate
    if (init === undefined || init === null) return;

    // assign
    Object.assign(this, init);

    return this;
  }
}

/**
 * FireStore側で管理されるユーザ情報の型
 */
export class AppUserModel {
  constructor(init = {}) {
    this.version = '';

    this.given_name_kanji = '';
    this.family_name_kanji = '';
    this.given_name_kana = '';
    this.family_name_kana = '';

    this.birthday = '';
    this.age = 0;
    this.sex = '';
    this.phone_number = '';
    this.email = '';

    this.postal_code = '';
    this.address_level1 = '';
    this.address_level2 = '';
    this.address_line1 = '';
    this.address_line2 = '';
    this.address_line3 = '';
    this.occupation = '';

    this.handicap = '';

    // ツアー参加回数（配列[0]=関東,[1]=関西,[2]=今後・・・）
    this.tour_counts = [];
    this.ranking_points = '';
    this.reg_date = '';
    this.serial_nb = '';

    this.technical_test_area = -1; // 技能テストエリア 0 = 関東, 1 = 関西(-1は未選択)
    this.technical_test_flg = null; // 技術テストの合否 0=不合格、1=合格
    this.delete_flg = 0; // 削除フラグ0=未削除、1=削除
    this.delete_comment = '';

    this.order_id = '';
    this.order_id_paymnet = '';
    this.paid_skill_test = '';

    this.set(init);
  }

  /**
   * set
   * @param {Object} init
   */
  set(init) {
    // validate
    if (init === undefined || init === null) return;

    // assign
    Object.assign(this, init);

    return this;
  }

  /**
   * @returns {Boolean}
   */
  isSet() {
    return this.given_name_kanji !== '' ? true : false;
  }

  /**
   * 子供かどうか判定
   */
  isChild() {
    const child = ['学生(小学生)', '学生(中学生)', '学生(高校生)', '学生(専門・大学)'];

    return child.includes(this?.occupation);
  }
}

/**
 * FireStore側で管理されるプレイヤー情報の型
 */
export class AppPlayerModel {
  constructor(init = {}) {
    this.version = '';

    this.shozoku = ''; // 所属
    this.image_url = ''; // 画像URL
    this.height = ''; // 身長
    this.body_weight = ''; // 体重
    this.blood_type = ''; // 血液型
    this.birthplace = ''; // 出身地
    this.school = ''; // 学校
    this.good_club = ''; // 得意クラブ
    this.beginning_age = 0; // ゴルフを始めた時の年齢
    this.motivation_start = ''; // ゴルフを始めた動機
    this.average_distance = 0; // ドライバー平均飛距離(ヤード)
    this.best_score = ''; // ベストスコア
    this.target_person = ''; // 目標とする人

    // 口座情報
    this.bank_info = {
      bank_name: '',
      branch_name: '',
      account_type: '',
      account_number: '',
      account_holder: '',
    };

    this.set(init);
  }

  /**
   * set
   * @param {Object} init
   */
  set(init) {
    // validate
    if (init === undefined || init === null) return;

    // assign
    Object.assign(this, { ...init });

    return this;
  }

  /**
   * @returns {Boolean}
   */
  isSet() {
    return this.height !== '' ? true : false;
  }
}
