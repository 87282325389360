import React, { useMemo } from 'react';
import { getRedirectUrlByUserStatus, useUserStatus } from '../../firebase/FireBaseManager';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { UserAccountStatus } from '../../const/UserConst';

/**
 * ログインしていないと表示できない画面の制御に使用
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PrivateRouterParts = (props) => {
  const { exact, path, allowUserStatus, redirectUrl, component } = props;

  const location = useLocation();
  const authUserStatusHook = useUserStatus();

  const currentStatus = useMemo(() => {
    return authUserStatusHook();
  }, [authUserStatusHook, allowUserStatus, location]);

  if (currentStatus >= allowUserStatus) {
    return (
      <Route exact={exact} path={path} component={component}>
        {props.children}
      </Route>
    );
  }

  if (redirectUrl != null && redirectUrl !== '') {
    return <Redirect to={redirectUrl} />;
  }

  return <Redirect to={getRedirectUrlByUserStatus(currentStatus)}></Redirect>;
};

PrivateRouterParts.defaultProps = {
  exact: true,
  redirectUrl: '',
  allowUserStatus: UserAccountStatus.ENABLE,
};

export default PrivateRouterParts;
