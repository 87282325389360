import moment from 'moment';
import CourseDataModel from './CourseDataModel';
import { UserTournamentEntryStatus } from '../const/UserConst';

export default class TournamentDataModel {
  constructor(init = {}) {
    this.id = ''; // 大会ID
    this.title = ''; // 大会タイトル
    this.category = ''; // 「男子」、「女子」、「ジュニア」のどれか
    this.area = ''; // 「関東」、「関西」のどれか
    this.startMillisecond = 0; // 開催日時（ミリ秒）
    this.finishMillisecond = 0; // 終了日時
    this.entry_amount = 0; // エントリー料金
    this.group_match_interval = 0; // グループの試合間隔
    this.golfCourseDocId = ''; // rcKeNU8NQsnGAPpBg9DO（ゴルフ場のID）

    /**
     *
     * @type {{
     *   courseName: string,
     *   key: string,
     *   greenData: {
     *     key: string,
     *     name: string
     *   },
     *   teeData: {
     *     key: string,
     *     name: string
     *   }
     * }[]}
     */
    this.useCourseList = [];

    /**
     * @type {string[]}
     */
    this.pictureList = []; // 画像URLが配列で保存されている

    this.usePurpose = ''; // ツアー、実技テスト、イベント

    this.first_place = ''; // 1位賞金
    this.second_place = ''; // 	2位賞金
    this.third_place = ''; // 	3位賞金
    this.fourth_place = ''; // 	4位
    this.fifth_place = ''; // 	5位
    this.sixth_tenth = ''; // 	6位～10位
    this.eleventh_sixtieth = ''; // 	11位〜60位
    this.special_prize = ''; // 	副賞
    this.postponed_flg = ''; // 	0=通常、1=延期
    this.comment = ''; // 	削除や延期等のコメント
    this.delete_flg = ''; // 	削除フラグ0=未削除、1=削除
    this.allocationFlg = ''; // 	割り当てフラグ0=未割り振り、1=割り振り済（メイン画面にエントリーを表示させる為の割り振り）
    this.fix_flg = ''; // 	0=確定、1=未確定

    this.entryStartMillisecond = 0; // エントリー受付開始
    this.entryFinishMillisecond = 0; // エントリー受付終了

    this.golfCourseData = new CourseDataModel();
    this.max_entry = '';
    this.payment_data = {}; // 	ツアー支払い情報

    // NOTE: ここからはフロント側で足した情報
    this.userTournamentEntryStatus = UserTournamentEntryStatus.NOT_ENTRY;

    this.set(init);
  }

  /**
   * エントリー期間かどうかを判定する
   * @returns {boolean}
   */
  isEntryPeriod() {
    if (
      this.entryStartMillisecond == null ||
      this.entryStartMillisecond === '' ||
      this.entryFinishMillisecond == null ||
      this.entryFinishMillisecond === ''
    )
      return false;

    const today = moment();
    const start = moment(this.entryStartMillisecond);
    const end = moment(this.entryFinishMillisecond);

    return today.isAfter(start) && today.isBefore(end);
  }

  /**
   * ツアー延期
   * @returns {boolean}
   */
  isPostponed() {
    return this.postponed_flg === 1;
  }

  /**
   * ツアー延期(開催日未定)
   * @returns {boolean}
   */
  isPostponedPending() {
    return this.postponed_flg === 2;
  }

  /**
   * @param {number} status UserTournamentEntryStatus
   */
  setEntryStatus(status) {
    this.userTournamentEntryStatus = status;
  }

  /**
   * @returns {number} UserTournamentEntryStatus
   */
  getEntryStatus() {
    return this.userTournamentEntryStatus;
  }

  /**
   * @param {CourseDataModel} courseData
   */
  setCourseData(courseData) {
    this.golfCourseData = courseData;
  }

  /**
   * @returns {CourseDataModel}
   */
  getCourseData() {
    return this.golfCourseData;
  }

  /**
   * コース名の一覧を取得する
   * @returns {string[]}
   */
  getCourseNames() {
    if (this.useCourseList == null) return [];

    return this.useCourseList.map((_item) => _item.courseName);
  }

  /**
   * ティーデータを取得する
   * @return {string}
   */
  getTees() {
    if (this.useCourseList == null || this.useCourseList.length === 0) return '';

    return this.useCourseList[0]?.teeData?.name || '';
  }

  /**
   * 画像一覧を取得する
   * @returns {string[]}
   */
  getPictureList() {
    return this.pictureList.filter((_item) => _item != null && _item !== '');
  }

  /**
   * set
   * @param {Object} init
   */
  set(init) {
    // validate
    if (init === undefined || init === null) return;

    // assign
    Object.assign(this, { ...init });

    if (init.golfCourseData != null) {
      this.golfCourseData = new CourseDataModel(init.golfCourseData);
    }

    return this;
  }
}
