import { NoticeModel } from '../models/NoticeModel';

export const ONE_PAGE_DISPLAY = 10;

export class NoticeCollection {
  constructor(init = []) {
    this.data = [];
    // TODO: データが返ってくる様になったら消す
    // init = this.getTmpInit();
    this.set(init);
  }

  /**
   * 未読の件数を返す
   * @returns {Number}
   */
  countUnread() {
    let count = 0;
    this.data.map((noticeModel) => {
      if (!noticeModel.isRead()) {
        count++;
      }
    });

    return count;
  }

  /**
   * お知らせの件数を返す
   * @returns {Number}
   */
  countAll() {
    return this.data.length;
  }

  /**
   * 現在ページのお知らせ一覧を返す
   * @param {Number} page
   * @returns {Array}
   */
  getCurrentNotice(page) {
    return this.data.slice(page * ONE_PAGE_DISPLAY - ONE_PAGE_DISPLAY, page * ONE_PAGE_DISPLAY);
  }

  /**
   * お知らせの詳細を返す
   * @param {Number} id
   * @returns {NoticeModel}
   */
  getNoticeModel(id) {
    const detail = this.data.filter((noticeModel) => {
      return noticeModel.id === id;
    });

    if (detail.length === 0) {
      return new NoticeModel();
    }

    return detail[0];
  }

  get() {
    return this.data;
  }

  set(init) {
    init.map((data) => {
      this.data.push(new NoticeModel(data));
    });
  }

  // getTmpInit() {
  //   return [
  //     {
  //       id: 1,
  //       date_time: '2021-08-21',
  //       priority: 0,
  //       title: 'ニュースタイトル1',
  //       news_text: 'ニュ<br>ース１',
  //       version: '',
  //       view_flag: 0,
  //     },
  //     {
  //       id: 2,
  //       date_time: '2021-08-20',
  //       priority: 0,
  //       title: 'ニュースタイトル2',
  //       news_text: 'ニュース2',
  //       version: '',
  //       view_flag: 0,
  //     },
  //     {
  //       id: 3,
  //       date_time: '2021-08-19',
  //       priority: 0,
  //       title: 'ニュースタイトル3',
  //       news_text: 'ニュース3',
  //       version: '',
  //       view_flag: 0,
  //     },
  //     {
  //       id: 4,
  //       date_time: '2021-08-18',
  //       priority: 0,
  //       title: 'ニュースタイトル4',
  //       news_text: 'ニュース4',
  //       version: '',
  //       view_flag: 0,
  //     },
  //     {
  //       id: 5,
  //       date_time: '2021-08-17',
  //       priority: 0,
  //       title: 'ニュースタイトル5',
  //       news_text: 'ニュース5',
  //       version: '',
  //       view_flag: 0,
  //     },
  //     {
  //       id: 6,
  //       date_time: '2021-08-16',
  //       priority: 0,
  //       title: 'ニュースタイトル6',
  //       news_text: 'ニュース6',
  //       version: '',
  //       view_flag: 0,
  //     },
  //     {
  //       id: 7,
  //       date_time: '2021-08-15',
  //       priority: 0,
  //       title: 'ニュースタイトル7',
  //       news_text: 'ニュース7',
  //       version: '',
  //       view_flag: 0,
  //     },
  //     {
  //       id: 8,
  //       date_time: '2021-08-14',
  //       priority: 0,
  //       title: 'ニュースタイトル8',
  //       news_text: 'ニュース8',
  //       version: '',
  //       view_flag: 0,
  //     },
  //     {
  //       id: 9,
  //       date_time: '2021-08-13',
  //       priority: 0,
  //       title: 'ニュースタイトル9',
  //       news_text: 'ニュース9',
  //       version: '',
  //       view_flag: 0,
  //     },
  //     {
  //       id: 10,
  //       date_time: '2021-08-12',
  //       priority: 0,
  //       title: 'ニュースタイトル10',
  //       news_text: 'ニュース10',
  //       version: '',
  //       view_flag: 1,
  //     },
  //     {
  //       id: 11,
  //       date_time: '2021-08-11',
  //       priority: 0,
  //       title: 'ニュースタイトル11',
  //       news_text: 'ニュース11',
  //       version: '',
  //       view_flag: 1,
  //     },
  //     {
  //       id: 12,
  //       date_time: '2021-08-20',
  //       priority: 0,
  //       title: 'ニュースタイトル12',
  //       news_text: 'ニュース12',
  //       version: '',
  //       view_flag: 0,
  //     },
  //     {
  //       id: 13,
  //       date_time: '2021-08-19',
  //       priority: 0,
  //       title: 'ニュースタイトル13',
  //       news_text: 'ニュース13',
  //       version: '',
  //       view_flag: 0,
  //     },
  //     {
  //       id: 14,
  //       date_time: '2021-08-18',
  //       priority: 0,
  //       title: 'ニュースタイトル14',
  //       news_text: 'ニュース14',
  //       version: '',
  //       view_flag: 0,
  //     },
  //     {
  //       id: 15,
  //       date_time: '2021-08-17',
  //       priority: 0,
  //       title: 'ニュースタイトル15',
  //       news_text: 'ニュース15',
  //       version: '',
  //       view_flag: 0,
  //     },
  //     {
  //       id: 16,
  //       date_time: '2021-08-16',
  //       priority: 0,
  //       title: 'ニュースタイトル16',
  //       news_text: 'ニュース16',
  //       version: '',
  //       view_flag: 0,
  //     },
  //     {
  //       id: 17,
  //       date_time: '2021-08-15',
  //       priority: 0,
  //       title: 'ニュースタイトル17',
  //       news_text: 'ニュース17',
  //       version: '',
  //       view_flag: 0,
  //     },
  //     {
  //       id: 18,
  //       date_time: '2021-08-14',
  //       priority: 0,
  //       title: 'ニュースタイトル18',
  //       news_text: 'ニュース18',
  //       version: '',
  //       view_flag: 0,
  //     },
  //     {
  //       id: 19,
  //       date_time: '2021-08-13',
  //       priority: 0,
  //       title: 'ニュースタイトル19',
  //       news_text: 'ニュース19',
  //       version: '',
  //       view_flag: 0,
  //     },
  //     {
  //       id: 20,
  //       date_time: '2021-08-12',
  //       priority: 0,
  //       title: 'ニュースタイトル20',
  //       news_text: 'ニュース20',
  //       version: '',
  //       view_flag: 1,
  //     },
  //     {
  //       id: 21,
  //       date_time: '2021-08-11',
  //       priority: 0,
  //       title: 'ニュースタイトル21',
  //       news_text: 'ニュース21',
  //       version: '',
  //       view_flag: 1,
  //     },
  //   ];
  // }
}
