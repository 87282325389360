import { getUserStatus } from '../firebase/FireBaseManager';
import { UserAccountStatus, UserServiceApprovalStatus } from '../const/UserConst';
import { getTournamentData } from '../services/TournamentService';

export default class UserStatusHelper {
  /**
   * ユーザのサービスの承認状態を返す
   * @return {number} UserServiceApprovalStatus
   */
  static getUserServiceApprovalStatus() {
    const userStatus = getUserStatus();

    if (userStatus !== UserAccountStatus.ENABLE) return UserServiceApprovalStatus.NOT_ENTRY;

    const tournamentData = getTournamentData();

    // NOTE: 実技テストのフローは現在ないけど、一応入れておく。
    // 一旦はこのステータスの場合は「参加表明済みの会員」画面を表示するようにする。
    if (tournamentData.length === 0) return UserServiceApprovalStatus.PRACTICAL_TEST;

    return UserServiceApprovalStatus.APPROVAL;
  }
}
