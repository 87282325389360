import { AppPlayerModel, AppUserModel } from '../models/UserModel';
import { calculateAge } from '../helpers/common';

/**
 *
 * @param {object} data
 * @param {AppUserModel} userModel
 * @returns {AppUserModel}
 */
export function convertToInsertUserInfoData(data, userModel = new AppUserModel()) {
  if (data.version != null && data.version !== '') {
    userModel.version = data.version || '';
  }

  if (data.givenName != null && data.givenName !== '') {
    userModel.given_name_kanji = data.givenName || '';
  }
  if (data.familyNameKanji != null && data.familyNameKanji !== '') {
    userModel.family_name_kanji = data.familyNameKanji || '';
  }
  if (data.givenNameKana != null && data.givenNameKana !== '') {
    userModel.given_name_kana = data.givenNameKana || '';
  }
  if (data.familyNameKana != null && data.familyNameKana !== '') {
    userModel.family_name_kana = data.familyNameKana || '';
  }

  if (data.birthday != null && data.birthday !== '') {
    userModel.birthday = data.birthday || '';
  }
  if (data.age != null && data.age < 0) {
    userModel.age = data.age || 0;
  }
  if (data.birthday != null && data.birthday !== '') {
    userModel.age = calculateAge(data.birthday) || 0;
  }
  if (data.sex != null && data.sex !== '') {
    userModel.sex = data.sex || '';
  }
  if (data.phoneNumber != null && data.phoneNumber !== '') {
    userModel.phone_number = data.phoneNumber || '';
  }
  if (data.email != null && data.email !== '') {
    userModel.email = data.email || '';
  }

  if (data.postalCode != null && data.postalCode !== '') {
    userModel.postal_code = data.postalCode || '';
  }
  if (data.addressLevel1 != null && data.addressLevel1 !== '') {
    userModel.address_level1 = data.addressLevel1 || '';
  }
  if (data.addressLevel2 != null && data.addressLevel2 !== '') {
    userModel.address_level2 = data.addressLevel2 || '';
  }
  if (data.addressLine1 != null && data.addressLine1 !== '') {
    userModel.address_line1 = data.addressLine1 || '';
  }
  if (data.addressLine2 != null && data.addressLine2 !== '') {
    userModel.address_line2 = data.addressLine2 || '';
  }
  if (data.addressLine3 != null && data.addressLine3 !== '') {
    userModel.address_line3 = data.addressLine3 || '';
  }
  if (data.occupation != null && data.occupation !== '') {
    userModel.occupation = data.occupation || '';
  }

  if (data.occupation != null && data.occupation !== '') {
    userModel.handicap = data?.occupation.includes('学生') ? data?.handicap : '';
  }

  if (data.tourCounts != null && data.tourCounts !== '') {
    userModel.tour_counts = data.tourCounts;
  }
  if (data.rankinigPoints != null && data.rankinigPoints !== '') {
    userModel.ranking_points = data.rankinigPoints;
  }
  if (data.regDate != null && data.regDate !== '') {
    userModel.reg_date = data.regDate;
  }
  if (data.serialNb != null && data.serialNb !== '') {
    userModel.serial_nb = data.serialNb;
  }
  if (data.technicalTestArea != null && data.technicalTestArea !== '') {
    userModel.technical_test_area = data.technicalTestArea;
  }
  if (data.technicalTestFlg != null && data.technicalTestFlg !== '') {
    userModel.technical_test_flg = data.technicalTestFlg;
  }
  /*
  if (data.deleteFlg != null && data.deleteFlg !== "") {
    userModel.delete_flg = data.deleteFlg
  }
  if (data.deleteComment != null && data.deleteComment !== "") {
    userModel.delete_comment = data.deleteComment
  }
  */

  if (data.paidSkillTest != null && data.paidSkillTest !== '') {
    userModel.paid_skill_test = data.paidSkillTest || false;
  }

  return userModel;
}

/**
 *
 * @param {object} data
 * @param {AppPlayerModel} playerModel
 * @returns {AppPlayerModel}
 */
export function convertToInsertPlayerInfoData(data, playerModel = new AppPlayerModel()) {
  if (data.version != null && data.version !== '') {
    playerModel.version = data.version || '';
  }

  if (data.imageUrl != null && data.imageUrl !== '') {
    playerModel.image_url = data.imageUrl || '';
  }
  if (data.height != null && data.height !== '') {
    playerModel.height = data.height || '';
  }
  if (data.bodyWeight != null && data.bodyWeight !== '') {
    playerModel.body_weight = data.bodyWeight || '';
  }
  if (data.bloodType != null && data.bloodType !== '') {
    playerModel.blood_type = data.bloodType || '';
  }
  if (data.birthplace != null && data.birthplace !== '') {
    playerModel.birthplace = data.birthplace || '';
  }

  if (data.school != null && data.school !== '') {
    playerModel.school = data.school || '';
  }
  if (data.goodClub != null && data.goodClub !== '') {
    playerModel.good_club = data.goodClub || '';
  }
  if (data.beginningAge != null && data.beginningAge > 0) {
    playerModel.beginning_age = data.beginningAge || 0;
  }
  if (data.motivationStart != null && data.motivationStart !== '') {
    playerModel.motivation_start = data.motivationStart || '';
  }
  if (data.averageDistance != null && data.averageDistance > 0) {
    playerModel.average_distance = data.averageDistance || 0;
  }
  if (data.bestScore != null && data.bestScore !== '') {
    playerModel.best_score = data.bestScore || '';
  }
  if (data.targetPerson != null && data.targetPerson !== '') {
    playerModel.target_person = data.targetPerson || '';
  }

  if (data.shozoku != null && data.shozoku !== '') {
    playerModel.shozoku = data.shozoku || '';
  }

  // 口座情報
  if (data.bankInfoBankName != null && data.bankInfoBankName !== '') {
    playerModel.bank_info.bank_name = data.bankInfoBankName || '';
  }
  if (data.bankInfoBranchName != null && data.bankInfoBranchName !== '') {
    playerModel.bank_info.branch_name = data.bankInfoBranchName || '';
  }
  if (data.bankInfoAccountType != null && data.bankInfoAccountType !== '') {
    playerModel.bank_info.account_type = data.bankInfoAccountType || '';
  }
  if (data.bankInfoAccountNumber != null && data.bankInfoAccountNumber !== '') {
    playerModel.bank_info.account_number = data.bankInfoAccountNumber || '';
  }
  if (data.bankInfoAccountHolder != null && data.bankInfoAccountHolder !== '') {
    playerModel.bank_info.account_holder = data.bankInfoAccountHolder || '';
  }

  return playerModel;
}
