import React from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import IndexPage from '../pages/IndexPage';
import WelcomePage from '../pages/WelcomePage';
import FirstTimeLoginPage from '../pages/FirstTimeLoginPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import CommercialTransactionsLawPage from '../pages/CommercialTransactionsLawPage';
import FirebaseAuthActionCallbackPage from '../pages/FirebaseAuthActionCallbackPage';
import MainPage from '../pages/MainPage';
import MainEntrySelectionPage from '../pages/MainEntrySelectionPage';
import CheckRedirectPayment from '../pages/CheckRedirectPayment';
import MainEntryPaymentSuccessedPage from '../pages/MainEntryPaymentSuccessedPage';
import MainEntryPaymentRejectedPage from '../pages/MainEntryPaymentRejectedPage';
import TestContentConfirmationPage from '../pages/TestContentConfirmationPage';
import LoginPage from '../pages/LoginPage';
import EmailConfirmRequestPage from '../pages/EmailConfirmationRequestPage';
import EmailConfirmCompletePage from '../pages/EmailConfirmationCompletePage';
import RegisterPage from '../pages/RegisterPage';
import Page404 from '../pages/Error/Page404';
import MaintenancePage from '../pages/MaintenancePage';
import CommunicationErrorPage from '../pages/Error/CommunicationErrorPage';
import RegisterCompletePage from '../pages/RegisterCompletePage';
import MemberInfoEditCompletePage from '../pages/MemberInfoEditCompletePage';
import MemberInfoPage from '../pages/MemberInfoPage';
import MemberInfoEdit from '../pages/MemberInfoEditPage';
import NoticeInboxPage from '../pages/NoticeInboxPage';
import NoticeMessagePage from '../pages/NoticeMessagePage';
import ProfileViewPage from '../pages/ProfileViewPage';
import ProfileRegisterPage from '../pages/ProfileRegisterPage';
import ProfileRegisterCompleted from '../pages/ProfileRegisterCompleted';
import MainEntryPaymentAccepted from '../pages/MainEntryPaymentAccepted';
import ProfileUpdatePage from '../pages/ProfileUpdatePage';
import ProfileUpdateCompleted from '../pages/ProfileUpdateCompleted';
import PrivateRouterParts from './parts/PrivateRouterParts';
import { UserAccountStatus } from '../const/UserConst';
import ScrollToTop from '../components/ScrollToTop';
import MainEntryDeclineCompletePage from '../pages/MainEntryDeclineCompletePage';
import MainEntryDeclineRequestPage from '../pages/MainEntryDeclineRequestPage';
import MainEntryPaymentFinishedPage from '../pages/MainEntryPaymentFinishedPage';
import MainEntryParticipatePage from '../pages/MainEntryParticipatePage';

const RootRouter = () => {
  // const redirectUriRoot = '/';
  // const redirectUriRegist = '/regist';
  // const redirectUrlMain = '/main';

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        {/* index */}
        <Route path="/" exact component={IndexPage} />

        {/* welcome */}
        <Route path="/welcome" exact component={WelcomePage} />

        {/* first_time_login */}
        <Route path="/first_time_login" exact component={FirstTimeLoginPage} />

        {/* privacy policy */}
        <Route path="/privacy_policy" exact component={PrivacyPolicyPage} />

        {/* commercial_transactions_law */}
        <Route path="/commercial_transactions_law" exact component={CommercialTransactionsLawPage} />

        {/* email confirm request */}
        <Route path="/email_confirm_request" exact component={EmailConfirmRequestPage} />

        {/* auth email verify */}
        <Route path="/__/auth/email/verify" exact component={EmailConfirmCompletePage} />

        {/* firebase auth callback */}
        <Route path="/__/auth/action" exact component={FirebaseAuthActionCallbackPage} />

        {/* login */}
        <Route path="/login" exact component={LoginPage} />

        {/* 404 error */}
        <Route path="/404" exact component={Page404} />

        {/* 404 error */}
        <Route path="/maintenance" exact component={MaintenancePage} />

        {/* communication error */}
        <Route path="/communication_error" exact component={CommunicationErrorPage} />

        {/* メール認証が完了している */}
        {/* register */}
        <PrivateRouterParts
          path="/register"
          allowUserStatus={UserAccountStatus.EMAIL_VERIFIED}
          component={RegisterPage}
        />

        {/* register confirm */}
        <PrivateRouterParts
          path="/register_complete"
          allowUserStatus={UserAccountStatus.EMAIL_VERIFIED}
          component={RegisterCompletePage}
        />

        {/* ユーザ登録が完了している */}

        {/* Main */}
        <PrivateRouterParts path="/main" component={MainPage} />

        {/* Main Entry Selection */}
        <PrivateRouterParts path="/main-entry-selection" component={MainEntrySelectionPage} />

        {/* Check Redirect Status */}
        <PrivateRouterParts path="/check-redirect-payment/:tournament_id" component={CheckRedirectPayment} />

        {/* Main Entry Payment Successed */}
        <PrivateRouterParts path="/main-entry-payment-successed" component={MainEntryPaymentSuccessedPage} />

        {/* Main Entry Payment Rejected */}
        <PrivateRouterParts path="/main-entry-payment-rejected" component={MainEntryPaymentRejectedPage} />

        {/* Test Content Confirmation */}
        <PrivateRouterParts path="/test-content-confirmation" component={TestContentConfirmationPage} />

        {/* member info */}
        <PrivateRouterParts path="/member_info" component={MemberInfoPage} />

        {/* member info edit */}
        <PrivateRouterParts path="/member_info_edit" component={MemberInfoEdit} />

        {/* member info edit complete */}
        <PrivateRouterParts path="/member_info_edit_complete" component={MemberInfoEditCompletePage} />

        {/* notice inbox */}
        <PrivateRouterParts path="/notice/inbox" component={NoticeInboxPage} />

        {/* notice message */}
        <PrivateRouterParts path="/notice/message/:id" component={NoticeMessagePage} />
        {/* profile view */}
        <PrivateRouterParts path="/profile_view" component={ProfileViewPage} />

        {/* profile register */}
        <PrivateRouterParts path="/profile_register" component={ProfileRegisterPage} />

        {/* profile register complete */}
        <PrivateRouterParts path="/profile_register_completed" component={ProfileRegisterCompleted} />

        {/* profile update */}
        <PrivateRouterParts path="/profile_update" component={ProfileUpdatePage} />

        {/* profile update complete */}
        <PrivateRouterParts path="/profile_update_completed" component={ProfileUpdateCompleted} />

        {/* main entry payment accepted */}
        <PrivateRouterParts path="/main_entry_payment_accepted" component={MainEntryPaymentAccepted} />

        {/* main entry payment finished */}
        <PrivateRouterParts path="/main_entry_payment_finished" component={MainEntryPaymentFinishedPage} />

        {/* main entry participate */}
        <PrivateRouterParts path="/main_entry_participate" component={MainEntryParticipatePage} />

        {/* main entry decline complete */}
        <PrivateRouterParts path="/main_entry_decline_complete" component={MainEntryDeclineCompletePage} />

        {/* main entry decline request */}
        <PrivateRouterParts path="/main_entry_decline_request" component={MainEntryDeclineRequestPage} />

        <Redirect to="/" />
      </Switch>
    </Router>
  );
};

export default RootRouter;
