import { useCallback } from 'react';
import firebase from 'firebase';
import * as firebaseUi from 'firebaseui-ja';
import { getFirebaseConfig } from '../const/EnvConst';
import { UserAccountStatus } from '../const/UserConst';
import { AppPlayerModel, AppUserModel, FirebaseUserModel } from '../models/UserModel';
import { getFireStoreAppPlayerModel, getFireStoreAppUserModel, setFireStoreDBInstance } from './FireBaseDB';
import { setFireStorageInstance } from './FirebaseStorage';
import { isRegisterPlayer, cloudFunctionsSetUsersNewsView, cloudFunctionGetUsersNews } from './FireBaseCloudFunction';
import { NoticeCollection } from '../collections/NoticeCollection';

/**
 * @type {firebaseUi.auth.AuthUI}
 */
let ui = undefined;

/**
 * ログインしているユーザのfirebase情報を格納する
 * @type {FirebaseUserModel | undefined | null}
 */
let firebaseUserModel = undefined;

/**
 * ログインしているユーザのFirestore側のユーザ情報を格納する
 * @type {AppUserModel | undefined | null}
 */
let appUserModel = undefined;

/**
 * ログインしているユーザのFirebase側のプレイヤー情報を格納する
 * @type {AppPlayerModel | undefined}
 */
let playerModel = undefined;

/**
 * 選手登録の状態
 * 毎回の通信を避けるため、initializeで処理し、選手登録前に改めて確認する
 * @type {Number | undefined}
 */
let registerType = undefined;

/**
 * ログインしているユーザのFirebase側のお知らせ情報を格納する
 * @type {NoticeCollection | undefined}
 */
let noticeCollection = undefined;

/**
 * 初期化を行う
 * @return {Promise<boolean>}
 */
export const initializeFireBase = async () => {
  // initialize
  firebase.initializeApp(getFirebaseConfig);

  // set up fire store db
  setFireStoreDBInstance();

  // set up fire storage
  setFireStorageInstance();

  // set up ui
  ui = new firebaseUi.auth.AuthUI(firebase.auth());

  // set callbacks
  await onAuthStateChanged();

  return true;
};

/**
 * auth state changed
 */
const onAuthStateChanged = () => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth().onAuthStateChanged(
        function (user) {
          if (user) {
            // User is signed in.
            const displayName = user.displayName;
            const email = user.email;
            const emailVerified = user.emailVerified;
            const photoURL = user.photoURL;
            const uid = user.uid;
            const phoneNumber = user.phoneNumber;
            const providerData = user.providerData;

            // get id token
            user.getIdToken().then(function (accessToken) {
              // get app user model
              getFireStoreAppUserModel(user.uid).then((_appUserModel) => {
                // set firebase user model
                firebaseUserModel = new FirebaseUserModel({
                  displayName: displayName,
                  email: email,
                  emailVerified: emailVerified,
                  phoneNumber: phoneNumber,
                  photoURL: photoURL,
                  uid: uid,
                  accessToken: accessToken,
                  providerData: providerData,
                });

                // set app user model
                appUserModel = _appUserModel;

                // get player model
                getFireStoreAppPlayerModel(user.uid).then(async (_playerModel) => {
                  playerModel = _playerModel;

                  await updateRegisterType();

                  const userNews = await cloudFunctionGetUsersNews();
                  noticeCollection = new NoticeCollection(userNews);

                  // resolve
                  resolve();
                });
              });
            });
          } else {
            firebaseUserModel = null;
            appUserModel = null;
            playerModel = null;
            registerType = null;
            noticeCollection = new NoticeCollection();
            resolve();
          }
        },
        function (error) {
          firebaseUserModel = null;
          console.log(error);
          resolve();
        }
      );
    } catch (e) {
      console.error(e);
      reject();
    }
  });
};

/**
 * ログイン中かどうか判定する
 */
export function useAuthIsLoggedIn() {
  return useCallback(
    /**
     * @return {boolean}
     */
    () => {
      return firebaseUserModel != null;
    },
    [firebaseUserModel]
  );
}

/**
 * firebaseのユーザの状態をチェックする
 */
export function useUserStatus() {
  /**
   * @return {number} UserAccountStatus
   */
  return useCallback(() => {
    return getUserStatus();
  }, [firebaseUserModel, appUserModel]);
}

/**
 * ユーザのサービスの登録状況を返す
 * @returns {number} UserAccountStatus
 */
export function getUserStatus() {
  if (firebaseUserModel == null) return UserAccountStatus.NO_ACCOUNT;

  // メール認証
  if (firebaseUserModel.email && firebaseUserModel.email !== '') {
    if (!firebaseUserModel.emailVerified) return UserAccountStatus.EMAIL_NOT_VERIFY;
  }
  // 電話番号認証
  if (firebaseUserModel.phoneNumber == null && firebaseUserModel.phoneNumber === '') {
    return UserAccountStatus.NO_ACCOUNT;
  }

  if (appUserModel == null) return UserAccountStatus.EMAIL_VERIFIED;
  if (appUserModel.delete_flg === 1) return UserAccountStatus.STOP;

  return UserAccountStatus.ENABLE;
}

/**
 *
 * @param {History} history
 * @param {number} userStatus
 */
export function moveWindowByUserStatus(history, userStatus) {
  history.push(getRedirectUrlByUserStatus(userStatus));
}

/**
 *
 * @param {History} history
 * @param {number} userStatus
 */
export function getRedirectUrlByUserStatus(userStatus) {
  switch (userStatus) {
    case UserAccountStatus.NO_ACCOUNT:
      return '/';

    case UserAccountStatus.EMAIL_NOT_VERIFY:
      return '/';

    case UserAccountStatus.EMAIL_VERIFIED:
      return '/register';

    case UserAccountStatus.ENABLE:
      return '/main';

    case UserAccountStatus.STOP:
      return '/';

    default:
      return '/';
  }
}

/**
 * ログイン中のユーザ情報を返す
 * @returns {{
 *   firebaseUserModel: FirebaseUserModel | undefined | null,
 *   appUserModel: AppUserModel | undefined | null
 * }}
 */
export function useUserModel() {
  return useCallback(() => {
    return {
      firebaseUserModel,
      appUserModel,
    };
  }, [firebaseUserModel, appUserModel]);
}

/**
 * FirebaseUserModelを返す
 * @returns {FirebaseUserModel|undefined|null}
 */
export function getFirebaseUserModel() {
  return firebaseUserModel;
}

/**
 * AppUserModelを返す
 * @returns {AppUserModel|undefined|null}
 */
export function getAppUserModel() {
  return appUserModel;
}

/**
 * PlayerModelを返す
 * @returns {PlayerModel|undefined}
 */
export function getAppPlayerModel() {
  return playerModel;
}

/**
 * PlayerModelを返す
 * @returns {Boolean}
 */
export function getIsAppPlayerModel() {
  return getAppPlayerModel()?.height ? true : false;
}

/**
 * registerTypeを返す
 * @returns {registerType|undefined}
 */
export function getRegisterType() {
  return registerType;
}

/**
 * NoticeCollectionを返す
 * @returns {NoticeCollection|undefined}
 */
export function getNoticeCollection() {
  return noticeCollection;
}

/**
 * AppUserModelを更新する
 * @param {AppUserModel} _appUserModel
 */
export function updateAppUserModel(_appUserModel) {
  appUserModel = new AppUserModel(_appUserModel);
}

/**
 * AppUserModelを更新する
 * @param {AppPlayerModel} _appUserModel
 */
export function updateAppPlayerModel(_appPlayerModel) {
  playerModel = new AppPlayerModel(_appPlayerModel);
}

/**
 * registerTypeを更新する
 */
export async function updateRegisterType() {
  const isPlayerInfo = getIsAppPlayerModel();
  if (isPlayerInfo) {
    // 登録済
    registerType = 2;
  } else {
    const isRegister = await isRegisterPlayer();

    if (isRegister === -1) {
      alert('エラーが発生しました。更新していただくかサイト管理者にお問い合わせください。');
    }

    if (isRegister === 1 && !isPlayerInfo) {
      // 登録可能
      registerType = 1;
    } else if (isRegister === 0) {
      // 参加上限
      registerType = 3;
    }
  }
}

/**
 * uiインスタンスを返す
 * @returns {firebaseUi.auth.AuthUI}
 */
export const getUiInstance = () => ui;

/**
 * メールアドレスの認証を行う
 */
export function useEmailVerify() {
  /**
   * @param {string} oobCode
   * @param {{
   *   onProcessStart: () => void,
   *   onSuccess: ({firebaseUserModel: FirebaseUserModel, appUserModel: AppUserModel}) => void,
   *   onFailed: () => void
   * }} callbacks
   */
  return useCallback((oobCode, callbacks) => {
    if (callbacks.onProcessStart) {
      callbacks.onProcessStart();
    }
    firebase
      .auth()
      .applyActionCode(oobCode)
      .catch((error) => {
        console.error(error);
      });
  }, []);
}

/**
 * uiの設定を返す
 * @params {{
 *   onProcessStart: () => void,
 *   onRegisterSuccess: ({firebaseUserModel: FirebaseUserModel, appUserModel: AppUserModel}) => void,
 *   onRegisterFailed: ({firebaseUserModel: FirebaseUserModel, appUserModel: AppUserModel}) => void,
 *   onRegisterAlreadyCreated: ({firebaseUserModel: FirebaseUserModel, appUserModel: AppUserModel}) => void,
 * }} callbackArgs
 * @return {firebaseUi.auth.Config}
 */
export const getUiConfig = (callbackArgs) => {
  return {
    signInSuccessUrl: '/__/auth/action',
    signInOptions: [
      { provider: firebase.auth.EmailAuthProvider.PROVIDER_ID, requireDisplayName: false },
      { provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID, defaultCountry: 'JP' },
    ],
    // tosUrl and privacyPolicyUrl accept either url string or a callback
    // function.
    // Terms of service url/callback.
    tosUrl: '/privacy_policy',
    // Privacy policy url/callback.
    privacyPolicyUrl: function () {
      window.location.assign('/privacy_policy');
    },
    callbacks: {
      signInSuccessWithAuthResult: (authResult) => {
        if (callbackArgs.onProcessStart) {
          callbackArgs.onProcessStart();
        }

        const userInfo = authResult.additionalUserInfo;

        if (userInfo) {
          if (authResult.user) {
            const _user = authResult.user;
            firebaseUserModel = new FirebaseUserModel({
              displayName: _user.displayName,
              email: _user.email,
              emailVerified: _user.emailVerified,
              phoneNumber: _user.phoneNumber,
              photoURL: _user.photoURL,
              uid: _user.uid,
            });
          }

          // 新規追加の場合
          if (userInfo.isNewUser) {
            // パスワード登録の場合
            if (userInfo.providerId === 'password') {
              firebase
                .auth()
                .currentUser.sendEmailVerification()
                .then(() => {});
            }

            if (callbackArgs.onRegisterSuccess) {
              callbackArgs.onRegisterSuccess({
                firebaseUserModel,
                appUserModel,
                userInfo,
              });
            }

            // 既存ユーザの場合
          } else {
            if (callbackArgs.onRegisterAlreadyCreated) {
              // get app user model
              getFireStoreAppUserModel(firebaseUserModel.uid).then((_appUserModel) => {
                appUserModel = _appUserModel;

                // get player model
                getFireStoreAppPlayerModel(firebaseUserModel.uid).then(async (_playerModel) => {
                  playerModel = _playerModel;
                  await updateRegisterType();

                  const userNews = await cloudFunctionGetUsersNews();
                  noticeCollection = new NoticeCollection(userNews);

                  callbackArgs.onRegisterAlreadyCreated({
                    firebaseUserModel,
                    appUserModel,
                  });
                });
              });
            }
          }
          // 失敗の場合
        } else {
          if (callbackArgs.onRegisterFailed) {
            callbackArgs.onRegisterFailed({
              firebaseUserModel,
              appUserModel,
            });
          }
        }
      },
    },
  };
};

export async function setViewFlag(noticeModel) {
  if (!noticeModel.isSet() || noticeModel.isRead()) return;

  const data = {
    id: noticeModel.id,
  };
  await cloudFunctionsSetUsersNewsView(data);
  noticeModel.setViewFlag(1);
}
