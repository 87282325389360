import firebase from 'firebase';

/**
 * @type {firebase.firestore.Firestore}
 */
let storage = undefined;

export function setFireStorageInstance() {
  storage = new firebase.storage();
}

export async function uploadImageAsPromise(file, path, fileName = null) {
  const extend = file.name.split('/').reverse()[0].split('.')[1];
  if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
    alert('アップロードできる画像はjpg、jpeg、png、gifとなります');
    return;
  }

  // アップロード先のファイルパスの作成
  let file_name = '';
  if (!fileName) {
    file_name = encodeURI(file.name);
  } else {
    file_name = fileName + '.' + extend;
  }
  const storageRef = storage.ref(path).child(file_name);

  return new Promise(function (resolve, reject) {
    //Upload file
    let task = storageRef.put(file);

    //Update progress bar
    task.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      function error(err) {
        // 失敗時
        console.log('error uploading image', err);
        reject(err);
      },
      function complete() {
        // 成功時
        task.then(function (snapshot) {
          resolve(snapshot.ref.getDownloadURL());
        });
      }
    );
  })
    .then(function (downloadURL) {
      return downloadURL;
    })
    .catch(function () {
      console.log('Error:uploadImageAsPromise');
    });
}

export async function previewImageAsPromise(e) {
  if (e.target.value.length === 0) {
    return;
  }

  const file = e.target.files[0];
  if (!file) {
    console.log('image is required');
    return;
  }

  return await URL.createObjectURL(file);
}
