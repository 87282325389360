import React from 'react';

const IconSun = () => (
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Group 712" viewBox="0 0 24 24">
    <path fill="#ffc401" d="M12 3.2a1.6 1.6 0 10-1.6-1.6A1.6 1.6 0 0012 3.2z" data-name="Path 1780" />
    <path fill="#ffc401" d="M11.931 20.8a1.6 1.6 0 101.668 1.53 1.6 1.6 0 00-1.668-1.53z" data-name="Path 1781" />
    <path fill="#ffc401" d="M5.777 5.777a1.6 1.6 0 10-2.263 0 1.6 1.6 0 002.263 0z" data-name="Path 1782" />
    <path fill="#ffc401" d="M18.175 18.273a1.6 1.6 0 102.26-.1 1.6 1.6 0 00-2.26.1z" data-name="Path 1783" />
    <path fill="#ffc401" d="M1.6 10.4A1.6 1.6 0 103.2 12a1.6 1.6 0 00-1.6-1.6z" data-name="Path 1784" />
    <path fill="#ffc401" d="M22.33 10.401a1.6 1.6 0 101.668 1.53 1.6 1.6 0 00-1.668-1.53z" data-name="Path 1785" />
    <path fill="#ffc401" d="M3.515 18.223a1.6 1.6 0 102.262 0 1.6 1.6 0 00-2.262 0z" data-name="Path 1786" />
    <path fill="#ffc401" d="M20.534 5.728a1.6 1.6 0 10-2.262.1 1.6 1.6 0 002.262-.1z" data-name="Path 1787" />
    <circle cx="6.46" cy="6.46" r="6.46" fill="#ffc401" data-name="Ellipse 79" transform="translate(5.539 5.541)" />
  </svg>
);

export default IconSun;
