import React from 'react';
import DesktopMenuHeader from '../../components/header/DesktopMenuHeader';
import CopyRightFooter from '../../components/footer/CopyRightFooter';
import MainFooter from '../../components/footer/MainFooter';

/**
 * MainPage
 * @returns {JSX.Element}
 * @constructor
 */
const MainKantoPage = () => {
  return (
    <body className="main">
      <header className="main__section">
        <div className="main__content main__content--upper main__header">
          <div className="isologo isologo--main">
            <a href="/main">
              <img src="/images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
            </a>
          </div>
          <DesktopMenuHeader />
        </div>
      </header>

      <main className="main__section main__section--last">
        <section>
          <div className="strip strip--player">
            <div className="strip__container">
              <div className="strip__flow"></div>
              <div className="strip__decoration strip__decoration--upper"></div>
              <div className="strip__title">
                <h1 className="c-heading--1">JCG関東プレシーズンツアー2021</h1>
              </div>
              <div className="strip__decoration strip__decoration--lower"></div>
            </div>
          </div>

          <div className="main__content">
            <article className="article">
              <div className="article__image">
                <img src="./images/golf-00.jpg" className="" alt="記事画像" />
              </div>
              <div className="article__buttons">
                <button disabled className="button button__primary button--disabled-gray">
                  参加表明開始前
                </button>
              </div>
              <div className="main-information">
                <div className="c-container-vertical c-container-vertical--orange-blaze c-radius--8 c-padding--16">
                  <h2 className="c-heading--1 c-heading--center">関東ブロック</h2>
                  <p className="c-paragraph c-mt16 c-prl--32">
                    関東全域において9月30日まで緊急事態宣言が延長される事になり、その後10月4日から関西大会開幕となる為、運営会社の方で関東大会の準備を十分に行う事が出来ない見込みとなり、今年度の開幕が難しくなりました。2022年の早い段階で開催をしたいと考えており、開幕時期の方は決まり次第発表とさせていただきます。
                  </p>
                </div>
              </div>
            </article>
          </div>
        </section>
      </main>
      <footer>
        <CopyRightFooter />
        <MainFooter />
      </footer>
    </body>
  );
};

export default MainKantoPage;
