import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getIsAppPlayerModel, getNoticeCollection } from '../../firebase/FireBaseManager';

const MainFooter = () => {
  const history = useHistory();
  const [unreadNoticeSize, setUnreadNoticeSize] = useState(0);

  /**
   * ホームボタンが押された時
   */
  const onClickHomeButton = () => {
    history.push('/main');
  };

  /**
   * 会員情報ボタンが押された時
   */
  const onClickMemerInfoButton = () => {
    history.push('/member_info');
  };

  /**
   * 選手情報ボタンが押された時
   */
  const onClickPlayerInfoButton = () => {
    history.push('/profile_view');
  };

  /**
   * お知らせボタンが押された時
   */
  const onClickNoticeButton = () => {
    history.push('/notice/inbox');
  };

  const isAppPlayerModel = getIsAppPlayerModel();

  useEffect(() => {
    const collection = getNoticeCollection();
    setUnreadNoticeSize(collection.countUnread());
  }, []);

  return (
    <div className="footer">
      <div className="footer__container">
        <button className="footer__button" onClick={onClickNoticeButton}>
          <img src="/images/icon--notice.svg" alt="" className="footer__icon" />
          <span className="footer__label">お知らせ</span>
          {unreadNoticeSize > 0 && <span className="footer__counter">{unreadNoticeSize}</span>}
        </button>

        <button className="footer__button" onClick={onClickHomeButton}>
          <img src="/images/icon--white-home.svg" alt="" className="footer__icon" />
          <span className="footer__label">ホーム</span>
        </button>

        <button className="footer__button" onClick={onClickMemerInfoButton}>
          <figure className="c-icon c-icon--24 c-icon--white">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 32">
              <defs />
              <path d="M27 0H3a3.009 3.009 0 00-3 3v26a3.009 3.009 0 003 3h24a3.009 3.009 0 003-3V3a3.009 3.009 0 00-3-3zm-1 28H4V4h22zM8 18h14v2H8zm0 4h14v2H8zm2-13a3 3 0 113 3 3 3 0 01-3-3zm5 3h-4c-1.65 0-3 .9-3 2v2h10v-2c0-1.1-1.35-2-3-2z" />
            </svg>
          </figure>
          <span className="footer__label">会員情報</span>
        </button>

        {isAppPlayerModel && (
          <button className="footer__button" onClick={onClickPlayerInfoButton}>
            <figure className="c-icon c-icon--24 c-icon--white">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <defs />
                <path
                  d="M275.076 93.952c2.991-24.117-14.126-46.108-38.252-49.108-24.126-3-46.107 14.117-49.107 38.252-3 24.126 14.126 46.108 38.256 49.107 24.112 2.992 46.103-14.125 49.103-38.251z"
                  className="st0"
                />
                <path
                  d="M384.588 229.743c-20.572-32.927-36.882-70.296-41.076-77.557-2.487-4.316-2.73-7.361-.78-11.622 8.4-12.144 16.991-24.197 25.685-36.125 7.54-10.352 18.319-21.802 14.202-35.9-2.364-8.108-9.541-11.486-15.604-16.424-15.23-12.441-30.414-24.982-45.742-37.333-4.292-3.45-8.436-7.08-12.783-10.45-7.068-6.288-17.897-5.658-24.18 1.424-5.297 5.964-5.617 14.576-1.361 20.9L266.932 43.34c3.928 3.1 7.446 6.658 10.436 10.648l15.987-16.648 40.324 39.126-31.878 33.954c-17.613 17.252-59.472 42.072-49.891 77.035 7.302 26.648 32.418 72.404 32.418 85.323 0 17.892-9.81 108.287-9.81 108.287-.064.352-.081.703-.126 1.063l-28.396 98.143c-3.225 10.612 2.775 21.838 13.4 25.054 10.617 3.225 21.842-2.766 25.063-13.396l34.162-90.873c.473-1.054.892-2.153 1.225-3.297l.37-.982c.518-1.721 18.468-86.8 18.468-86.8l.798-7.91-8.586 85.324c-.248 1.504-.41 3.027-.41 4.613l4.207 97.665c0 12.333 9.991 22.333 22.324 22.333 12.33 0 22.333-10 22.333-22.333l5.77-94.134 13.792-86.927c2.977-26.84 6.243-45.938-14.324-78.865zM187.676 125.879l-73.224 76.242c-3.734 3.901-4.631 9.712-2.239 14.55l18.554 37.531c1.464 2.991 4.162 5.171 7.379 6.036 3.216.847 6.64.279 9.396-1.586l13.936-9.397c2.753-1.864 4.568-4.819 4.987-8.117.419-3.297-.608-6.612-2.815-9.099l-24.972-33.594 60.035-62.53c-4.073-2.811-7.762-6.198-11.037-10.036z"
                  className="st0"
                />
              </svg>
            </figure>
            <span className="footer__label">選手情報</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default MainFooter;
