import React from 'react';

const IconNext = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18.824">
    <defs />
    <path
      d="M13.069 7.688a2 2 0 010 3.448L3.014 17.051A2 2 0 010 15.327V3.5a2 2 0 013.014-1.724z"
      data-name="多角形 21"
    />
  </svg>
);

export default IconNext;
