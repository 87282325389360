/**
 * ユーザのサービス登録状態
 */
export const UserAccountStatus = {
  NO_ACCOUNT: -1, // firebase, DBそれぞれデータなし
  EMAIL_NOT_VERIFY: 0, // email認証前
  EMAIL_VERIFIED: 1, // email認証完了、DBにデータなし
  ENABLE: 2, // email認証、DBデータ登録(個人情報登録)完了
  STOP: -2, // ユーザ停止状態
};

/**
 * ユーザのツアーエントリー状態
 */
export const UserTournamentEntryStatus = {
  ERROR: -1, // エラー
  NOT_ENTRY: 0, // 未エントリー
  ENTRY: 1, // エントリー済み
  CANCEL: 2, // 辞退
};

/**
 * ユーザのサービスの承認状態
 */
export const UserServiceApprovalStatus = {
  NOT_ENTRY: 0, // 未エントリー
  PRACTICAL_TEST: 1, // 技能テスト中
  APPROVAL: 2, // 承認
  DISAPPROVAL: 3, // 非承認
};
