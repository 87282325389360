import React from 'react';

const AppFooterHome = () => (
  <div className="app-footer app-footer--home">
    <div className="c-container-vertical c-container-vertical--center">
      <p className="c-small">© JCG Management</p>
      <div className="c-container-horizontal c-container-horizontal--center">
        <a href="/privacy_policy" target="_blank" className="app-footer__link">
          プライバシーポリシー
        </a>
        <a href="/commercial_transactions_law" target="_blank" className="app-footer__link">
          特定商取引法について
        </a>
      </div>
    </div>
  </div>
);

export default AppFooterHome;
