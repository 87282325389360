import { AppUserModel, AppPlayerModel } from '../models/UserModel';
import firebase from 'firebase';

/**
 * @type {firebase.firestore.Firestore}
 */
let db = undefined;

export function setFireStoreDBInstance() {
  db = new firebase.firestore();
}

// const
const FIRE_STORE_DOCUMENT_USERS_DATA = 'users_data';
// const FIRE_STORE_DOCUMENT_PLAYER_DATA = 'player_data';

/**
 * @param {string} uid
 * @return {Promise<AppUserModel | undefined>}
 */
export const getFireStoreAppUserModel = async (uid) => {
  // validate
  if (db == null) return undefined;

  try {
    const doc = await db.collection(FIRE_STORE_DOCUMENT_USERS_DATA).doc(uid).get();

    if (doc.exists) {
      const data = doc.data();
      return new AppUserModel(data);
    } else {
      return;
    }
  } catch (e) {
    console.error('FIreBaseDBUtil getUserInfo()', uid, e);
    throw new Error(e);
  }
};

/**
 * @return {Promise<AppPlayerModel | undefined>}
 */
export const getFireStoreAppPlayerModel = async () => {
  const getInfoPlayer = firebase.functions().httpsCallable('getInfoPlayer');

  const result = await getInfoPlayer();

  if (result.data != null && result.data.data != null) {
    return new AppPlayerModel(result.data.data);
  }
  return new AppPlayerModel();
  /*
  // validate
  if (db == null) return undefined;

  try {
    const doc = await db.collection(FIRE_STORE_DOCUMENT_PLAYER_DATA).doc(uid).get();

    if (doc.exists) {
      const data = doc.data();
      return new AppPlayerModel(data);
    } else {
      return;
    }
  } catch (e) {
    console.error('FIreBaseDBUtil getPlayerInfo()', uid, e);
    throw new Error(e);
  }

  */
};

/**
 * @param {AppUserModel} appUserModel
 */
// const createUserInfo = (appUserModel) => {};
