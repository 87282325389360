import React, { useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import AppFooter from '../components/footer/AppFooter';
import { replacePrefixPhone, scrollTop } from '../helpers/common';
import { getFirebaseUserModel, getAppUserModel } from '../firebase/FireBaseManager';
import InputUserForm from '../components/form/user_info/InputUserForm';
import ConfirmUserForm from '../components/form/user_info/ConfirmUserForm';
import { cloudFunctionUpdateInfoUser } from '../firebase/FireBaseCloudFunction';
import { convertToInsertUserInfoData } from '../firebase/FireBaseDataConverter';
import { useHistory } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import { AppUserModel } from '../models/UserModel';

const RegisterPage = () => {
  const userInfo = getAppUserModel() || new AppUserModel();
  const [form] = Form.useForm();
  const history = useHistory();
  // const userStatusHook = useUserStatus();
  const [isLoading, setIsLoading] = useState(false);

  const [isConfirm, setIsConfirm] = useState(false);
  const [inputCurrentData, setInputCurrentData] = useState(null);
  const firebaseUserModel = getFirebaseUserModel();
  const [inputCurrentModel, setInputCurrentModel] = useState(new AppUserModel());
  const isUpdate = false;

  useEffect(() => {
    // 登録済の場合は404ページへ
    if (userInfo.isSet()) {
      history.push('/404');
    }
  }, []);

  /**
   * 確認ボタンが押された時
   * @param values
   */
  const handleConfirmRegister = (values) => {
    setInputCurrentData(values);
    setIsConfirm(true);
    const userModel = convertToInsertUserInfoData(values);
    setInputCurrentModel(userModel);
    scrollTop();
  };

  /**
   * 登録がボタンが押されたとき
   */
  const onClickSubmitButton = () => {
    console.log('RegisterPage onClickSubmitButton()', inputCurrentData);
    const userModel = convertToInsertUserInfoData(inputCurrentData);

    // ユーザ情報更新
    cloudFunctionUpdateInfoUser(
      {
        /**
         * 処理開始時
         */
        onProcessStart: () => {
          console.log('ProfileUpdatePage cloudFunctionUpdateInfoUser()');
          setIsLoading(true);
        },

        /**
         * 成功時
         */
        onSuccess: (_appUserModel) => {
          setIsLoading(false);
          // moveWindowByUserStatus(history, userStatusHook());
          history.push('/register_complete');
        },

        /**
         * 失敗時
         */
        onFailed: () => {
          setIsLoading(false);
        },
      },
      userModel
    );
  };

  if (isLoading) {
    return (
      <body>
        <LoadingScreen />;
      </body>
    );
  }

  return (
    <body id="all-body">
      <main className="register">
        <article className="screen screen--register">
          <div className="register register__logo">
            <img src="images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
          </div>

          <h1 className="register register__title c-mt32">{!isConfirm ? '個人情報登録' : '個人情報登録ご確認'}</h1>

          <Form
            form={form}
            onFinish={handleConfirmRegister}
            scrollToFirstError
            initialValues={{
              phoneNumber: replacePrefixPhone(firebaseUserModel.phoneNumber || ''),
              email: firebaseUserModel.email,
            }}
            className="h-adr form"
            id="user-input-form"
          >
            {/* 入力画面 */}
            {!isConfirm && (
              <>
                <InputUserForm isUpdate={isUpdate} currentUser={inputCurrentModel} form={form} />

                <Button htmlType="submit" id="send-form" className="button button__primary c-mt56 c-mb88">
                  次へ
                </Button>
              </>
            )}

            {/* 確認画面 */}
            {isConfirm && (
              <>
                <div className={`${!isConfirm ? 'hidden' : ''}`}>
                  <ConfirmUserForm dataUser={inputCurrentData} isUpdate={false} />
                </div>

                <div className="c-button-set c-mt56">
                  <Button className="button button__secondary" id="send-form" onClick={() => setIsConfirm(isUpdate)}>
                    修正する
                  </Button>
                  <Button className="button button__primary c-mb88" id="send-form" onClick={onClickSubmitButton}>
                    登録する
                  </Button>
                </div>
              </>
            )}
          </Form>
        </article>
      </main>

      <footer>
        <AppFooter />
      </footer>
    </body>
  );
};

export default RegisterPage;
