import moment from 'moment';
import firebase from 'firebase';
import TournamentDataModel from '../models/TournamentDataModel';
// import CourseDataModel from '../models/CourseDataModel';
import { UserTournamentEntryStatus } from '../const/UserConst';

/**
 *
 * @type {TournamentDataModel[]}
 */
let tournamentDatas = [];

/**
 * @return {TournamentDataModel[]}
 */
export function getTournamentData() {
  return tournamentDatas || [];
}

/**
 * @param {String}
 * @param {Integer}
 */
function logStatusError(CF, reply) {
  console.log('CloudFunction:' + CF + ' returned: ' + reply);
}

/**
 * IDからツアーを返す
 * @param {String} tournamentId
 * @returns {null|TournamentDataModel}
 */
export function getTournament(tournamentId) {
  const tournament = tournamentDatas.find((_item) => {
    return _item.id == tournamentId;
  });

  if (tournament === undefined) return null;

  return tournament;
}

/**
 * エントリー開始している情報のみ返す
 * @returns {TournamentDataModel[]}
 */
export function getStartedEntryTournamentData() {
  const today = moment();
  return tournamentDatas.filter(
    (_item) =>
      _item.entryStartMillisecond != null &&
      _item.entryStartMillisecond !== '' &&
      today.isAfter(moment(_item.entryStartMillisecond))
  );
}

/**
 * @return {TournamentDataModel[]}
 */
export function useGetTournamentData() {
  return tournamentDatas;
}

/**
 * 参加している大会一覧を取得
 * @returns {Promise<TournamentDataModel[]>}
 */
export async function tourGetParticipationTournamentService() {
  try {
    const getParticipationTournament = firebase.functions().httpsCallable('getParticipationTournament');

    // get tournament info
    const response = await getParticipationTournament();

    // create TournamentDataModel classes
    if (response?.data?.message?.tournamentDatas != null && Array.isArray(response?.data?.message?.tournamentDatas)) {
      tournamentDatas = [];
      response.data.message.tournamentDatas.forEach((_item) => {
        tournamentDatas.push(new TournamentDataModel(_item));
      });

      // test data
      /*
      console.log('FireBaseCloudFunction getParticipationTournament()', {
        response,
      });

      // NOTE: 取得してきたデータのエントリー開始日を変更する場合
      if (tournamentDatas.length > 0) {
        tournamentDatas[0].entryStartMillisecond = 1630500188000;
      }
      console.log(moment(tournamentDatas[0].entryStartMillisecond).format('YYYY-MM-DD HH:mm:ss'));
      */

      /*
      // NOTE: 任意のツアー情報を追加する場合
      const tournamentModel = new TournamentDataModel({
        id: 'tdJITnqfImmtWB3IiCIBaa',
        title: 'テストツアー(title)',
        first_place: 180000,
        second_place: '100000',
        third_place: '5000',
        special_prize: '銀閣寺⼤⻄のサーロインステーキセット',
        entryStartMillisecond: 1630500188000,
        entryFinishMillisecond: 1632754800000,
      });
      tournamentModel.course_data = new CourseDataModel({
        club_name: '〇〇カントリーゴルフ',
        club_address: '〒000-0000 東京都千代田区外神田〇〇〇〇',
        club_phone: '072-737-0888',
        club_website: 'HTTP://JCG-MANAGEMENT.CO.JP/',
      });
      console.log(moment(tournamentModel.entryStartMillisecond).format('YYYY-MM-DD HH:mm:ss'));
      console.log(moment(tournamentModel.entryFinishMillisecond).format('YYYY-MM-DD HH:mm:ss'));
      tournamentModel.userTournamentEntryStatus = UserTournamentEntryStatus.NOT_ENTRY;

      tournamentDatas.push(tournamentModel);
      */
    }

    // sort
    tournamentDatas.sort((a, b) => {
      // ツアー開催日未定は一番上に出す
      if (b.postponed_flg === 2) return 1;
      // ツアー開始日の昇順
      if (a.startMillisecond < b.startMillisecond) return -1;
      if (a.startMillisecond > b.startMillisecond) return 1;
      return 0;
    });

    // 未認証の場合
    if (response.data.status === 403) {
      return [];
    }

    if (response.data.status !== 200) {
      logStatusError('tourGetParticipationTournamentService()', response.data);
      return [];
    }

    return tournamentDatas;
  } catch (e) {
    console.log('tourGetParticipationTournamentService', {
      tournamentDatas,
    });
    console.error(e);
  }

  return [];
}

/**
 * 大会に参加しているユーザーのエントリーフラグを取得する
 * @param {string} tournamentId
 * @returns {Promise<number>} UserTournamentEntryStatus
 */
export async function tourGetTournamentEntryFlgService(tournamentId) {
  try {
    const getTournamentEntryFlg = firebase.functions().httpsCallable('getTournamentEntryFlg');

    // get entry flg
    const response = await getTournamentEntryFlg({ tournamentId });

    // set entry fly by api result.
    const entryFlg = response?.data?.message?.entryFlg || UserTournamentEntryStatus.NOT_ENTRY;

    console.log('tourGetTournamentEntryFlgService', {
      tournamentId,
      entryFlg,
    });

    if (response.data.status === 200) {
      const index = tournamentDatas.findIndex((_item) => _item.id === tournamentId);
      if (index >= 0) {
        tournamentDatas[index].userTournamentEntryStatus = entryFlg;
        tournamentDatas = tournamentDatas.concat();
      }
    }
    return entryFlg;
  } catch (e) {
    console.error(e);
  }

  return UserTournamentEntryStatus.ERROR;
}

/**
 * 大会に参加しているユーザーのエントリーフラグを設定する
 * @param {string} tournamentId
 * @param {number} entryFlg UserTournamentEntryStatus
 * @returns {Promise<number>} UserTournamentEntryStatus
 */
export async function tourSetTournamentEntryFlgService(tournamentId, entryFlg) {
  try {
    const setTournamentEntryFlg = firebase.functions().httpsCallable('setTournamentEntryFlg');

    // execute web api
    await setTournamentEntryFlg({ tournamentId, entryFlg });

    return entryFlg;
  } catch (e) {
    console.error(e);
  }

  return UserTournamentEntryStatus.ERROR;
}

/**
 * 決済前のorder_idを追加
 * @param {String} tournamentId
 * @param {String} orderId
 */
export async function tourSetTournamentPaymentUserDataService(tournamentId, obj) {
  try {
    const setTournamentPaymentUserData = firebase.functions().httpsCallable('setTournamentPaymentUserData');

    const response = await setTournamentPaymentUserData({
      tournamentId: tournamentId,
      payment_data: obj,
    });

    if (response.data.status !== 200) {
      logStatusError('tourSetTournamentPaymentUserDataService()', response.data);
      console.log('on tournament: ' + tournamentId + 'and payment: ' + obj);
      window.location.href = '/main-entry-payment-rejected';
    }
  } catch (e) {
    console.log('tourSetTournamentPaymentUserDataService()');
    console.log('on tournament: ' + tournamentId + 'and payment: ' + obj);
    console.error(e);
  }
}

/**
 * 決済画面から戻ってきた際ののorder_idを取得
 * @param {String} tournamentId
 */
export async function tourGetTournamentPaymentUserDataService(tournamentId) {
  try {
    const getTournamentPaymentUserData = firebase.functions().httpsCallable('getTournamentPaymentUserData');

    const response = await getTournamentPaymentUserData({ tournamentId: tournamentId });
    console.log(response);
    if (response.data.status !== 200) {
      logStatusError('tourGetTournamentPaymentUserDataService()', response.data);
    }
    return response.data.message;
  } catch (e) {
    console.error(e);
  }
}
