import firebase from 'firebase/app';
import {
  getTournamentData,
  tourSetTournamentPaymentUserDataService,
  tourSetTournamentEntryFlgService,
} from '../services/TournamentService';
import { cloudFunctionsGetPaymentURL } from '../firebase/FireBaseCloudFunction';
import { UserTournamentEntryStatus } from '../const/UserConst';

export const PAYMENT_STATUSES = {
  // REQAUTH, REQSALESは即時売上では使用しない
  PROCESSING: 'PROCESSING', // 通常決済フロー中
  ERROR: 'ERROR', // 通常決済フロー終了
  UNPROCESSED: 'UNPROCESSED', // 未決済
  AUTHENTICATED: 'AUTHENTICATED', // 未決済(3DS登録済)
  CHECK: 'CHECK', // 有効性チェック
  CAPTURE: 'CAPTURE', // 即時売上
  AUTH: 'AUTH', // 仮売上
  SALES: 'SALES', // 実売上
  VOID: 'VOID', // 取消
  RETURN: 'RETURN', // 返品
  RETURNX: 'RETURNX', // 月跨り返品
  SAUTH: 'SAUTH', // 簡易オーソリ
};

/**
 * 決済URLを取得
 * @param {Object} params // 決済に必要なパラメータ。詳細はGMOの仕様書確認
 */
export async function getPaymentUrlService(params) {
  return await cloudFunctionsGetPaymentURL(params);
}

/**
 * GMOに決済情報の問い合わせ
 * @param {String} orderId
 * @param {Object} callbacks
 */
export async function getPaymentStatusService(orderId, callbacks = null) {
  const getPaymentStatus = firebase.functions().httpsCallable('getPaymentStatus');

  try {
    const result = await getPaymentStatus({ OrderID: orderId });

    if (callbacks?.onSuccess) {
      await callbacks.onSuccess(result);
    }
  } catch (e) {
    if (callbacks?.onFailed) {
      await callbacks.onFailed(e);
    }
  }
}

export async function checkPaymentStatus() {
  console.log('checking payment status');

  const tournamentDatas = getTournamentData();

  try {
    let promises = tournamentDatas.map(async (value) => {
      // isProccesingがセットされていない(エントリーしていない)
      if (value.payment_data?.isProccesing === undefined) {
        return;
      }
      // 処理完了なら何もしない
      if (!value.payment_data.isProccesing) {
        return;
      }

      console.log('processing check payment');

      // 仕掛かり中の場合はGMOに決済状況を問い合わせ
      await getPaymentStatusService(value.payment_data.orderId, {
        onSuccess: async (res) => {
          const status = res.data.message?.PaymentStatus;
          let obj = {
            status: status,
            isProccesing: false,
          };
          if (status === PAYMENT_STATUSES.CAPTURE || status === PAYMENT_STATUSES.SALES) {
            // 決済成功時
            await tourSetTournamentEntryFlgService(value.id, UserTournamentEntryStatus.ENTRY);
          } else {
            // 決済失敗時にフィールドを追加
            Object.assign(obj, {
              failedOrderId: value.payment_data.order_id,
              orderId: '',
            });
          }

          await tourSetTournamentPaymentUserDataService(value.id, obj)
            .then(() => {
              value.payment_data.status = status;
              value.payment_data.isProccesing = false;
            })
            .catch((err) => {
              console.error(err);
            });
        },
        onFailed: (err) => {
          console.error(err);
        },
      });
    });
    await Promise.all(promises);
  } catch (e) {
    console.error(e);
  }
}
