import React from 'react';
import { convertDate, calculateAge } from '../../../helpers/common';

const ConfirmUserForm = (props) => {
  const { dataUser, isUpdate } = props;

  return (
    <>
      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">氏名(姓)</span>
        <p className="confirm confirm__field" id="given_name_kanji">
          {dataUser?.givenName}
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">氏名(名)</span>
        <p className="confirm confirm__field" id="family_name_kanji">
          {dataUser?.familyNameKanji}
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">氏名カナ(姓)</span>
        <p className="confirm confirm__field" id="given_name_kana">
          {dataUser?.givenNameKana}
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">氏名カナ(名)</span>
        <p className="confirm confirm__field" id="family_name_kana">
          {dataUser?.familyNameKana}
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">生年月日</span>
        <p className="confirm confirm__field" id="birthday">
          {convertDate(dataUser?.birthday)}
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">年齢</span>
        <p className="confirm confirm__field" id="age">
          {calculateAge(dataUser?.birthday)} 才
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">性別</span>
        <p className="confirm confirm__field" id="sex">
          {dataUser?.sex}
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">電話場号</span>
        <p className="confirm confirm__field" id="phone_number">
          {dataUser?.phoneNumber}
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">メールアドレス</span>
        <p className="confirm confirm__field" id="email">
          {dataUser?.email}
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <h2 className="confirm confirm__title">住所</h2>
        <div className="confirm confirm__vertical confirm__vertical--no-mt c-mt16">
          <span className="confirm confirm__label">郵便番号</span>
          <p className="confirm confirm__field confirm__field--medium" id="postal_code">
            {dataUser?.postalCode}
          </p>
        </div>
        <div className="confirm confirm__horizontal">
          <div className="confirm confirm__vertical">
            <span className="confirm confirm__label">都道府県</span>
            <p className="confirm confirm__field confirm__field--small" id="address_level1">
              {dataUser?.addressLevel1}
            </p>
          </div>
          <div className="confirm confirm__vertical confirm__vertical--stretch">
            <span className="confirm confirm__label">市区町村や郡</span>
            <p className="confirm confirm__field confirm__field--stretch" id="address_level2">
              {dataUser?.addressLevel2}
            </p>
          </div>
        </div>
        <div className="confirm confirm__horizontal">
          <div className="confirm confirm__vertical">
            <span className="confirm confirm__label">町名</span>
            <p className="confirm confirm__field confirm__field--medium" id="address_line1">
              {dataUser?.addressLine1}
            </p>
          </div>
          <div className="confirm confirm__vertical confirm__vertical--stretch">
            <span className="confirm confirm__label">地番</span>
            <p className="confirm confirm__field confirm__field--stretch" id="address_line2">
              {dataUser?.addressLine2}
            </p>
          </div>
        </div>
        <div className="confirm confirm__horizontal">
          <div className="confirm confirm__vertical confirm__vertical--stretch">
            <span className="confirm confirm__label">建物名</span>
            <p className="confirm confirm__field" id="address_line3">
              {dataUser?.addressLine3}
            </p>
          </div>
        </div>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">職業</span>
        <p className="confirm confirm__field">{dataUser?.occupation}</p>
      </div>

      {dataUser?.handicap && (
        <div className="confirm confirm__vertical">
          <span className="confirm confirm__label">ハンディキャップ</span>
          <p className="confirm confirm__field">{dataUser?.handicap}</p>
        </div>
      )}

      {isUpdate && dataUser?.technical_test_area && (
        <div className="confirm confirm__vertical">
          <span className="confirm confirm__label">ツアーに出場する際の希望する地域</span>
          <p className="confirm confirm__field" id="area">
            {dataUser?.technical_test_area || '-'}
          </p>
        </div>
      )}
    </>
  );
};

export default ConfirmUserForm;
