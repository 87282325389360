import React from 'react';
import { useHistory } from 'react-router-dom';
import AppFooter from '../components/footer/AppFooter';
import { getAppUserModel } from '../firebase/FireBaseManager';
import { AppUserModel } from '../models/UserModel';

const MemberInfoPage = () => {
  const history = useHistory();
  const userInfo = getAppUserModel() || new AppUserModel();

  const onClickBackButton = () => {
    history.push('/main');
  };

  const onClickEdit = () => {
    history.push('/member_info_edit');
  };

  return (
    <body>
      <main className="confirm">
        <article className="screen screen--confirm">
          <div className="confirm confirm__logo">
            <img src="/images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
          </div>

          <h1 className="register register__title c-mt32">会員情報</h1>

          <div className="confirm confirm__vertical">
            <span className="confirm confirm__label">氏名(姓)</span>
            <p className="confirm confirm__field" id="given_name_kanji">
              {userInfo.given_name_kanji || ''}
            </p>
          </div>

          <div className="confirm confirm__vertical">
            <span className="confirm confirm__label">氏名(名)</span>
            <p className="confirm confirm__field" id="family_name_kanji">
              {userInfo.family_name_kanji || ''}
            </p>
          </div>

          <div className="confirm confirm__vertical">
            <span className="confirm confirm__label">氏名カナ(姓)</span>
            <p className="confirm confirm__field" id="given_name_kana">
              {userInfo.given_name_kana || ''}
            </p>
          </div>

          <div className="confirm confirm__vertical">
            <span className="confirm confirm__label">氏名カナ(名)</span>
            <p className="confirm confirm__field" id="family_name_kana">
              {userInfo.family_name_kana || ''}
            </p>
          </div>

          <div className="confirm confirm__vertical">
            <span className="confirm confirm__label">生年月日</span>
            <p className="confirm confirm__field" id="birthday">
              {userInfo.birthday || ''}
            </p>
          </div>

          <div className="confirm confirm__vertical">
            <span className="confirm confirm__label">年齢</span>
            <p className="confirm confirm__field" id="age">
              {userInfo.age !== null ? userInfo.age : '-'}才
            </p>
          </div>

          <div className="confirm confirm__vertical">
            <span className="confirm confirm__label">性別</span>
            <p className="confirm confirm__field" id="sex">
              {userInfo.sex || ''}
            </p>
          </div>

          <div className="confirm confirm__vertical">
            <span className="confirm confirm__label">電話場号</span>
            <p className="confirm confirm__field" id="phone_number">
              {userInfo.phone_number || ''}
            </p>
          </div>

          <div className="confirm confirm__vertical">
            <span className="confirm confirm__label">メールアドレス</span>
            <p className="confirm confirm__field" id="email">
              {userInfo.email || ''}
            </p>
          </div>

          <div className="confirm confirm__vertical">
            <h2 className="confirm confirm__title">住所</h2>
            <div className="confirm confirm__vertical confirm__vertical--no-mt c-mt16">
              <span className="confirm confirm__label">郵便番号</span>
              <p className="confirm confirm__field confirm__field--medium" id="postal_code">
                {userInfo.postal_code || ''}
              </p>
            </div>
            <div className="confirm confirm__horizontal">
              <div className="confirm confirm__vertical">
                <span className="confirm confirm__label">都道府県</span>
                <p className="confirm confirm__field confirm__field--small" id="address_level1">
                  {userInfo.address_level1 || ''}
                </p>
              </div>
              <div className="confirm confirm__vertical confirm__vertical--stretch">
                <span className="confirm confirm__label">市区町村や郡</span>
                <p className="confirm confirm__field confirm__field--stretch" id="address_level2">
                  {userInfo.address_level2 || ''}
                </p>
              </div>
            </div>
            <div className="confirm confirm__horizontal">
              <div className="confirm confirm__vertical">
                <span className="confirm confirm__label">町名</span>
                <p className="confirm confirm__field confirm__field--medium" id="address_line1">
                  {userInfo.address_line1 || ''}
                </p>
              </div>
              <div className="confirm confirm__vertical confirm__vertical--stretch">
                <span className="confirm confirm__label">地番</span>
                <p className="confirm confirm__field confirm__field--stretch" id="address_line2">
                  {userInfo.address_line2 || ''}
                </p>
              </div>
            </div>
            <div className="confirm confirm__horizontal">
              <div className="confirm confirm__vertical confirm__vertical--stretch">
                <span className="confirm confirm__label">建物名</span>
                <p className="confirm confirm__field" id="address_line3">
                  {userInfo.address_line3 || ''}
                </p>
              </div>
            </div>
          </div>

          <div className="confirm confirm__vertical">
            <span className="confirm confirm__label">職業</span>
            <p className="confirm confirm__field" id="occupation">
              {userInfo.occupation || ''}
            </p>
          </div>

          {userInfo.occupation.includes('学生') && (
            <div className="confirm confirm__vertical">
              <span className="confirm confirm__label">ハンディキャップ</span>
              <p className="confirm confirm__field" id="handicap">
                {userInfo.handicap || ''}
              </p>
            </div>
          )}

          <div className="confirm confirm__vertical">
            <span className="confirm confirm__label">ツアーに出場する際の希望する地域</span>
            <p className="confirm confirm__field" id="area">
              {userInfo.technical_test_area === 0 ? '関東' : userInfo.technical_test_area === 1 ? '関西' : ''}
            </p>
          </div>

          <div className="c-container-vertical c-balanced-width--376 c-balanced-width--center c-mt32 c-mb88">
            <button type="submit" id="back" className="button button__secondary" onClick={onClickBackButton}>
              戻る
            </button>
            <button type="submit" id="send-form" className="button button__primary" onClick={onClickEdit}>
              修正する
            </button>
          </div>
        </article>
      </main>

      <footer>
        <AppFooter />
      </footer>
    </body>
  );
};

export default MemberInfoPage;
