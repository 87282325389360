import React from 'react';
import CopyRightFooter from '../components/footer/CopyRightFooter';
import MainFooter from '../components/footer/MainFooter';
import { useHistory } from 'react-router-dom';
import { getTournament } from '../services/TournamentService';

export default function MainEntryPaymentRejectedPage(props) {
  const history = useHistory();
  const tournamentId = props.location.state.tournament_id;

  const onClickToPaymentPage = () => {
    if (!tournamentId) {
      history.push('/main-entry-selection');
    }

    const tournament = getTournament(tournamentId);
    if (tournament === undefined) {
      history.push('/main-entry-selection');
    }

    history.push({
      pathname: '/main_entry_participate',
      state: tournament,
    });
  };

  return (
    <body>
      <main>
        <article className="screen screen--message">
          <div className="message">
            <div className="message message__logo">
              <img src="images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
            </div>

            <div className="message__title">
              <h1>決済に失敗しました</h1>
            </div>

            <div className="message__icon animation--wobble">
              <img src="images/alert.svg" alt="!" />
            </div>

            <div className="message__content">
              <p>初めからやり直してください。</p>
            </div>

            <button className="button button__primary c-mt56 c-mb88" onClick={onClickToPaymentPage}>
              決済ページへ
            </button>
          </div>
        </article>
      </main>

      <footer>
        <CopyRightFooter />
        <MainFooter />
      </footer>

      <script></script>
    </body>
  );
}
