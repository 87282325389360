import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppFooter from '../components/footer/AppFooter';
import { getUiConfig, getUiInstance, moveWindowByUserStatus, useUserStatus } from '../firebase/FireBaseManager';
import LoadingScreen from '../components/LoadingScreen';
import 'firebaseui-ja/dist/firebaseui.css';

const LoginPage = () => {
  const history = useHistory();
  const userStatusHook = useUserStatus();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const ui = getUiInstance();
    const uiConfig = getUiConfig({
      /**
       * 処理開始時
       */
      onProcessStart: () => {
        console.log('LoginPage onRegisterStart()');
        setIsLoading(true);
      },

      /**
       * 登録成功時
       */
      onRegisterSuccess: (params) => {
        setIsLoading(false);
        if (params.userInfo.providerId === 'password') {
          history.push('/email_confirm_request');
        } else if (params.userInfo.providerId === 'phone') {
          // history.push('/register');
          window.location.href = '/register';
        }
      },

      /**
       * 登録失敗時
       */
      onRegisterFailed: (params) => {
        console.log('LoginPage onRegisterFailed()', params);
        setIsLoading(false);
      },

      /**
       * すでに登録されていた場合
       */
      onRegisterAlreadyCreated: (params) => {
        console.log('LoginPage onRegisterAlreadyCreated()');
        console.log({ params });
        setIsLoading(false);
        moveWindowByUserStatus(history, userStatusHook());
      },
    });

    ui.start('#firebaseui-auth-container', uiConfig);
  }, []);

  return (
    <body>
      <main className="login">
        <article className="screen screen--login2">
          {isLoading ? (
            <LoadingScreen />
          ) : (
            <>
              <div className="screen__triangleHeader">
                <img src="./images/triangle-down.png" alt="background triangle" />
              </div>

              <div className="login login__header">
                <h1>ログイン</h1>
              </div>

              <div className="login login__logo">
                <img src="images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
              </div>

              <div className="c-container-vertical c-balanced-width--376 c-balanced-width--center c-mt16">
                <p className="c-paragraph c-paragraph--center">
                  下記より電話番号またはメール
                  <br />
                  アドレスでログインしてください。
                </p>
              </div>
            </>
          )}

          {/* <!-- FirebaseUIのベース--> */}
          <div className={isLoading ? 'hidden' : ''} id="firebaseui-auth-container" />
        </article>
      </main>

      <footer>
        <AppFooter />
      </footer>
    </body>
  );
};

export default LoginPage;
