import moment from 'moment';

/**
 *
 * @param date
 * @param format
 * @returns {string|null}
 */
export const convertDate = (date, format = 'YYYY-MM-DD') => {
  return date ? moment(date).format(format) : null;
};

/**
 *
 * @param birthday
 * @returns {number}
 */
export const calculateAge = (birthday) => {
  if (birthday && birthday !== '') {
    const ageDifMs = Date.now() - moment(birthday).toDate().getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  return 0;
};

/**
 *
 */
export const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

/**
 *
 * @param phone
 * @returns {string|*}
 */
export const replacePrefixPhone = (phone) => {
  // Replace phone starts with prefix
  if (phone && phone.startsWith('+')) {
    return '0' + phone.substring(3, phone.length);
  }

  return phone;
};
