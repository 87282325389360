import React from 'react';

const AppFooterLogin = () => (
  <div className="app-footer app-footer--login">
    <figure className="footer-background">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 376 56">
        <defs />
        <path
          fill="#023342"
          d="M350.6 56c-21.1-15.2-63.9-28.8-113.7-29.6V1.7h-2v1.4l-7.2 3.1 7.2 3.1v17h-2.6c-31.6 0-60.6 5.2-83.4 12.8h-5.2C92.4 39.2 47.9 46 25.9 56h324.7z"
        />
      </svg>
    </figure>

    <div className="c-container-vertical c-container-vertical--center">
      <p className="c-small">© JCG Management</p>
      <div className="c-container-horizontal c-container-horizontal--center">
        <a href="/privacy_policy" target="_blank" className="app-footer__link">
          プライバシーポリシー
        </a>
        <a href="/commercial_transactions_law" target="_blank" className="app-footer__link">
          特定商取引法について
        </a>
      </div>
    </div>
  </div>
);

export default AppFooterLogin;
