import React from 'react';
import CopyRightFooter from '../components/footer/CopyRightFooter';
import { useHistory } from 'react-router-dom';

const WelcomePage = () => {
  const history = useHistory();

  const onClickRegisterAccountButton = () => {
    history.push('/first_time_login');
  };

  return (
    <body className="main main--welcome">
      <header className="main__section">
        <div className="main__content main__content--welcome main__header main__header--welcome">
          <div className="isologo isologo--welcome">
            <img src="/images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
          </div>
        </div>
      </header>

      <main className="main__section">
        <section>
          <div className="strip">
            <div className="strip__container">
              <div className="strip__flow strip__flow--welcome"></div>

              <div className="strip__decoration strip__decoration--upper"></div>

              <div className="strip__title strip__title--welcome">
                <h1 className="c-heading--1">JCGプレシーズンツアー2021</h1>
              </div>

              <div className="strip__decoration strip__decoration--lower"></div>
            </div>
          </div>

          <div className="main__content main__content--welcome">
            <article className="article article--welcome">
              <div className="article__paragraph article__paragraph--welcome">
                <h2 className="c-heading--1 c-heading--center c-mt32">関⻄ブロックより開幕</h2>

                <p className="c-heading--2 c-heading--center c-mt32">
                  関⻄男子ツアー開幕日
                  <br />
                  (第一戦):10月4日(月)
                </p>

                <p className="c-heading--2 c-heading--center c-mt32">
                  関⻄女子ツアー開幕日
                  <br />
                  (第一戦):10月8日(金)
                </p>

                <p className="c-heading--3 c-heading--center c-heading--orange-blaze c-mt16">
                  開幕予定日と開催試合数に関しては
                  <br />
                  蔓延防止等重点措置や緊急事態宣言の発令状況により変更致します。
                </p>

                <p className="c-heading--1 c-heading--center c-heading--orange-blaze c-mt32">
                  関東ブロックの開幕日は後日告知致します。
                </p>

                <p className="c-paragraph c-mt56">
                  国内にて18試合(/1ブロック)の1Day大会を開催する事により、様々な理由から現ツアーに出ることのできない「練習生・研修生」やこれからプロを目指す「ジュニアゴルファー」の支援・強化を行います。
                </p>
                <p className="c-paragraph">
                  JCGツアーが、ツアープロへの夢を追いかけることができる環境の基盤となることで、より多くのゴルファーが、次のステージとなるツアープロという夢に繋がるよう、プロ業界を盛り上げていく基盤の一つとなることを目標としております。
                </p>
              </div>

              <div className="first-time first-time__pyramid">
                <img
                  src="/images/welcome-pyramid.svg"
                  className="welcome__pyramid--img"
                  alt="世界の新たな基盤となる！"
                />
              </div>

              <div className="first-time first-time__bubbles">
                <div className="bubble">
                  <img src="/images/bubble--01.svg" alt="18試合/ ブロック" className="bubble__image" />
                  <small className="bubble__caption">男子9試合</small>
                  <small className="bubble__caption">女子9試合</small>
                  <small className="bubble__caption">関⻄・関東の2ブロック開催予定。</small>
                </div>
                <div className="bubble">
                  <img src="/images/bubble--02.svg" alt="1DAYトーナメント" className="bubble__image" />
                  <small className="bubble__caption">定員60名で開催</small>
                </div>
                <div className="bubble">
                  <img src="/images/bubble--03.svg" alt="１００万円/DAY/ブロック" className="bubble__image" />
                  <small className="bubble__caption">参加者全員に賞金分配</small>
                </div>
                <div className="bubble">
                  <img src="/images/bubble--04.svg" alt=" 18万円/DAY/ブロック" className="bubble__image" />
                  <small className="bubble__caption">
                    ※賞金とポイントは別となり、ポイントで年間順位を確定させます。ポイント制度の詳細についてツアー開幕までに公表いたします。
                  </small>
                </div>
                <div className="bubble">
                  <img
                    src="/images/bubble--05.svg"
                    alt="練習生研修生ジュニアアマ チュア(学生含む)が参加"
                    className="bubble__image"
                  />
                  <small className="bubble__caption">各男女</small>
                </div>
              </div>

              <div className="c-table c-table--welcome c-padding--32 c-mt32 c-mb56">
                <div className="c-table__row">
                  <div className="c-table__title">エントリー代</div>
                  <div className="c-table__content">
                    <p>11,000円(税込)/日</p>
                    <p className="c-mt8">&#8251;要別途当日プレー代。当日コースにて個人清算となります。</p>
                  </div>
                </div>

                <div className="c-table__row">
                  <div className="c-table__title">ジュニアエントリー代</div>
                  <div className="c-table__content">
                    <p>3,300円(税込)/日</p>
                    <p className="c-mt8">&#8251;要別途当日プレー代。当日コースにて個人清算となります。</p>
                  </div>
                </div>

                <div className="c-table__row">
                  <div className="c-table__title">プレー代</div>
                  <div className="c-table__content">
                    <p>6,800円（デイリー信楽カントリー倶楽部）</p>
                    <p>7,300円（アートレイクゴルフ倶楽部）</p>
                    <p className="c-mt8">&#8251;当日コースにて個人清算となります</p>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </section>

        <section>
          <div className="strip">
            <div className="strip__container">
              <div className="strip__flow strip__flow--welcome"></div>

              <div className="strip__decoration strip__decoration--upper"></div>

              <div className="strip__title strip__title--welcome">
                <h1 className="c-heading--1">ジュニア基金の設立</h1>
              </div>

              <div className="strip__decoration strip__decoration--lower"></div>
            </div>
          </div>

          <div className="main__content main__content--welcome">
            <article className="article article--welcome">
              <div className="junior-fund">
                {/* <h2 className="junior-fund__title">学生が優勝した場合、賞金をジュニア基金に積立</h2> */}

                <h3 className="c-heading--2 c-heading--highl-ight c-heading--orange-blaze">
                  ツアー内最優秀学生は米国大会(※1)招待権自動会得
                </h3>

                <div className="c-container-vertical c-container-vertical--376 c-container-vertical--max-content">
                  <p className="c-subtitle c-subtitle--left c-mt16">(※1)全米アマチュアゴルフ選手権</p>
                  <p className="c-subtitle c-subtitle--left c-pl--32 c-mt8">全米女子アマチュアゴルフ選手権</p>
                  <p className="c-subtitle c-subtitle--left c-pl--32 c-mt8">全米ジュニアアマチュアゴルフ選手権</p>
                  <p className="c-subtitle c-subtitle--left c-pl--32 c-mt8">
                    全米ガールズジュニアアマチュアゴルフ選手権
                  </p>
                </div>

                <div className="junior-fund__highlight c-mt32">
                  <img src="/images/junior-highlight.svg" alt="スポンサー金額の10%をジュニア基金に積立" />
                </div>
              </div>
            </article>
          </div>
        </section>

        <section>
          <article className="main__content main__content--welcome">
            <div className="first-time__register">
              <button type="button" className="button button button__primary" onClick={onClickRegisterAccountButton}>
                アカウント登録する
              </button>
            </div>
          </article>
        </section>
      </main>

      <footer>
        <CopyRightFooter />
      </footer>
    </body>
  );
};

export default WelcomePage;
