import React, { useState, useEffect } from 'react';
import { Input, Form } from 'antd';

import { previewImageAsPromise } from '../../../firebase/FirebaseStorage';

const InputPlayerForm = (props) => {
  const { form, currentPlayer, setImageFile } = props;
  const [imageUrl, setImageUrl] = useState(null);
  useEffect(() => {
    const params = form.getFieldsValue();
    setImageUrl(params?.imageUrl);
  });

  // account type
  const [currentAccountType, setCurrentAccountType] = useState(currentPlayer?.bank_info.account_type);
  const handleChangeSelection = (e) => setCurrentAccountType(e.target.value);

  const onPreview = async (e) => {
    const image_url = await previewImageAsPromise(e);
    if (image_url) {
      setImageUrl(image_url);
      setImageFile(e.target.files[0]);
      form.setFieldsValue({ imageUrl: image_url });
    }
  };

  return (
    <>
      {/* 1 アイコン */}
      <div className="c-profile-picture c-mt8">
        {!imageUrl && (
          <>
            <img src="/images/default-profile-picture.svg" alt="デフォルトのプロフィール写真" />
          </>
        )}
        {imageUrl && (
          <>
            <img src={imageUrl} alt="プロフィール写真" />
          </>
        )}
        <div className="c-profile-picture__upload">
          <input
            type="file"
            id="profile_picture"
            name="profile_picture"
            accept="image/png, image/jpeg"
            className="c-profile-picture__input icon-7"
            onChange={onPreview}
          />
        </div>
      </div>

      <Form.Item name="imageUrl" initialValue={imageUrl}>
        <Input type="hidden" value={imageUrl} />
      </Form.Item>

      {/* 1 所属 */}
      <Form.Item name="shozoku" className="input input__set" label={<label className="input input__label">所属</label>}>
        <Input placeholder="例：〇〇" className="input input__field" maxLength={255} />
      </Form.Item>

      {/* 2 身長 */}
      <Form.Item
        name="height"
        className="input input__set"
        label={
          <label htmlFor="" className="input input__label">
            身長(cm)
          </label>
        }
        rules={[{ required: true, whitespace: true, message: '身長を入力してください。' }]}
      >
        <Input type="number" placeholder="例：163" name="height" className="input input__field" min={50} max={280} />
      </Form.Item>

      {/* 3 体重 */}
      <Form.Item
        name="bodyWeight"
        className="input input__set"
        label={
          <label htmlFor="" className="input input__label">
            体重(kg)
          </label>
        }
        rules={[{ required: true, whitespace: true, message: '体重を入力してください。' }]}
      >
        <Input type="number" placeholder="例：60" name="bodyWeight" className="input input__field" min={1} max={300} />
      </Form.Item>

      {/* 4 血液型 */}
      <Form.Item
        name="bloodType"
        className="input input__set"
        label={<span className="input input__label">血液型</span>}
      >
        <select name="bloodType" id="bloodType" required className="input input__field">
          <option value=""> - - - </option>
          <option value="A型">A型</option>
          <option value="B型">B型</option>
          <option value="AB型">AB型</option>
          <option value="O型">O型</option>
        </select>
      </Form.Item>

      {/* 5 出身地 */}
      <Form.Item
        name="birthplace"
        className="input input__set"
        label={<span className="input input__label">出身地</span>}
      >
        <select name="birthplace" id="birthplace" required className="input input__field">
          <option value=""> - - -</option>
          <option value="北海道">北海道</option>
          <option value="青森県">青森県</option>
          <option value="岩手県">岩手県</option>
          <option value="宮城県">宮城県</option>
          <option value="秋田県">秋田県</option>
          <option value="山形県">山形県</option>
          <option value="福島県">福島県</option>
          <option value="茨城県">茨城県</option>
          <option value="栃木県">栃木県</option>
          <option value="群馬県">群馬県</option>
          <option value="埼玉県">埼玉県</option>
          <option value="千葉県">千葉県</option>
          <option value="東京都">東京都</option>
          <option value="神奈川県">神奈川県</option>
          <option value="新潟県">新潟県</option>
          <option value="富山県">富山県</option>
          <option value="石川県">石川県</option>
          <option value="福井県">福井県</option>
          <option value="山梨県">山梨県</option>
          <option value="長野県">長野県</option>
          <option value="岐阜県">岐阜県</option>
          <option value="静岡県">静岡県</option>
          <option value="愛知県">愛知県</option>
          <option value="三重県">三重県</option>
          <option value="滋賀県">滋賀県</option>
          <option value="京都府">京都府</option>
          <option value="大阪府">大阪府</option>
          <option value="兵庫県">兵庫県</option>
          <option value="奈良県">奈良県</option>
          <option value="和歌山県">和歌山県</option>
          <option value="鳥取県">鳥取県</option>
          <option value="島根県">島根県</option>
          <option value="岡山県">岡山県</option>
          <option value="広島県">広島県</option>
          <option value="山口県">山口県</option>
          <option value="徳島県">徳島県</option>
          <option value="香川県">香川県</option>
          <option value="愛媛県">愛媛県</option>
          <option value="高知県">高知県</option>
          <option value="福岡県">福岡県</option>
          <option value="佐賀県">佐賀県</option>
          <option value="長崎県">長崎県</option>
          <option value="熊本県">熊本県</option>
          <option value="大分県">大分県</option>
          <option value="宮崎県">宮崎県</option>
          <option value="鹿児島県">鹿児島県</option>
          <option value="沖縄県">沖縄県</option>
          <option value="海外">海外</option>
        </select>
      </Form.Item>

      {/* 6 学校 */}
      <Form.Item
        name="school"
        className="input input__set"
        label={
          <label htmlFor="" className="input input__label">
            学校
          </label>
        }
        rules={[{ required: true, whitespace: true, message: '学校を入力してください。' }]}
      >
        <Input placeholder="例：○○大学" name="school" className="input input__field" maxLength={255} />
      </Form.Item>

      {/* 7 得意クラブ */}
      <Form.Item
        name="goodClub"
        className="input input__set"
        label={
          <label htmlFor="" className="input input__label">
            得意クラブ
          </label>
        }
        rules={[{ required: true, whitespace: true, message: '得意クラブを入力してください。' }]}
      >
        <Input placeholder="例：○○" name="goodClub" className="input input__field" maxLength={255} />
      </Form.Item>

      {/* 8 ゴルフを始めた時の年齢 */}
      <Form.Item
        name="beginningAge"
        className="input input__set"
        label={
          <label htmlFor="" className="input input__label">
            ゴルフを始めた時の年齢
          </label>
        }
        rules={[{ required: true, whitespace: true, message: 'ゴルフを始めた時の年齢を入力してください。' }]}
      >
        <Input
          type="number"
          placeholder="例：15"
          name="beginningAge"
          className="input input__field"
          min={1}
          max={100}
        />
      </Form.Item>

      {/* 9 ゴルフを始めた動機 */}
      <Form.Item
        name="motivationStart"
        className="input input__set"
        label={
          <label htmlFor="" className="input input__label">
            ゴルフを始めた動機
          </label>
        }
        rules={[{ required: true, whitespace: true, message: 'ゴルフを始めた動機を入力してください。' }]}
      >
        <Input placeholder="例：○○" name="motivationStart" className="input input__field" maxLength={255} />
      </Form.Item>

      {/* 10 ドライバー平均飛距離(ヤード) */}
      <Form.Item
        name="averageDistance"
        className="input input__set"
        label={
          <label htmlFor="" className="input input__label">
            ドライバー平均飛距離(ヤード)
          </label>
        }
        rules={[{ required: true, whitespace: true, message: 'ドライバー平均飛距離(ヤード)を入力してください。' }]}
      >
        <Input type="number" placeholder="例：290" name="averageDistance" className="input input__field" min={1} />
      </Form.Item>

      {/* 11 ベストスコア */}
      <Form.Item
        name="bestScore"
        className="input input__set"
        label={
          <label htmlFor="" className="input input__label">
            ベストスコア
          </label>
        }
        rules={[{ required: true, whitespace: true, message: 'ベストスコアを入力してください。' }]}
      >
        <Input placeholder="例：55 (場所)" name="bestScore" className="input input__field" maxLength={255} />
      </Form.Item>

      {/* 12 目標とする人 */}
      <Form.Item
        name="targetPerson"
        className="input input__set"
        label={
          <label htmlFor="" className="input input__label">
            目標とする人
          </label>
        }
        rules={[{ required: true, whitespace: true, message: '目標とする人を入力してください。' }]}
      >
        <Input placeholder="例：○○さん" name="targetPerson" className="input input__field" maxLength={255} />
      </Form.Item>

      <h3 className="c-heading--2 c-heading--center c-mt56">獲得賞金振込口座情報</h3>

      {/* 13 金融機関名 */}
      <Form.Item
        name="bankInfoBankName"
        className="input input__set"
        label={
          <label htmlFor="bank_info_bank_name" className="input input__label">
            金融機関名
            <i className="input input__tag">必須</i>
          </label>
        }
        rules={[{ required: true, whitespace: true, message: '金融機関名を入力してください。' }]}
      >
        <Input placeholder="例：○○銀行" name="bankInfoBankName" className="input input__field" maxLength={50} />
      </Form.Item>

      {/* 14 支店名 */}
      <Form.Item
        name="bankInfoBranchName"
        className="input input__set"
        label={
          <label htmlFor="bank_info_branch_name" className="input input__label">
            支店名
            <i className="input input__tag">必須</i>
          </label>
        }
        rules={[{ required: true, whitespace: true, message: '支店名を入力してください。' }]}
      >
        <Input placeholder="例：東京" name="bankInfoBranchName" className="input input__field" maxLength={50} />
      </Form.Item>

      {/* 15 口座種別 */}
      <Form.Item
        name="bankInfoAccountType"
        className="c-radio-button-set c-radio-button-set--account-type"
        label={
          <label htmlFor="bank_info_account_type" className="input input__label input input__label c-mt56">
            口座種別
            <i className="input input__tag">必須</i>
          </label>
        }
        rules={[{ required: true, whitespace: true, message: '口座種別を選択してください。' }]}
      >
        <div className="c-radio-button-set c-radio-button-set--account-type">
          <Input
            type="radio"
            id="account_type_futsuu"
            name="bankInfoAccountType"
            className="button button__secondary"
            value="普通"
            checked={currentAccountType === '普通'}
            onClick={handleChangeSelection}
          />
          <label
            htmlFor="account_type_futsuu"
            className="button button__secondary entry-participant account-type--futsuu c-mt16"
          >
            普通
          </label>

          <Input
            type="radio"
            id="account_type_touza"
            name="bankInfoAccountType"
            className="button button__secondary"
            value="当座"
            checked={currentAccountType === '当座'}
            onClick={handleChangeSelection}
          />
          <label
            htmlFor="account_type_touza"
            className="button button__secondary entry-participant account-type--touza c-mt16"
          >
            当座
          </label>
        </div>
      </Form.Item>

      {/* 16 口座番号 */}
      <Form.Item
        name="bankInfoAccountNumber"
        className="input input__set"
        label={
          <label htmlFor="bank_info_account_number" className="input input__label">
            口座番号
            <i className="input input__tag">必須</i>
          </label>
        }
        rules={[
          { required: true, whitespace: true, message: '口座番号を入力してください。' },
          { pattern: new RegExp(/^[0-9]{7}$/), message: '口座番号は７桁の数字でお願いします' },
        ]}
      >
        <Input
          id="account_number"
          placeholder="例：1234567"
          name="bankInfoAccountNumber"
          className="input input__field"
          maxLength={7}
        />
      </Form.Item>

      {/* 17 口座名義人名(カナ) */}
      <Form.Item
        name="bankInfoAccountHolder"
        className="input input__set"
        label={
          <label htmlFor="bank_info_account_holder" className="input input__label">
            口座名義人名(カナ)
            <i className="input input__tag">必須</i>
          </label>
        }
        rules={[
          { required: true, whitespace: true, message: '口座名義人名(カナ)を入力してください。' },
          { pattern: new RegExp(/^[ァ-・ｧ-ﾝﾞﾟｦ-ﾟヽヾ゛゜ー\s]+$/), message: 'カタカナで入力してください。' },
        ]}
      >
        <Input
          id="account_holder"
          placeholder="例：ヤマダ　タロウ"
          name="bankInfoAccountHolder"
          className="input input__field"
          maxLength={50}
        />
      </Form.Item>
    </>
  );
};

export default InputPlayerForm;
