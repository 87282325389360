import React from 'react';
import { useAuthIsLoggedIn } from '../firebase/FireBaseManager';
import { Redirect } from 'react-router-dom';

/**
 * Firebase認証後のコールバックを受け取る画面
 * @returns {JSX.Element}
 * @constructor
 */
const FirebaseAuthActionCallbackPage = () => {
  const authIsLoggedInHook = useAuthIsLoggedIn();

  if (authIsLoggedInHook()) {
    return <Redirect to="/main" />;
  }

  return <Redirect to="/" />;
};

export default FirebaseAuthActionCallbackPage;
