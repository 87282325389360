export default class ValidatorUtil {
  static isNumberPattern = /^[-]?([1-9]\d*|0)(\.\d+)?$/;

  /**
   * 数値チェック
   * @param {number|string}numVal
   * @returns {boolean}
   */
  static isNumber(numVal) {
    // 数値チェック
    return ValidatorUtil.isNumberPattern.test(numVal);
  }
}
