import React, { useEffect, useState } from 'react';
import './App.scss';
import RootRouter from './routes/RootRouter';
import { initialize } from './initialize';
import LoadingScreen from './components/LoadingScreen';

function App() {
  const [isInitializeDone, setIsInitializeDone] = useState(false);

  useEffect(async () => {
    // initialize application
    const initializeResult = await initialize();
    setIsInitializeDone(initializeResult);
  }, []);

  // まだ初期設定処理が終わっていない場合
  if (!isInitializeDone) {
    return <LoadingScreen />;
  }

  return <RootRouter />;
}

export default App;
