import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as queryString from 'querystring';
import { useEmailVerify } from '../firebase/FireBaseManager';

const EmailConfirmCompletePage = () => {
  // const history = useHistory();
  const location = useLocation();
  const emailVerifyHook = useEmailVerify();

  useEffect(() => {
    const params = queryString.parse(location.search);

    if (params.oobCode) {
      emailVerifyHook(params.oobCode, {
        onProcessStart: () => {
          console.log('EmailConfirmCompletePage onEmailVerify()');
        },
      });
    }
  }, []);

  const onClickLoginButton = () => {
    // history.push('/login');
    window.location.href = '/login';
  };

  return (
    <body>
      <article className="screen screen--email">
        <div className="message">
          <div className="message message__logo">
            <img src="/images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
          </div>

          <div className="message__title">
            <h1>メールアドレスが認証されました</h1>
          </div>

          <div className="message__icon animation--zoomIn c-mt56">
            <img src="/images/okay.svg" alt="email" />
          </div>

          <div className="message__content">
            <p>ログインして会員登録を行ってください。</p>
          </div>

          <div className="c-container-vertical c-balanced-width--376 c-balanced-width--center c-mt32 c-mb88">
            <button onClick={onClickLoginButton} className="button button__primary" name="returnButton">
              ログインする
            </button>
          </div>
        </div>
      </article>
    </body>
  );
};

export default EmailConfirmCompletePage;
