import React from 'react';

const ConfirmPlayerForm = (props) => {
  const { dataUser } = props;

  return (
    <>
      <figure className="c-profile-picture c-mt8">
        {!dataUser.imageUrl && (
          <>
            <img src="/images/default-profile-picture.svg" alt="デフォルトのプロフィール写真" />
          </>
        )}
        {dataUser.imageUrl && (
          <>
            <img src={dataUser.imageUrl} alt="プロフィール写真" />
          </>
        )}
      </figure>

      <div className="confirm confirm__vertical c-mt8">
        <span className="confirm confirm__label">所属</span>
        <p className="confirm confirm__field c-paragraph" id="">
          {dataUser?.shozoku || ''}
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">身長</span>
        <p className="confirm confirm__field c-paragraph" id="">
          {dataUser.height || ''}cm
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">体重</span>
        <p className="confirm confirm__field c-paragraph" id="">
          {dataUser.bodyWeight || ''}kg
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">血液型</span>
        <p className="confirm confirm__field c-paragraph" id="">
          {dataUser?.bloodType || ''}
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">出身地</span>
        <p className="confirm confirm__field c-paragraph" id="">
          {dataUser?.birthplace || ''}
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">学校</span>
        <p className="confirm confirm__field c-paragraph" id="">
          {dataUser?.school || ''}
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">得意クラブ</span>
        <p className="confirm confirm__field c-paragraph" id="">
          {dataUser?.goodClub || ''}
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">ゴルフを始めた時の年齢</span>
        <p className="confirm confirm__field c-paragraph" id="">
          {dataUser?.beginningAge || ''}
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">ゴルフを始めた動機</span>
        <p className="confirm confirm__field c-paragraph" id="">
          {dataUser?.motivationStart || ''}
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">ドライバー平均飛距離</span>
        <p className="confirm confirm__field c-paragraph" id="">
          {dataUser?.averageDistance || ''}ヤード
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">ベストスコア</span>
        <p className="confirm confirm__field c-paragraph" id="">
          {dataUser?.bestScore || ''}
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">目標とする人</span>
        <p className="confirm confirm__field c-paragraph" id="">
          {dataUser?.targetPerson || ''}
        </p>
      </div>

      <h3 className="c-heading--2 c-heading--center c-mt56">獲得賞金振込口座情報</h3>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">金融機関名</span>
        <p className="confirm confirm__field" id="bank_name">
          {dataUser?.bankInfoBankName || ''}
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">支店名</span>
        <p className="confirm confirm__field" id="branch_name">
          {dataUser?.bankInfoBranchName || ''}
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">口座種別</span>
        <p className="confirm confirm__field" id="account_type">
          {dataUser?.bankInfoAccountType || ''}
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">口座番号</span>
        <p className="confirm confirm__field" id="account_number">
          {dataUser?.bankInfoAccountNumber || ''}
        </p>
      </div>

      <div className="confirm confirm__vertical">
        <span className="confirm confirm__label">口座名義人名(カナ)</span>
        <p className="confirm confirm__field" id="account_holder">
          {dataUser?.bankInfoAccountHolder || ''}
        </p>
      </div>
    </>
  );
};

export default ConfirmPlayerForm;
