import React from 'react';

const CopyRightOnlyFooter = () => (
  <div className="copyright copyright--gunmetal">
    <div className="c-container-vertical c-container-vertical--center">
      <p className="c-small">© JCG Management</p>
    </div>
  </div>
);

export default CopyRightOnlyFooter;
