import React from 'react';
import AppFooter from '../components/footer/AppFooter';
import { useHistory } from 'react-router-dom';
import { tourSetTournamentEntryFlgService } from '../services/TournamentService';
import { UserTournamentEntryStatus } from '../const/UserConst';

/**
 * @param {string} props.location.state.id 大会ID
 * @returns {JSX.Element}
 * @constructor
 */
const MainEntryDeclineRequestPage = (props) => {
  const history = useHistory();

  /**
   * エントリーを辞退するが押された時
   */
  const onClickDecline = async () => {
    if (props?.location?.state?.id != null && props?.location?.state?.id !== '') {
      const tournamentId = props.location.state.id;
      await tourSetTournamentEntryFlgService(tournamentId, UserTournamentEntryStatus.CANCEL);
      history.push('/main_entry_decline_complete');
    }
  };

  return (
    <body>
      <main>
        <article className="screen screen--message">
          <div className="message">
            <div className="message message__logo">
              <img src="/images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
            </div>

            <div className="message__title">
              <h1>ツアーエントリー辞退確認</h1>
            </div>

            <div className="message__icon animation--zoomIn">
              <img src="/images/question.svg" alt="疑問符" />
            </div>

            <div className="message__content">
              <h2>JCGツアーコンサイズカップ</h2>
              <p>2021年9月16日午前7時</p>
              <p className="c-mt24">上記のツアーのエントリーを辞退します。</p>
              <p>よろしいですか？</p>
              <p className="c-warning c-mt8">
                ※ツアー申込期間内ならいつでも
                <br />
                エントリー可能です。
              </p>
              <p className="c-warning c-mt8">
                ※エントリー締め切り日は各ツアー
                <br />
                開始の1週間前までです。
              </p>
            </div>

            <button onClick={onClickDecline} className="button button__primary c-mt56 c-mb88">
              エントリーを辞退する
            </button>
          </div>
        </article>
      </main>

      <footer>
        <AppFooter />
      </footer>
    </body>
  );
};

export default MainEntryDeclineRequestPage;
