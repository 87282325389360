import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CopyRightFooter from '../components/footer/CopyRightFooter';
import MainFooter from '../components/footer/MainFooter';
import DesktopMenuHeader from '../components/header/DesktopMenuHeader';
import { getNoticeCollection, setViewFlag } from '../firebase/FireBaseManager';
import sanitizeHtml from 'sanitize-html';

const NoticeMessagePage = () => {
  const history = useHistory();
  const [noticeDetail, setNoticeDetail] = useState({});
  const { id } = useParams();

  const onClickBack = () => {
    history.push('/notice/inbox');
  };

  useEffect(async () => {
    const collection = getNoticeCollection();
    const noticeModel = collection.getNoticeModel(id);
    if (!noticeModel.isSet()) {
      history.push('/404');
    }
    setNoticeDetail(noticeModel);
    // TODO: 未読の場合に既読に変更する
    await setViewFlag(noticeModel);
  });

  return (
    <body className="main">
      <header className="main__section">
        <div className="main__content main__content--upper main__header">
          <div className="isologo isologo--main">
            <a href="/main">
              <img src="/images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
            </a>
          </div>
          <DesktopMenuHeader />
        </div>
      </header>

      <main className="main__section main__section--last">
        <section>
          <div className="strip strip--player">
            <div className="strip__container">
              <div className="strip__flow"></div>

              <div className="strip__decoration strip__decoration--upper"></div>

              <div className="strip__title">
                <h1 className="c-heading--1 c-heading--center">お知らせ</h1>
              </div>

              <div className="strip__decoration strip__decoration--lower"></div>
            </div>
          </div>

          <div className="main__content">
            <article className="notice-message">
              {/* お知らせタイトル */}
              <h2 className="c-heading--2 c-mb24">{noticeDetail.title}</h2>

              {/* お知らせ内容 */}
              <div className="notice-message__content">
                <p
                  className="c-paragraph"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(noticeDetail.news_text, {
                      allowedAttributes: {
                        a: ['href', 'name', 'target', 'class'],
                        img: ['src', 'alt'],
                      },
                    }),
                  }}
                />
              </div>
            </article>

            <div className="c-button-set">
              <button className="button button__primary c-mt56 c-mb88" onClick={onClickBack}>
                戻る
              </button>
            </div>
          </div>
        </section>
      </main>

      <footer>
        <CopyRightFooter />
        <MainFooter />
      </footer>
    </body>
  );
};

export default NoticeMessagePage;
