/**
 * Firebase側で管理されるお知らせ情報
 */
export class NoticeModel {
  constructor(init = {}) {
    this.version = '';
    this.id = null;
    this.title = '';
    this.news_text = '';
    this.priority = null;
    this.date_time = '';
    this.view_flag = null;

    this.set(init);
  }

  /**
   * データがセットされているかどうか
   * @returns {boolean}
   */
  isSet() {
    return this.title !== '' ? true : false;
  }

  /**
   * お知らせが既読かどうか
   * @return {boolean}
   */
  isRead() {
    return this.view_flag === 1 ? true : false;
  }

  /**
   * 重要なお知らせかどうか
   * @returns {boolean}
   */
  isPriority() {
    return this.priority === 1 ? true : false;
  }

  setViewFlag(flag) {
    this.view_flag = flag;
  }

  /**
   * set
   * @param {Object} init
   */
  set(init) {
    // validate
    if (init === undefined || init === null) return;

    // assign
    Object.assign(this, init);
  }
}
