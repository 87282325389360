import React from 'react';
import AppFooter from '../components/footer/AppFooter';
import { useHistory } from 'react-router-dom';

const ProfileUpdateCompleted = () => {
  const history = useHistory();

  /**
   * メインページへが押された時
   */
  const onClickToMain = () => {
    history.push('/main');
  };

  return (
    <body>
      <main>
        <article className="screen screen--message">
          <div className="message">
            <div className="message message__logo">
              <img src="/images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
            </div>

            <div className="message__title">
              <h1>選手情報変更完了</h1>
            </div>

            <div className="message__icon animation--zoomIn">
              <img src="/images/okay.svg" alt="OK!" />
            </div>

            <div className="message__content">
              <p>選手情報変更が完了しました。</p>
            </div>

            <button onClick={onClickToMain} className="button button__primary c-mt56 c-mb88">
              メインページへ
            </button>
          </div>
        </article>
      </main>

      <footer>
        <AppFooter />
      </footer>
    </body>
  );
};

export default ProfileUpdateCompleted;
