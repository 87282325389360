import React from 'react';
import { useHistory } from 'react-router-dom';
import DesktopMenuHeader from '../../components/header/DesktopMenuHeader';
import CopyRightFooter from '../../components/footer/CopyRightFooter';
import MainFooter from '../../components/footer/MainFooter';
import { getRegisterType } from '../../firebase/FireBaseManager';

/**
 * メイン画面(参加表明前)
 * @returns {JSX.Element}
 * @constructor
 */
const MainBeforeParticipatePage = () => {
  const history = useHistory();
  const registerType = getRegisterType();

  const onClickCheckContent = () => history.push('/test-content-confirmation');
  const onClickEntry = () => history.push('/main-entry-selection');

  return (
    <body className="main">
      <header className="main__section">
        <div className="main__content main__content--upper main__header">
          <div className="isologo isologo--main">
            <a href="/main">
              <img src="/images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
            </a>
          </div>
          <DesktopMenuHeader />
        </div>
      </header>

      <main className="main__section main__section--last">
        <section>
          <div className="strip strip--player">
            <div className="strip__container">
              <div className="strip__flow"></div>

              <div className="strip__decoration strip__decoration--upper"></div>
              {/* <!-- cssから画像読み込まれます。--> */}

              <div className="strip__title">
                <h1 className="c-heading--1">JCG関⻄プレシーズンツアー2021</h1>
              </div>

              <div className="strip__decoration strip__decoration--lower"></div>
              {/*<!-- cssから画像読み込まれます。--> */}
            </div>
          </div>

          <div className="main__content">
            <article className="article">
              <div className="article__image">
                <img src="/images/golf-00.jpg" className="" alt="記事画像" />
              </div>

              <div className="article__buttons">
                <button onClick={onClickCheckContent} className="button button__secondary">
                  JCG関⻄プレシーズンツアー2021内容確認
                </button>
                {/* 参加表明可能 */}
                {registerType === 1 && (
                  <button className="button button__primary" onClick={onClickEntry}>
                    参加表明をする
                  </button>
                )}
                {/* 参加表明済 */}
                {registerType === 2 && (
                  <button id="button_score" className="button button__primary button--disabled-gray" disabled>
                    <div className="kanri-option__icon">
                      <figure className="c-icon c-icon--devys-gray c-icon--16">
                        <svg xmlns="http://www.w3.org/2000/svg" data-name="okay icon" viewBox="0 0 88 91.028">
                          <defs />
                          <path
                            fillRule="evenodd"
                            d="M42.98 5.068a42.767 42.767 0 1033.629 16.075l-4.047 4.8c5.131 6.313 7.138 13.328 7.138 22.105a36.721 36.721 0 11-36.72-36.721c6.433 0 11.245.925 16.5 3.826l3.845-5.049A41.073 41.073 0 0042.98 5.067z"
                            data-name="Path 1700"
                          />
                          <path
                            d="M27.073 42.396q2.6 0 3.933 4.266 2.666 8 3.8 8 .867 0 1.8-1.333Q55.342 23.331 71.269 4.801q4.132-4.8 13.132-4.8a6.813 6.813 0 012.866.4 1.161 1.161 0 01.733 1q0 .933-2.2 3.666a647.52 647.52 0 00-47.728 65.326q-1.533 2.4-6.266 2.4-4.8 0-5.666-.4-2.267-1-5.333-10.2-3.466-10.2-3.466-12.8 0-2.8 4.666-5.4a10.7 10.7 0 015.066-1.6"
                            data-name="Path 1701"
                          />
                        </svg>
                      </figure>
                    </div>
                    <h1 className="c-button-text c-button-text--icon">参加表明完了</h1>
                  </button>
                )}
                {/* 参加表明上限 */}
                {registerType === 3 && (
                  <button id="button_score" className="button button__primary button--disabled-gray" disabled>
                    <div className="kanri-option__icon">
                      <figure className="c-icon c-icon--devys-gray c-icon--16">
                        <svg xmlns="http://www.w3.org/2000/svg" data-name="okay icon" viewBox="0 0 88 91.028">
                          <defs />
                          <path
                            fillRule="evenodd"
                            d="M42.98 5.068a42.767 42.767 0 1033.629 16.075l-4.047 4.8c5.131 6.313 7.138 13.328 7.138 22.105a36.721 36.721 0 11-36.72-36.721c6.433 0 11.245.925 16.5 3.826l3.845-5.049A41.073 41.073 0 0042.98 5.067z"
                            data-name="Path 1700"
                          />
                          <path
                            d="M27.073 42.396q2.6 0 3.933 4.266 2.666 8 3.8 8 .867 0 1.8-1.333Q55.342 23.331 71.269 4.801q4.132-4.8 13.132-4.8a6.813 6.813 0 012.866.4 1.161 1.161 0 01.733 1q0 .933-2.2 3.666a647.52 647.52 0 00-47.728 65.326q-1.533 2.4-6.266 2.4-4.8 0-5.666-.4-2.267-1-5.333-10.2-3.466-10.2-3.466-12.8 0-2.8 4.666-5.4a10.7 10.7 0 015.066-1.6"
                            data-name="Path 1701"
                          />
                        </svg>
                      </figure>
                    </div>
                    <h1 className="c-button-text c-button-text--icon">受付終了</h1>
                  </button>
                )}
              </div>

              <div className="main-information">
                <h2 className="c-heading--1 c-heading--center">関西会場</h2>

                <div className="main-information__section-line"></div>

                <div className="c-container-horizontal c-container-horizontal--responsive">
                  <div className="main-information__article">
                    <h4 className="c-heading--2 c-heading--dark-slate-gray">アートレイクゴルフ倶楽部</h4>

                    <p className="c-subtitle c-mt8">〒563-0135 大阪府豊能郡能勢町野間西山９５−３</p>
                    <p className="c-paragraph">072-737-0888</p>
                    <a href="http://www.artlake-gc.com/" className="c-link">
                      http://www.artlake-gc.com/
                    </a>

                    <p className="c-paragraph c-mt16">利用コース：インコース → アウトコース</p>
                    <p className="c-paragraph">利用ティー：バックティー（黒ティー）</p>
                  </div>

                  <div className="main-information__article">
                    <h4 className="c-heading--2 c-heading--dark-slate-gray">デイリー信楽カントリー倶楽部</h4>

                    <p className="c-subtitle c-mt8">〒529-1821 滋賀県甲賀市信楽町多羅尾１５７７</p>
                    <p className="c-paragraph">0748-85-8020</p>
                    <a href="https://www.d-shigaraki.co.jp/" className="c-link">
                      https://www.d-shigaraki.co.jp/
                    </a>

                    <p className="c-paragraph c-mt16">利用コース：柳生コース → 甲賀コース</p>
                    <p className="c-paragraph">利用ティー：バックティー（黒ティー）</p>
                  </div>
                </div>

                <div className="main-information__section-line c-mt56 c-mb32"></div>

                <h2 className="c-heading--1">JCG登録手順</h2>

                <ol className="steps c-mt8">
                  <li className="steps steps__one-step">
                    <span className="c-subtitle c-subtitle--center">8/27</span>
                    <picture className="steps steps__image">
                      <img src="/images/step01.svg" alt="step 1" />
                    </picture>
                    <p className="c-paragraph c-paragraph--bold c-paragraph--center c-mt8 c-mb8">参加表明</p>
                    <p className="c-subtitle">
                      口座開設はGMO
                      <br />
                      あおぞらネット
                      <br />
                      銀行がおすすめ！
                    </p>
                    <p className="c-subtitle">
                      賞金受け取り時の
                      <br />
                      振り込み手数料も
                      <br />
                      0円となります。
                    </p>
                  </li>

                  <li className="steps steps__one-step">
                    <span className="c-subtitle c-subtitle--center">9/12</span>
                    <picture className="steps steps__image">
                      <img src="/images/step02.svg" alt="step 2" />
                    </picture>
                    <p className="c-paragraph c-paragraph--bold c-paragraph--center c-mt8 c-mb8">募集締め切り</p>
                  </li>

                  <li className="steps steps__one-step">
                    <span className="c-subtitle c-subtitle--center">9/15</span>
                    <picture className="steps steps__image">
                      <img src="/images/step03.svg" alt="step 3" />
                    </picture>
                    <p className="c-paragraph c-paragraph--bold c-paragraph--center c-mt8 c-mb8">
                      エントリー
                      <br />
                      申し込み
                    </p>
                  </li>

                  <li className="steps steps__one-step">
                    <picture className="steps steps__image c-mt24">
                      <img src="/images/step04.svg" alt="step 4" />
                    </picture>
                    <p className="c-paragraph c-paragraph--bold c-paragraph--center c-mt8 c-mb8">
                      エントリー
                      <br />
                      (カード決済)
                    </p>
                  </li>

                  <li className="steps steps__one-step">
                    <span className="c-subtitle c-subtitle--center">10/4</span>
                    <picture className="steps steps__image">
                      <img src="/images/step05.svg" alt="step 5" />
                    </picture>
                    <p className="c-paragraph c-paragraph--bold c-paragraph--center c-mt8 c-mb8">
                      エントリー
                      <br />
                      JCGツアー
                      <br />
                      開始
                    </p>
                  </li>
                </ol>
              </div>
            </article>
          </div>
        </section>
      </main>

      <footer>
        <CopyRightFooter />
        <MainFooter />
      </footer>
    </body>
  );
};

export default MainBeforeParticipatePage;
