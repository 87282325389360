import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DesktopMenuHeader from '../../components/header/DesktopMenuHeader';
import CopyRightFooter from '../../components/footer/CopyRightFooter';
import MainFooter from '../../components/footer/MainFooter';
import IconPageNationNext from '../../components/icon/IconPaginatioinNext';
import ValidatorUtil from '../../utils/ValidatorUtil';
import { UserTournamentEntryStatus } from '../../const/UserConst';
import IconSun from '../../components/icon/IconSun';

import moment from 'moment';
import SlideShow from '../../components/library/SlideShow';

/**
 * メイン画面(ツアー参加後)
 * @param {TournamentDataModel[]} tournamentData
 * @returns {JSX.Element}
 * @constructor
 */
const MainTourPage = ({ tournamentData }) => {
  const history = useHistory();
  const [isLoadSlideShow, setIsLoadSlideShow] = useState(false);

  useEffect(() => {
    if (!isLoadSlideShow) {
      SlideShow();
      setIsLoadSlideShow(true);
    }
  });

  /**
   * 「ツアー内容確認」をクリック
   * @param {TournamentDataModel} _tournament
   */
  const onClickCheckTourContent = () => {
    history.push('/test-content-confirmation');
  };

  /**
   * 「このツアーにエントリーする」をクリック
   * @param {TournamentDataModel} _tournament
   */
  const onClickEntry = (_tournament) => {
    history.push({
      pathname: '/main_entry_participate',
      state: _tournament,
    });
  };

  /**
   * 「このツアーにエントリーしない」をクリック
   * @param {TournamentDataModel} _tournament
   */
  const onClickNotEntry = (_tournament) => {
    history.push({
      pathname: '/main_entry_decline_request',
      state: _tournament,
    });
  };

  /**
   * 「このツアーのエントリー再開する」をクリック
   * @param {TournamentDataModel} _tournament
   */
  const resumeEntry = (_tournament) => {
    history.push({
      pathname: '/main_entry_participate',
      state: _tournament,
    });
  };

  /**
   * @param {TournamentDataModel} _tournament
   */
  const getTourStartTime = (_tournament) => {
    if (_tournament == null || _tournament.startMillisecond == null || _tournament.startMillisecond === '') return '';
    return moment(_tournament.startMillisecond).format('YYYY年M月D日ah時');
  };

  /**
   * 賞金のテキスト取得
   * @param _place
   * @returns {string}
   */
  const getPlaceText = (_place) => {
    if (_place == null || _place === '') return '';

    if (ValidatorUtil.isNumber(_place)) {
      const _placeNumber = Number(_place);
      if (_placeNumber >= 10000) {
        return `${_placeNumber / 10000}万円`;
      } else {
        return `${_placeNumber}円`;
      }
    }

    return _place;
  };

  return (
    <body className="main">
      <header className="main__section">
        <div className="main__content main__content--upper main__header">
          <div className="isologo isologo--main">
            <a href="/main">
              <img src="/images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
            </a>
          </div>

          <DesktopMenuHeader />
        </div>
      </header>

      <main className="main__section main__section--last">
        <section>
          {/* <!-- 目出しストリップ。 「strip--player」クラス名追加と、ストリップの上右側にゴルフ選手のシルエットが表示されます。 --> */}
          <div className="strip strip--player">
            <div className="strip__container">
              {/* }<!-- ストリップの背景エリア。 空いてるタグです。cssから設定します。--> */}
              <div className="strip__flow"></div>

              {/* <!-- cssから画像読み込まれます。--> */}
              <div className="strip__decoration strip__decoration--upper"></div>

              {/* <!-- 目出し文言 --> */}
              <div className="strip__title">
                <h1 className="c-heading--1">JCG関⻄プレシーズンツアー2021</h1>
              </div>

              {/* }<!-- cssから画像読み込まれます。--> */}
              <div className="strip__decoration strip__decoration--lower"></div>
            </div>
          </div>

          <div className="main__content">
            {/* tournament loop */}
            {tournamentData.map((_tournament, _tournamentId) => (
              <article className="article" key={`${_tournament.id}_${_tournamentId}`}>
                <h2 className="c-heading--2">{_tournament.title || ''}</h2>
                <span className="c-subtitle article__subtitle">
                  {!_tournament.isPostponedPending() ? getTourStartTime(_tournament) : '----/--/--'}
                </span>

                {/*<!-- スライドショー -->*/}
                <div className="slider c-mt16">
                  {/*<!-- このコンテナの中に画像を並べます。 -->*/}
                  <div className="slider__container">
                    {_tournament.getPictureList().map((_picture, _index) => (
                      <div
                        className={`slider__item ${_index === 0 ? 'slider__item--active' : ''}`}
                        key={`${_tournament.id}_${_picture}`}
                      >
                        <img className="slider__img" src={_picture} alt="画像" />
                      </div>
                    ))}
                  </div>

                  {/*<!-- スライドショーのコントロール -->*/}
                  <div className="slider__btn-box">
                    {/*<!-- 次へボタン -->*/}
                    <button className="slider__btn slider__btn-prev">
                      <figure className="c-icon c-margin-reset">
                        <IconPageNationNext />
                      </figure>
                    </button>
                    {/*<!-- 前のボタン -->*/}
                    <button className="slider__btn slider__btn-next">
                      <figure className="c-icon c-margin-reset">
                        <IconPageNationNext />
                      </figure>
                    </button>
                    {/*<!-- ナビゲーションドット -->*/}
                    <div className="slider__controls">
                      <button className="slider__control slider__control--active" type="button">
                        <span className="slider__control-text sr-onl-y">1番目スライドへ</span>
                      </button>
                    </div>
                  </div>
                </div>

                {/*<!-- 賞金総額情報 -->*/}
                <div className="c-container-vertical c-container-vertical--center c-mt24">
                  <h3 className="c-heading--2">
                    賞金総額：<i className="c-heading--highlight">￥1,000,000</i>
                  </h3>

                  <ol className="c-table c-table--no-margin c-container-horizontal c-container-horizontal--responsive c-container-horizontal--center c-mt24">
                    <li className="c-table__row c-prl--16">
                      <span className="c-table__title">優勝：</span>
                      <span className="c-table__content">{getPlaceText(_tournament.first_place)}</span>
                    </li>

                    <li className="c-table__row c-prl--16">
                      <span className="c-table__title">副賞：</span>
                      <span className="c-table__content">{getPlaceText(_tournament.special_prize)}</span>
                    </li>

                    <li className="c-table__row c-prl--16">
                      <span className="c-table__title">2位：</span>
                      <span className="c-table__content">{getPlaceText(_tournament.second_place)}</span>
                    </li>

                    <li className="c-table__row c-prl--16">
                      <span className="c-table__title">3位：</span>
                      <span className="c-table__content">{getPlaceText(_tournament.third_place)}</span>
                    </li>

                    <li className="c-table__row c-prl--16">
                      <span className="c-table__title">4位：</span>
                      <span className="c-table__content">{getPlaceText(_tournament.fourth_place)}</span>
                    </li>

                    <li className="c-table__row c-prl--16">
                      <span className="c-table__title">5位：</span>
                      <span className="c-table__content">{getPlaceText(_tournament.fifth_place)}</span>
                    </li>

                    <li className="c-table__row c-prl--16">
                      <span className="c-table__title">6位～10位：</span>
                      <span className="c-table__content">{getPlaceText(_tournament.sixth_tenth)}</span>
                    </li>

                    <li className="c-table__row c-prl--16">
                      <span className="c-table__title">11位～60位：</span>
                      <span className="c-table__content">{getPlaceText(_tournament.eleventh_sixtieth)}</span>
                    </li>
                  </ol>
                </div>

                {/*<!-- エントリーかツアーのオプションボタン。 -->*/}

                {/* ツアー期間内の場合 */}
                {_tournament.isEntryPeriod() ? (
                  // 未エントリー
                  _tournament.getEntryStatus() === UserTournamentEntryStatus.NOT_ENTRY ? (
                    <div className="c-container-horizontal c-container-horizontal--space-evenly c-container-horizontal--responsive">
                      <button onClick={() => onClickCheckTourContent()} className="button button__secondary">
                        ツアー内容確認
                      </button>
                      <div className="c-button-vertical-container c-m16">
                        <button onClick={() => onClickEntry(_tournament)} className="button button__primary">
                          このツアーにエントリーする
                        </button>
                        <button onClick={() => onClickNotEntry(_tournament)} className="button button__secondary">
                          このツアーにエントリーしない
                        </button>
                      </div>
                    </div>
                  ) : // エントリー済み
                  _tournament.getEntryStatus() === UserTournamentEntryStatus.ENTRY ? (
                    <div className="c-container-vertical c-container-vertical--center c-container-vertical--orange-blaze c-padding--8 c-radius--8 c-mt24">
                      <p className="c-subtitle">このツアーは</p>
                      <div className="c-container-horizontal">
                        <span className="c-heading--2 c-pr--8">エントリー済み</span>
                        <figure className="c-icon c-icon--24">
                          <IconSun />
                        </figure>
                      </div>
                    </div>
                  ) : (
                    // 辞退
                    <>
                      <div className="c-container-vertical c-container-vertical--center c-container-vertical--gunmetal-5 c-radius--8 c-mt24">
                        <p className="c-subtitle">このツアーは</p>
                        <div className="c-container-horizontal">
                          <span className="c-heading--2 c-pr--8">エントリー無し</span>
                        </div>
                      </div>
                      <div className="c-container-horizontal c-container-horizontal--space-evenly c-container-horizontal--responsive">
                        <button onClick={() => onClickCheckTourContent()} className="button button__secondary">
                          ツアー内容確認
                        </button>
                        <div className="c-button-vertical-container c-mt16">
                          <button onClick={() => resumeEntry(_tournament)} className="button button__secondary">
                            このツアーのエントリー再開する
                          </button>
                        </div>
                      </div>
                    </>
                  )
                ) : (
                  <div className="article__buttons">
                    {/* ツアー期間外の場合 */}
                    <button onClick={() => onClickCheckTourContent()} className="button button__secondary c-mt48">
                      ツアー内容確認
                    </button>
                    {/*<!-- 「button--disabled-gray」と<button>タグに「disabled」属性使うと、ボタンの見た目は灰色になります。  -->*/}
                    <div className="c-button-vertical-container c-mt16">
                      <p className="c-warning c-warning--center">エントリー受付期間終了</p>
                      <button className="button button__primary button--disabled-gray c-mt8" disabled>
                        {[UserTournamentEntryStatus.NOT_ENTRY, UserTournamentEntryStatus.CANCEL].includes(
                          _tournament.getEntryStatus()
                        )
                          ? 'このツアーにエントリーしない'
                          : 'このツアーにエントリーする'}
                      </button>
                    </div>
                  </div>
                )}
                {/*<!-- 延期お知らせ。 -->*/}
                {_tournament.isPostponedPending() && (
                  <div className="c-container-vertical c-container-vertical--center c-container-vertical--ivory c-container-vertical--dashed-orange c-radius--8 c-padding--8 c-mt24">
                    <div className="c-icon c-icon--24">
                      <img src="images/alert.svg" alt="!" />
                    </div>
                    <p className="c-heading--2 c-heading--center c-heading--orange-blaze c-mt8">
                      このツアーは延期になりました。
                    </p>
                    <p className="c-warning c-warning--center c-mt8">
                      延期先の日程が決まりましたらお知らせに通知されます。
                    </p>
                    <p className="c-warning c-warning--center">決定までしばらくお待ちください。</p>
                  </div>
                )}

                {/*<!-- 会場情報 -->*/}
                <div className="highlight">
                  <div className="highlight__item">
                    <i className="icon icon--club"></i>
                    <p className="c-info">{_tournament.getCourseData().club_name}</p>
                  </div>

                  <div className="highlight__item">
                    <i className="icon icon--location"></i>
                    <p className="c-info">{_tournament.getCourseData().club_address}</p>
                  </div>

                  <div className="highlight__item">
                    <i className="icon icon--phone"></i>
                    <p className="c-info">{_tournament.getCourseData().club_phone}</p>
                  </div>

                  <div className="highlight__item">
                    <div className="highlight__icon">
                      <i className="icon icon--website"></i>
                    </div>
                    <a href={_tournament.getCourseData().club_website} className="c-link">
                      {_tournament.getCourseData().club_website}
                    </a>
                  </div>

                  <div className="highlight__item">
                    <p className="c-info">利用コース: {_tournament.getCourseNames().join(' → ')}</p>
                  </div>

                  <div className="highlight__item">
                    <p className="c-info">利用ティー: {_tournament.getTees()}</p>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </section>
      </main>

      <footer>
        <CopyRightFooter />
        <MainFooter />
      </footer>

      {/*<script src="/scripts/slideshow.js"></script>*/}
    </body>
  );
};

export default MainTourPage;
