import React from 'react';
import CopyRightOnlyFooter from '../components/footer/CopyRightOnlyFooter';

const CommercialTransactionsLawPage = () => {
  /**
   * 閉じるが押された時
   */
  const onClickClose = () => {
    window.close();
  };

  return (
    <body>
      <header className="c-container-vertical c-container-vertical--center c-prl--16">
        <div className="c-logo--144 c-mt32">
          <img src="images/logo--dark.svg" className="c-logo__source" width="232" height="76" alt="JCG Management" />
        </div>

        <h1 className="c-heading--1 c-mt56">特定商取引法について</h1>
      </header>

      <main>
        <div className="c-table c-table--984 c-mt32">
          <div className="c-table__row">
            <div className="c-table__title">販売事業者名</div>
            <div className="c-table__content">株式会社JCGマネージメント</div>
          </div>

          <div className="c-table__row">
            <div className="c-table__title">販売事業者所在地</div>
            <div className="c-table__content">〒541-0055 大阪市中央区船場中央３－３－９ 船場センタービル９号館1階</div>
          </div>

          <div className="c-table__row">
            <div className="c-table__title">代表者または運営統括責任者</div>
            <div className="c-table__content">丘 智将</div>
          </div>

          <div className="c-table__row">
            <div className="c-table__title">代表者または運営統括責任者（フリガナ）</div>
            <div className="c-table__content">オカ トモマサ</div>
          </div>

          <div className="c-table__row">
            <div className="c-table__title">連絡先／ホームページ</div>
            <div className="c-table__content">http://www.jcg-management.co.jp/</div>
          </div>

          <div className="c-table__row">
            <div className="c-table__title">連絡先／電子メール</div>
            <div className="c-table__content">info@jcg-management.co.jp</div>
          </div>

          <div className="c-table__row">
            <div className="c-table__title">連絡先／TEL</div>
            <div className="c-table__content">06-6125-5778</div>
          </div>

          <div className="c-table__row">
            <div className="c-table__title">販売価格帯</div>
            <div className="c-table__content">3,300円～11,000円</div>
          </div>

          <div className="c-table__row">
            <div className="c-table__title">商品等の引き渡し時期（日数）・発送方法</div>
            <div className="c-table__content">1日～2日</div>
          </div>

          <div className="c-table__row">
            <div className="c-table__title">代金の支払時期および方法</div>
            <div className="c-table__content">クレジットカード</div>
          </div>

          <div className="c-table__row">
            <div className="c-table__title">商品代金以外に必要な費用 ／送料、消費税等</div>
            <div className="c-table__content">別途消費税が必要です。</div>
          </div>

          <div className="c-table__row">
            <div className="c-table__title">大会申込後のキャンセルについて</div>
            <div className="c-table__content">自己都合によるキャンセルの場合、エントリーフィの返金は致しかねます。</div>
          </div>
        </div>

        <div className="c-button-set c-mt56 c-mb88">
          <button id="back" onClick={onClickClose} className="button button__primary">
            閉じる
          </button>
        </div>
      </main>

      <footer>
        <CopyRightOnlyFooter />
      </footer>
    </body>
  );
};

export default CommercialTransactionsLawPage;
