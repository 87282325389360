import React from 'react';
import { useHistory } from 'react-router-dom';
import AppFooter from '../components/footer/AppFooter';
import { getAppPlayerModel, getAppUserModel } from '../firebase/FireBaseManager';
import { AppPlayerModel, AppUserModel } from '../models/UserModel';

const ProfileViewPage = () => {
  const history = useHistory();
  const userInfo = getAppUserModel() || new AppUserModel();
  const playerInfo = getAppPlayerModel() || new AppPlayerModel();

  const onClickEdit = () => {
    history.push('/profile_update');
  };

  return (
    <body className="c-screen">
      <header className="c-container-vertical c-mt16">
        <div className="c-logo c-logo--144">
          <img src="/images/logo--dark.svg" alt="JCG Management" className="c-logo__source" />
        </div>
        <h1 className="c-heading--1 c-heading--center c-mt32">選手情報</h1>
      </header>

      <main className="c-balanced-width c-balanced-width--608 c-prl--8 c-mb88">
        <h2 className="c-heading--2 c-heading--center c-mt32">
          {userInfo.given_name_kanji}
          {userInfo.family_name_kanji}
        </h2>

        <div className="c-container-vertical">
          <div className="c-profile-picture c-mt8">
            {playerInfo.image_url && (
              <>
                <img src={playerInfo.image_url} alt="プロフィール写真" />
              </>
            )}
            {!playerInfo.image_url && (
              <>
                <img
                  src="/images/default-profile-picture.svg"
                  alt="デフォルトのプロフィール写真"
                  onError={(e) => {
                    e.currentTarget.src = '/images/default-profile-picture.svg';
                  }}
                />
              </>
            )}
          </div>

          <div className="c-container-horizontal c-mt32">
            <div className="c-data__title">所属</div>
            <div className="c-data__slot">{playerInfo.shozoku || ''}</div>
          </div>

          <div className="c-container-horizontal">
            <div className="c-data__title">生年月日</div>
            <div className="c-data__slot">{userInfo.birthday || ''}</div>
          </div>

          <div className="c-container-horizontal">
            <div className="c-data__title">身長</div>
            <div className="c-data__slot">{playerInfo.height || ''}cm</div>
          </div>

          <div className="c-container-horizontal">
            <div className="c-data__title">体重</div>
            <div className="c-data__slot">{playerInfo.body_weight || ''}kg</div>
          </div>

          <div className="c-container-horizontal">
            <div className="c-data__title">血液型</div>
            <div className="c-data__slot">{playerInfo.blood_type || ''}</div>
          </div>

          <div className="c-container-horizontal">
            <div className="c-data__title">出身地</div>
            <div className="c-data__slot">{playerInfo.birthplace || ''}</div>
          </div>

          <div className="c-container-horizontal">
            <div className="c-data__title">学校</div>
            <div className="c-data__slot">{playerInfo.school || ''}</div>
          </div>

          <div className="c-container-horizontal">
            <div className="c-data__title">得意クラブ</div>
            <div className="c-data__slot">{playerInfo.good_club || ''}</div>
          </div>

          <div className="c-container-horizontal">
            <div className="c-data__title">ゴルフを始めた時の年齢</div>
            <div className="c-data__slot">{playerInfo.beginning_age || ''}歳</div>
          </div>

          <div className="c-container-horizontal">
            <div className="c-data__title">ゴルフを始めた動機</div>
            <div className="c-data__slot">{playerInfo.motivation_start || ''}</div>
          </div>

          <div className="c-container-horizontal">
            <div className="c-data__title">ドライバー平均飛距離</div>
            <div className="c-data__slot">{playerInfo.average_distance}ヤード</div>
          </div>

          <div className="c-container-horizontal">
            <div className="c-data__title">ベストスコア</div>
            <div className="c-data__slot">{playerInfo.best_score || ''}</div>
          </div>

          <div className="c-container-horizontal">
            <div className="c-data__title">目標とする人</div>
            <div className="c-data__slot">{playerInfo.target_person || ''}</div>
          </div>
        </div>

        {/* JCGツアー成績 */}
        {/* TODO: 取得APIが実装されたら実装する。 */}
        <h3 className="c-heading--3 c-heading--center c-mt32">JCGツアー成績</h3>

        <div className="c-container-vertical c-mt16">
          {/* <!-- 一年間部分 --> */}
          <div className="c-container-vertical">
            <div className="c-data__title c-data__title--center">2021</div>
            {/* <!-- カラムタイトル --> */}
            <div className="c-container-horizontal">
              <div className="c-data__title c-data__title--dark-slate-gray">ツアー名</div>
              <div className="c-data__title c-data__title--dark-slate-gray">最終順位</div>
              <div className="c-data__title c-data__title--dark-slate-gray">会得賞金</div>
              <div className="c-data__title c-data__title--dark-slate-gray">
                会得
                <br />
                ランキング
                <br />
                ポイント
              </div>
            </div>
            {/* <!-- 会員データ （一列）--> */}
            {/*
            <div className="c-container-horizontal">
              <div className="c-data__slot">日本オープンゴルフ選手権競技</div>
              <div className="c-data__slot">21位 T</div>
              <div className="c-data__slot">10,000円</div>
              <div className="c-data__slot">1pt</div>
            </div>
            */}
          </div>

          {/* <!-- 一年間部分 --> */}
          <div className="c-container-vertical">
            <div className="c-data__title c-data__title--center">2020</div>
            {/* <!-- カラムタイトル --> */}
            <div className="c-container-horizontal">
              <div className="c-data__title c-data__title--dark-slate-gray">ツアー名</div>
              <div className="c-data__title c-data__title--dark-slate-gray">最終順位</div>
              <div className="c-data__title c-data__title--dark-slate-gray">会得賞金</div>
              <div className="c-data__title c-data__title--dark-slate-gray">
                会得
                <br />
                ランキング
                <br />
                ポイント
              </div>
            </div>
            {/* <!-- 会員データ （一列）--> */}
            {/*
            <div className="c-container-horizontal">
              <div className="c-data__slot">日本オープンゴルフ選手権競技</div>
              <div className="c-data__slot">23位 T</div>
              <div className="c-data__slot">10,000円</div>
              <div className="c-data__slot">1pt</div>
            </div>
            */}
          </div>
        </div>

        <div className="c-button-set c-mt56">
          <button onClick={onClickEdit} id="next" className="button button__secondary">
            情報を編集
          </button>
        </div>
      </main>

      <footer>
        <AppFooter />
      </footer>
    </body>
  );
};

export default ProfileViewPage;
