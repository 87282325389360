import React, { useState } from 'react';
import { Input, Form } from 'antd';
import moment from 'moment';
import axios from 'axios';
import axiosJsonpAdapter from 'axios-jsonp';

const InputUserForm = (props) => {
  const { isUpdate, currentUser, form } = props;
  const [occupation, setOccupation] = useState(currentUser?.occupation || '');
  const [prevPostalCode, setPrevPostalCode] = useState(form.getFieldsValue().postalCode || '');
  const handleChangeOccupation = (e) => setOccupation(e.target.value);

  window.zipCallback = (json) => {
    if (json == null || json.results == null || json.results.size === 0) return;
    const prefecture = json.results[0].address1;
    const city = json.results[0].address2;
    const town = json.results[0].address3;
    form.setFieldsValue({
      addressLevel1: prefecture,
      addressLevel2: city,
      addressLine1: town,
      addressLine2: '',
      addressLine3: '',
    });
  };

  const changeAddress = (e) => {
    const postalCode = e.target.value;
    if (postalCode == prevPostalCode) {
      setPrevPostalCode(postalCode);
      return;
    }
    axios.get(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode}&callback=zipCallback`, {
      adapter: axiosJsonpAdapter,
    });
    setPrevPostalCode(postalCode);
  };

  return (
    <>
      {/* 1 氏名(姓) */}
      <Form.Item
        name="givenName"
        className="input input__set"
        label={
          <label className="input input__label">
            氏名(姓) <i className="input input__tag">必須</i>
          </label>
        }
        rules={[{ required: true, whitespace: true, message: '姓を入力してください。' }]}
      >
        <Input placeholder="例：山田" name="givenName" className="input input__field" maxLength={255} />
      </Form.Item>

      {/* 2 氏名(名) */}
      <Form.Item
        name="familyNameKanji"
        className="input input__set"
        label={
          <label className="input input__label">
            氏名(名)<i className="input input__tag">必須</i>
          </label>
        }
        rules={[{ required: true, whitespace: true, message: '名を入力してください。' }]}
      >
        <Input placeholder="例：太郎" className="input input__field" maxLength={255} />
      </Form.Item>

      {/* 3 氏名カナ(姓) */}
      <Form.Item
        name="givenNameKana"
        className="input input__set"
        label={
          <label className="input input__label">
            氏名カナ(姓)<i className="input input__tag">必須</i>
          </label>
        }
        rules={[
          { required: true, whitespace: true, message: 'フリガナを入力してください。' },
          { pattern: new RegExp(/^[ァ-・ｧ-ﾝﾞﾟｦ-ﾟヽヾ゛゜ー]+$/), message: 'カタカナで入力してください。' },
        ]}
      >
        <Input placeholder="例：ヤマダ" className="input input__field" maxLength={255} />
      </Form.Item>

      {/* 4 氏名カナ(名) */}
      <Form.Item
        name="familyNameKana"
        className="input input__set"
        label={
          <label className="input input__label">
            氏名カナ(名)<i className="input input__tag">必須</i>
          </label>
        }
        rules={[
          { required: true, whitespace: true, message: 'フリガナを入力してください。' },
          { pattern: new RegExp(/^[ァ-・ｧ-ﾝﾞﾟｦ-ﾟヽヾ゛゜ー]+$/), message: 'カタカナで入力してください。' },
        ]}
      >
        <Input placeholder="例：タロウ" className="input input__field" maxLength={255} />
      </Form.Item>

      {/* 5 生年月日 */}
      <Form.Item
        name="birthday"
        className="input input__set"
        label={
          <label className="input input__label">
            生年月日<i className="input input__tag">必須</i>
          </label>
        }
        rules={[
          { required: true, whitespace: true, message: '生年月日を入力してください。' },
          {
            validator(rule, value) {
              if (moment(value).diff(moment(new Date())) > 0) {
                return Promise.reject('誕生日は今日の日付より過去の日付であること。');
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        <Input type="date" className="input input__field" />
      </Form.Item>

      {/* 7 性別 */}
      <Form.Item
        name="sex"
        className="input input__set"
        label={
          <label className="input input__label">
            性別<i className="input input__tag">必須</i>
          </label>
        }
        // rules={[{ required: true, whitespace: true }]}
      >
        <select name="sex" id="sex" required className="input input__field">
          <option value="">選択してください</option>
          <option value="女性">女性</option>
          <option value="男性">男性</option>
        </select>
      </Form.Item>

      {/* 8 電話番号 */}
      <Form.Item
        name="phoneNumber"
        className="input input__set"
        label={
          <label className="input input__label">
            電話番号<i className="input input__tag">必須</i>
          </label>
        }
        rules={[
          { required: true, whitespace: true, message: '電話番号を入力してください。' },
          { pattern: new RegExp(/^[0-9]{3,11}$/), message: '電話番号を入力してください。' },
        ]}
      >
        <Input type="tel" placeholder="例：08077778888" className="input input__field" maxLength={255} />
      </Form.Item>

      {/* 9 メールアドレス */}
      <Form.Item
        name="email"
        className="input input__set"
        label={
          <label className="input input__label">
            メールアドレス<i className="input input__tag">必須</i>
          </label>
        }
        rules={[
          {
            pattern: new RegExp(
              /^[-!#$%&'"*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&"'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+$/
            ),
            message: '正しいメールアドレスを入力してください。',
          },
          { required: true, whitespace: true, message: 'メールアドレスを入力してください。' },
        ]}
      >
        <Input type="email" placeholder="tatoeba@example.com" className="input input__field" maxLength={255} />
      </Form.Item>

      {/* 10 住所  */}
      <fieldset className="address">
        <div className="input input__title">
          <legend className="input__legend">住所</legend>
          {/* <span className="input input__tag">必要</span> */}
        </div>
        <small className="c-mt8">※「郵便番号」から住所が自動で入力されます。</small>

        <div className="address address__row">
          <Form.Item
            name="postalCode"
            className="input input__set input__set--no-mt"
            label={
              <label className="input input__label">
                郵便番号<i className="input input__tag">必須</i>
              </label>
            }
            rules={[
              { required: true, whitespace: true, message: '郵便番号を入力してください。' },
              { pattern: new RegExp('\\d{3}-?\\d{4}$'), message: '郵便番号を入力してください。' },
            ]}
          >
            <Input
              placeholder="例：123-4567"
              maxLength={8}
              className="input input__field input__field--medium"
              onBlur={changeAddress}
            />
          </Form.Item>
        </div>

        <div className="address address__row">
          <Form.Item
            name="addressLevel1"
            className="input input__set input__set--no-mt"
            label={<span className="input input__label">都道府県</span>}
          >
            <Input type="text" className="input input__field input__field--small" required maxLength={255} />
          </Form.Item>

          <Form.Item
            name="addressLevel2"
            className="input input__set input__set--no-mt input__set--stretch"
            label={<span className="input input__label">市区町村や郡</span>}
          >
            <Input type="text" className="input input input__field input__field--stretch" required maxLength={255} />
          </Form.Item>
        </div>

        <div className="address address__row">
          <Form.Item
            name="addressLine1"
            className="input input__set input__set--no-mt"
            label={<span className="input input__label">町名</span>}
          >
            <Input type="text" className="input input__field input__field--medium" required maxLength={255} />
          </Form.Item>

          <Form.Item
            name="addressLine2"
            className="input input__set input__set--no-mt input__set--stretch"
            label={
              <span className="input input__label">
                地番<i className="input input__tag">必須</i>
              </span>
            }
            rules={[{ required: true, whitespace: true, message: '地番を入力してください。' }]}
          >
            <Input className="input input input__field input__field--stretch" maxLength={255} />
          </Form.Item>
        </div>

        <div className="address address__row">
          <Form.Item
            name="addressLine3"
            className="input input__set input__set--no-mt input__set--stretch"
            label={<span className="input input__label">建物名</span>}
          >
            <Input className="input input__field input__field--stretch" maxLength={255} />
          </Form.Item>
        </div>
      </fieldset>

      {/* 11 職業  */}
      <Form.Item
        name="occupation"
        className="input input__set"
        label={
          <label className="input input__label">
            職業<i className="input input__tag">必須</i>
          </label>
        }
        // rules={[{ required: true, whitespace: true }]}
      >
        <select required className="input input__field" onChange={(e) => handleChangeOccupation(e)}>
          <option value="">選択してください</option>
          <option value="会社員">会社員</option>
          <option value="経営者・役員">経営者・役員</option>
          <option value="学生(高校生)">学生(高校生)</option>
          <option value="学生(専門・大学)">学生(専門・大学)</option>
          <option value="公務員">公務員</option>
          <option value="自営業">自営業</option>
          <option value="自由業(フリーランス含む)">自由業(フリーランス含む)</option>
          <option value="専業主婦">専業主婦</option>
          <option value="パート・アルバイト">パート・アルバイト</option>
          <option value="その他">その他</option>
        </select>
      </Form.Item>

      {occupation.includes('学生') && (
        <Form.Item
          name="handicap"
          className="input input__set"
          label={
            <label className="input input__label">
              ハンディキャップ<i className="input input__tag">必須</i>
            </label>
          }
          rules={[{ required: true, message: 'ハンディキャップを入力してください。' }]}
        >
          <Input
            type="number"
            step="0.1"
            min={0}
            max={99}
            placeholder="例：20.1"
            maxLength={255}
            className="input input__field"
          />
        </Form.Item>
      )}

      {isUpdate && (
        <Form.Item
          name="technicalTestArea"
          className="input input__set"
          label={
            <label className="input input__label">
              ツアーに出場する際の希望する地域<i className="input input__tag">必須</i>
            </label>
          }
        >
          <select name="technicalTestArea" id="area" required className="input input__field" disabled>
            <option value="">選択してください</option>
            <option value="0">関東</option>
            <option value="1">関西</option>
          </select>
        </Form.Item>
      )}
    </>
  );
};

export default InputUserForm;
