import React from 'react';
import ReactPaginate from 'react-paginate';

const LAST_DISPLAY_SIZE = 1;
const AROUND_DISPLAY_PAGES = 3;

const Pagination = (props) => {
  const { dataSize, onePageDisplay, handleSearch, setCurrentPageNumber } = props;

  const handlePaginate = (selectedItem) => {
    window.scrollTo(0, 0);
    const page = selectedItem.selected + 1;
    setCurrentPageNumber(page);
    // APIを叩きに行く処理
    handleSearch(page);
  };

  const arrowIconPrevious = () => {
    return (
      <>
        <figure className="c-icon c-icon--8 c-margin-reset">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 28">
            <defs />
            <path
              d="M14 28a1.994 1.994 0 01-1.414-.586l-12-12a2 2 0 010-2.828l12-12a2 2 0 012.828 2.828L4.828 14l10.586 10.586A2 2 0 0114 28z"
              data-name="Path 1754 - Outline"
            />
          </svg>
        </figure>
        <span className="c-pl--8">前の</span>
      </>
    );
  };

  const arrowIconNext = () => {
    return (
      <>
        <span className="c-pr--8">次へ</span>
        <figure className="c-icon c-icon--8 c-margin-reset">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 28">
            <defs />
            <path
              d="M2 0a1.994 1.994 0 011.414.586l12 12a2 2 0 010 2.828l-12 12a2 2 0 01-2.828-2.828L11.172 14 .586 3.414A2 2 0 012 0z"
              data-name="Path 1755 - Outline"
            />
          </svg>
        </figure>
      </>
    );
  };

  // ページ数の計算
  const calculatePageCount = () => {
    return Math.ceil(dataSize / onePageDisplay);
  };

  return (
    <>
      <ReactPaginate
        pageCount={calculatePageCount()}
        marginPagesDisplayed={LAST_DISPLAY_SIZE}
        pageRangeDisplayed={AROUND_DISPLAY_PAGES}
        onPageChange={handlePaginate}
        containerClassName="c-pagination__list"
        pageClassName="c-pagination__number"
        pageLinkClassName="c-pagination__button"
        activeClassName="c-pagination__number--current"
        previousClassName="c-pagination__previus"
        previousLinkClassName="c-pagination__button"
        nextClassName="c-pagination__next"
        nextLinkClassName="c-pagination__button"
        previousLabel={arrowIconPrevious()}
        nextLabel={arrowIconNext()}
        disabledClassName="disabled-button"
      />
    </>
  );
};

export default Pagination;
