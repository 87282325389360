import React from 'react';
// import { useHistory } from 'react-router-dom';

const RegisterCompletePage = () => {
  // const history = useHistory();

  const onClickNextButton = () => {
    // history.push('/main');
    window.location.href = '/main';
  };

  return (
    <main>
      <article className="screen screen--message">
        <div className="message">
          <div className="message message__logo">
            <img src="/images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
          </div>

          <div className="message__title">
            <h1>会員登録完了</h1>
          </div>

          <div className="message__icon animation--zoomIn">
            <img src="/images/okay.svg" alt="OK!" />
          </div>

          <div className="message__content">
            <p>会員登録が完了しました。</p>
          </div>

          <button onClick={onClickNextButton} className="button button__primary c-mb88">
            次へ
          </button>
        </div>
      </article>
    </main>
  );
};

export default RegisterCompletePage;
