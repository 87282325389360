import React from 'react';
import AppFooter from '../components/footer/AppFooter';

const MainEntryDeclineRequestPage = () => {
  // const history = useHistory();

  /**
   * メインページへが押された時
   */
  const onClickToMain = () => {
    // history.push('/main');
    window.location.href = '/main';
  };

  return (
    <body>
      <main>
        <article className="screen screen--message">
          <div className="message">
            <div className="message message__logo">
              <img src="/images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
            </div>

            <div className="message__title">
              <h1>ツアーエントリー辞退受付完了</h1>
            </div>

            <div className="message__icon animation--zoomIn">
              <img src="/images/okay.svg" alt="OKです" />
            </div>

            <div className="message__content">
              <p>エントリーの辞退を受け付けました。</p>
              <p>
                エントリー期間内ならいつでも
                <br />
                エントリー可能です。
              </p>
            </div>

            <button onClick={onClickToMain} className="button button__primary c-mt56 c-mb88">
              メイン画面に戻る
            </button>
          </div>
        </article>
      </main>

      <footer>
        <AppFooter />
      </footer>
    </body>
  );
};

export default MainEntryDeclineRequestPage;
