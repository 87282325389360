import React, { useState } from 'react';
import { useHistory } from 'react-router';
import AppFooter from '../components/footer/AppFooter';
import { cloudFunctionUpdateInfoUser } from '../firebase/FireBaseCloudFunction';
import LoadingScreen from '../components/LoadingScreen';
import { getAppUserModel } from '../firebase/FireBaseManager';
import { AppUserModel } from '../models/UserModel';
import { convertToInsertUserInfoData } from '../firebase/FireBaseDataConverter';

const MainEntrySelectionPage = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = getAppUserModel() || new AppUserModel();

  const [selection, setSelection] = useState('');
  // Value to check.
  const [value, setValue] = useState({
    technicalTestArea: null,
    acceptTerms: false,
    loading: false,
  });

  // const userInfo = getAppUserModel();
  const handleChangeSelection = (e) => setSelection(e.target.value);
  const handleChangeArea = (e) => setValue({ ...value, technicalTestArea: Number(e.target.value) });

  /**
   * 登録ボタン
   */
  const onClickRegister = () => {
    const updateData = { technicalTestArea: value.technicalTestArea };
    const userModel = convertToInsertUserInfoData(updateData, userInfo);
    cloudFunctionUpdateInfoUser(
      {
        /**
         * 処理開始時
         */
        onProcessStart: () => {
          console.log('MainEntrySelectionPage cloudFunctionUpdateInfoUser()');
          setIsLoading(true);
        },

        /**
         * 成功時
         */
        onSuccess: (_appUserModel) => {
          setIsLoading(false);
          history.push('/profile_register');
        },

        /**
         * 失敗時
         */
        onFailed: () => {
          setIsLoading(false);
        },
      },
      userModel
    );
  };

  if (isLoading) {
    return (
      <body>
        <LoadingScreen />;
      </body>
    );
  }

  return (
    <body>
      <main className="register entry-checkbox">
        <article className="screen screen--entry">
          <div className="register register__logo">
            <img src="/images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
          </div>

          <h1 className="register__title c-mt16">関⻄ブロックJCGプレシーズンツアー2021概要</h1>

          <div className="c-table c-table--984">
            <div className="c-table__row">
              <div className="c-table__title">エントリー代</div>
              <div className="c-table__content">
                <p>11,000円(税込)/日</p>
                <p className="c-mt8">&#8251;要別途当日プレー代。当日コースにて個人清算となります。</p>
              </div>
            </div>

            <div className="c-table__row">
              <div className="c-table__title">ジュニアエントリー代</div>
              <div className="c-table__content">
                <p>3,300円(税込)/日</p>
                <p className="c-mt8">&#8251;要別途当日プレー代。当日コースにて個人清算となります。</p>
              </div>
            </div>

            <div className="c-table__row">
              <div className="c-table__title">プレー代</div>
              <div className="c-table__content">
                <p>6,800円（デイリー信楽カントリー倶楽部）</p>
                <p>7,300円（アートレイクゴルフ倶楽部）</p>
                <p className="c-mt8">&#8251;当日コースにて個人清算となります</p>
              </div>
            </div>

            <div className="c-table__row">
              <div className="c-table__title">受付期間</div>
              <div className="c-table__content">
                <p>2021年8月27日〜2021年9月22日</p>
              </div>
            </div>

            <div className="c-table__row">
              <div className="c-table__title">ツアースケジュール</div>
              <div className="c-table__content">
                <ol className="c-table__list">
                  <li className="c-table__list-item">
                    <span className="c-heading--3">10月</span>
                    <ol className="c-table__list">
                      <li className="c-table__list-item">04日(月): JCGツアー男子第１戦 アース製薬カップ</li>
                      <li className="c-table__list-item">11日(月): JCGツアー男子第２戦 UST Mamiyaカップ</li>
                      <li className="c-table__list-item">18日(火): JCGツアー女子第１戦 アース製薬カップ</li>
                      <li className="c-table__list-item">
                        25日(月): JCGツアー男子第３戦 JCGマネージメントカップ（仮称）
                      </li>
                      <li className="c-table__list-item">26日(火): JCGツアー女子第２戦 アース製薬カップ</li>
                      <li className="c-table__list-item">29日(金): JCGツアー男子第４戦 KamSyaカップ</li>
                    </ol>
                  </li>

                  <li className="c-table__list-item c-mt16">
                    <span className="c-heading--3">11月</span>
                    <ol className="c-table__list">
                      <li className="c-table__list-item">
                        01日(月): JCGツアー女子第３戦 JCGマネージメントカップ（仮称）
                      </li>
                      <li className="c-table__list-item">
                        08日(月): JCGツアー男子第５戦 JCGマネージメントカップ（仮称）
                      </li>
                      <li className="c-table__list-item">
                        19日(金): JCGツアー男子第６戦 JCGマネージメントカップ（仮称）
                      </li>
                      <li className="c-table__list-item">24日(水): JCGツアー女子第４戦 KamShaカップ</li>
                      <li className="c-table__list-item">
                        29日(月): JCGツアー男子第７戦 JCGマネージメントカップ（仮称）
                      </li>
                      <li className="c-table__list-item">
                        30日(火): JCGツアー女子第５戦 JCGマネージメントカップ（仮称）
                      </li>
                    </ol>
                  </li>

                  <li className="c-table__list-item c-mt16">
                    <span className="c-heading--3">12月</span>
                    <ol className="c-table__list">
                      <li className="c-table__list-item">
                        06日(月): JCGツアー男子第８戦 JCGマネージメントカップ（仮称）
                      </li>
                      <li className="c-table__list-item">
                        08日(水): JCGツアー女子第６戦 JCGマネージメントカップ（仮称）
                      </li>
                      <li className="c-table__list-item">
                        10日(金): JCGツアー男子第９戦 JCGマネージメントカップ（仮称）
                      </li>
                      <li className="c-table__list-item">
                        13日(月): JCGツアー女子第７戦 JCGマネージメントカップ（仮称）
                      </li>
                    </ol>
                  </li>

                  <li className="c-table__list-item c-warning c-mt16">
                    開幕予定日と開催試合数に関しては蔓延防止等重点措置や緊急事態宣言の発令状況により変更致します。
                  </li>

                  <li className="c-table__list-item c-warning c-mt16">
                    ※エントリー締め切り日は各ツアー開始の1週間前までです。
                  </li>
                </ol>
              </div>
            </div>

            <div className="c-table__row">
              <div className="c-table__title">各大会定員</div>
              <div className="c-table__content">
                <p>60名</p>
              </div>
            </div>
          </div>
          {/*<!-- データテーブル終了。 -->*/}

          <div className="c-container-vertical c-prl--32">
            <h4 className="c-heading--2 c-mt16">参加資格</h4>

            <p className="c-paragraph c-mt8">(1)PGA会員LPGA会員及びゴルフ場に所属の練習生、研修生</p>
            <p className="c-paragraph">
              &#8251;レギュラーツアー、Abemaツアー、ステップアップツアー出場選手は対象外となります。
            </p>
            <p className="c-paragraph">(2)ジュニアゴルファー(18歳以下の高校生)</p>
            <p className="c-paragraph">
              &#8251;JCGでは学校教育を重要と考えており、ジュニアの方の平日参加については学校の許可を前提と考えています。
            </p>
            <p className="c-paragraph">(3)アマチュア(HD13以下の実力保持者)</p>

            <p className="c-paragraph c-mt16">&#9632;ツアー参加前に協会会員登録が必要となります。</p>
            <p className="c-paragraph c-mt16">
              &#8251;
              協会登録費用については2021年度はプレオープン期間と位置付けており、2021年度登録会員は無料となります。
            </p>
            <p className="c-paragraph c-mt8">
              &#8251;
              協会年会費については2021年度はプレオープン期間と位置付けており、2021年度年会費は無料となります。次年度
              以降、練習生・研修生は6,000円(税別)/年、アマチュア (学生含む)・ジュニアは3,000円(税別)となります。
            </p>
            <p className="c-paragraph c-mt8">
              &#8251;
              アマチュア選手が賞金の受領を希望する場合には、参加合格後、登録の際に、《ツアープレイヤー》として指定の書類を提出しアマチュア資格を放棄することで、賞金獲得が可能となります。
            </p>

            <h4 className="c-heading--2 c-mt16">競技方法</h4>
            <p className="c-paragraph c-mt8">1Day、18ストロークプレー</p>
            <p className="c-paragraph c-mt8">同成績の場合順位は全て18番ホールからのカウントバック方式で決定</p>

            <h4 className="c-heading--2 c-mt16">競技規則</h4>
            <p className="c-paragraph c-mt8">日本ゴルフ協会競技規則、本大会の条件を適用</p>

            <h4 className="c-heading--2 c-mt16">賞金</h4>
            <p className="c-paragraph c-mt8">賞金総額100万円</p>
            <p className="c-paragraph c-mt8">優勝:18万円</p>
            <p className="c-paragraph c-mt8">副賞：銀閣寺大⻄のサーロインステーキセット</p>
            <p className="c-paragraph c-mt8">2位:10万円</p>
            <p className="c-paragraph c-mt8">3位:5万円</p>
            <p className="c-paragraph c-mt8">4位:4万円</p>
            <p className="c-paragraph c-mt8">5位:3万円</p>
            <p className="c-paragraph c-mt8">6位〜10位:2万円</p>
            <p className="c-paragraph c-mt8">11位〜60位:1万円</p>
            <p className="c-paragraph c-mt8">&#8251;参加者全員に金繭から作られたUVカットスプレープレゼント</p>
            <p className="c-paragraph c-mt8">
              &#8251;お振り込み額は賞金総額から振り込み手数料+源泉徴収+復興特別税を控除した金額となります。
            </p>
            <p className="c-paragraph c-mt8">&#8251;アマチュアが入賞した場合には賞品(アマチュア規定による)</p>

            <h4 className="c-heading--2 c-mt16">ドレスコード</h4>
            <p className="c-paragraph c-mt8">会場コースの規定のドレスコードに準じます。</p>
            <p className="c-paragraph c-mt8">ゴルフ場へお越しの際は、マスクの着用をお願いします。</p>

            <h4 className="c-heading--2 c-mt16">受付</h4>
            <p className="c-paragraph c-mt8">クラブハウスオープン時刻から</p>
            <p className="c-paragraph c-mt8">大会受付: 競技トップスタートの60分前</p>

            <h4 className="c-heading--2 c-mt16">キャンセル及びキャンセル料について</h4>
            <p className="c-paragraph c-mt8">
              &#8251;キャンセル受付はしておりません。やむを得ない事情によりキャンセルなさる場合は、運営事務局へ直接ご連絡ください。
            </p>

            <h4 className="c-heading--2 c-mt16">その他注意点</h4>
            <p className="c-paragraph c-mt8">
              &#8251;遅刻は失格となります。渋滞や交通機関の乱れによる遅刻も失格となりますので余裕を持って会場へお越しください。
            </p>
            <p className="c-paragraph c-mt8">
              &#8251;反社会勢力組織に所属する方、またはそれに準ずると認められる方、刺⻘・タトゥーを入れている方は、いかなる場合も参加を認めません。
            </p>
            <p className="c-paragraph c-mt8">
              &#8251;JCGツアーでは、大会を盛り上げ魅力ある競技を行う為に、YouTubeによるLive配信、写真撮影など、各選手にご協力をお願い致します。
            </p>
            <p className="c-paragraph c-mt8">
              &#8251;ゴルフは、紳士淑女のスポーツです。著しくマナーの悪い方や、秩序を乱す方は、大会の参加をお断りする場合がございます。
            </p>

            <h4 className="c-heading--2 c-mt16">JCG教育パートナー企業・団体</h4>
            <p className="c-paragraph c-mt8">株式会社ECC</p>
            <p className="c-paragraph c-mt8">特定非営利活動法人ファースト・ティ・ジャパン</p>
            <p className="c-paragraph c-mt8">スポーツキャリアサポートコンソーシアム</p>
            <p className="c-paragraph c-mt16">
              &#8251;JCGでは会員への教育啓蒙活動にも力を入れており、大会参加者にはウェブ講座にて30分程の講習を受講いただくようお願いをしています(無料)
            </p>

            {/*<!-- <form action="" method="" id=""> ブラウザーでボタン動くために<form>タグをコメントアウトします。 -->*/}

            {/*<!-- 10 実技テストエントリー -->*/}
            <div id="entry" className="form input input__set input__set--no-mt">
              <div className="entry-checkbox__selection c-mt32 c-mb88">
                <input
                  type="radio"
                  name="entry-participation"
                  id="entry-participant"
                  checked={selection === 'entry-participant'}
                  onChange={handleChangeSelection}
                  value={'entry-participant'}
                />
                <input
                  type="radio"
                  name="entry-participation"
                  id="entry-non-participant"
                  checked={selection === 'entry-non-participant'}
                  onChange={handleChangeSelection}
                  value={'entry-non-participant'}
                />

                <label htmlFor="entry-participant" className="button button__secondary entry-participant">
                  参加表明する
                </label>

                <label htmlFor="entry-non-participant" className="button button__secondary entry-non-participant">
                  参加表明しない
                </label>

                <div className="entry-participation-content">
                  <div className="c-radio-button-set c-mt32">
                    <span className="c-radio-button-set__label c-radio-button-set__label--center c-heading--3 c-mb16">
                      ツアーに出場する際の希望する地域
                    </span>

                    <input
                      checked={value?.technicalTestArea === 0}
                      value={'0'}
                      onChange={handleChangeArea}
                      type="radio"
                      id="kanto-area"
                      name="radio-button-set"
                      className="button button__secondary"
                    />
                    <input
                      className="button button__secondary"
                      checked={value?.technicalTestArea === 1}
                      value={'1'}
                      onChange={handleChangeArea}
                      type="radio"
                      id="kansai-area"
                      name="radio-button-set"
                    />

                    <label className="button button__secondary entry-participant kanto-area" htmlFor="kanto-area">
                      関東
                    </label>
                    <label className="button button__secondary entry-non-participant kansai-area" htmlFor="kansai-area">
                      関西
                    </label>

                    <span className="c-radio-button-set__label c-radio-button-set__label--center c-warning">
                      &#8251;人数の都合上ご希望に添えない場合があります。
                    </span>

                    <div className="kanto-announce">
                      <p className="c-heading--3">
                        現在<i className="c-heading--highlight">関東</i>ブロックの募集は行っておりません。
                      </p>
                      <p className="c-heading--3 c-mt8">
                        関東ブロックの開幕日は後日告知致しますので、しばらくお待ちください。
                      </p>
                    </div>
                  </div>

                  <fieldset id="entry-terms" className="entry-terms c-mt32">
                    <div className="entry-terms__content">
                      <h1>競技の条件</h1>

                      <h2>ゴルフ規則</h2>
                      <p>日本ゴルフ協会(JGA)ゴルフ規則及び本大会ローカルルールを適用する。</p>

                      <h2>競技委員会の裁定</h2>
                      <p>
                        競技委員会は競技の条件を修正する権限を有し、すべての事柄について、この委員会の裁定は最終である。
                      </p>

                      <h2>使用球の規格</h2>
                      <p>
                        競技者の使用球はR&A発行の公認球リスト(JGAホームページ:<a href="www.jga.or.jp">www.jga.or.jp</a>{' '}
                        は、競技失格。
                      </p>
                      <p>&#8251;本競技ではワンボール条件は適用しないものとする。</p>

                      <h2>使用クラブの規格</h2>
                      <p>
                        競技者のドライバーはR&Aが発行する最新の適合ドライバーヘッドリストに掲載されているクラブヘッドをもつものでなければならない。
                      </p>
                      <p>この条件の違反の罰は、競技失格。</p>

                      <h2>ホールとホールの間での練習禁止</h2>
                      <p>
                        競技者はプレーを終えたばかりのホールのグリーン上やその近くで練習ストロークをしてはならない。
                      </p>
                      <p>
                        これに違反して練習ストロークした場合、競技者は次のホールで2打の罰を受ける。ただし、ラウンドの最終ホールでのときは、そのホールで罰を受ける。
                      </p>
                      <p>&#8251;練習グリーンでのパッティング練習を除く。</p>

                      <h2>移動</h2>
                      <p>競技者は、常に歩いてラウンドしなければならない。&#8251;会場毎同一条件とする。</p>

                      <h2>険悪な気象条件のため競技が中止になった場合</h2>

                      <ul>
                        <li>
                          <p>競技中止の時点で9ホールを消化していれば競技成立、消化していなければ不成立とする。</p>
                        </li>
                        <li>
                          <p>
                            なお、9ホールでの競技成立の場合は、アウトスタート・インスタート各々の最少スコアチームを上位とする。
                          </p>
                          <p>
                            タイスコアが発生した場合は、アウトスタートは9番、インスタートは18番からのカウントバックにより上位を決定する。
                          </p>
                        </li>
                      </ul>

                      <h2>タイスコアの場合</h2>
                      <p>スコアがタイとなった場合、カウント・バック方式により上位を決定する。</p>

                      <h2>競技終了時点と裁定</h2>
                      <p>本大会は、成績発表がなされた時点で終了となり、その後の変更はしない。</p>
                      <p>ルール処置・その他疑問がある場合は、アテスト終了までに申告すること。</p>

                      <h2>チェックイン</h2>
                      <p>
                        大会当日における朝のチェックインについて、発表された各自のスタート時間の30分前までに大会受付を終えなければならない。
                      </p>
                      <p>連絡もなく、30分前までに受付しなければ失格とする。</p>

                      <h1>競技方法</h1>
                      <p>18ホールズフォアボールストロークプレーによって行う。</p>
                      <p>
                        ティショットはスタートホールにおいてはドロー表の順番とする。次のホール以降はスコアの良い選手がオナー権を持つことができる。
                      </p>
                      <p>
                        略上、必ずしも遠いボールからパッティングしなくてもよい(例:カップに近いパーパットと遠いバーディパットが残った状況で、パーパットを先に決めて
                        から、バーディパットを打ってもかまわない)。
                      </p>
                      <p>
                        マーカーとはあくまでも委員会(JCGマネージメント社)がスコアを記録するために指名した人のことで、規則を判断・裁定する権限はありません。
                      </p>

                      <h1>大会ローカルルール</h1>
                      <p>アウト・オブ・バウンズの境界は白杭をもって標示する。</p>
                      <p>修理地は青杭又は、白線をもってその限界を標示する。</p>
                      <p>
                        イエローペナルティーエリアは大会ローカルルールで指定する場合を除き、黄杭又は黄線、レッドペナルティーエリアは赤杭又は赤線、無い場合は水際又はコ
                        ンクリート(木)壁をもってその境界を標示する。
                      </p>
                      <p>排水溝は動かせない障害物とし、人工の表面をもつ道路に接した排水溝は、その道路とみなす。</p>
                      <p>
                        樹木保護のための巻き網は樹木の一部とみなすが、球がその巻き網に挟まって止まった場合は、罰なしにその直下の地点から1クラブレングス以内で、且つホールに近づかない個所に球をドロップすることができる。取り出した球は、拭くことができる。このローカルルールの違反の罰は、2打。
                      </p>
                      <p>
                        電磁誘導カート用の2本のコンクリート軌道は、全幅をもってカート道路とみなす。球がこのカート道路の上にある場合、競技者は救済を受けなければならない。
                      </p>
                      <p>このローカルルールの違反の罰は、2打。</p>
                      <p>
                        使用禁止の表示のある予備グリーン(カラーを含む)はプレー禁止の修理地(ジェネラルエリア)とし、その上に球があったりスタンスがかかる場合、競技者は救済を受けなければならない。このローカルルールの違反の罰は、2打。
                      </p>
                      <p>
                        プレーヤーの球が張芝の継ぎ目の中にあるか、触れている場合、あるいは継ぎ目がプレーヤーの意図するスイング区域の障害となっている場合:」
                      </p>
                      <p>(a) ジェネラルエリアの球。そのプレーヤーは規則 16.1b に基づいて救済を 受けることができる。</p>
                      <p>
                        (b) パッティンググリーン上の球。そのプレーヤーは規則 16.1d に基づいて 救済を受けることができる。
                      </p>
                      <p>
                        雨によるバンカー内の流水路跡に入った球は、罰なしに拾い上げ、ホールに近づかず、球の止まっていた箇所にできるだけ近いところにドロップすることがで
                        きる。
                      </p>
                      <p>〈拾い上げた球は拭くことができる〉</p>
                      <p>
                        グリーン上に球がある時はパター以外のクラブの使用を禁ずる。但しパターが破損し使用不可能な時はその限りではない。
                      </p>

                      <h1>ルールの確認</h1>
                      <ul>
                        <li>
                          <p>
                            ジェネラルエリアのどこでも、自己の球がその勢いで自ら地面に作った穴(ピッチマーク)にくいこんでいるときは、その球は罰なしに拾いあげて、ホー
                            ルに近づかず、しかも球の止まっていた地点にできるだけ近い個所にドロップすることができる。取り出した球は、拭くことができる。
                          </p>
                        </li>
                        <li>
                          <p>
                            レーザー・GPS等を用いた距離のみを測定する機器を使用する事ができる。
                            距離計測機がプレーヤーのプレーに影響を及ぼす可能性のあるその他
                            の条件(標高変化、風速など)の計測機能は使用できない。
                          </p>
                        </li>
                        <li>
                          <p>
                            プレーヤーの球がパッティンググリーン上にある場合、その球やボールマーカーがプレーヤーやパートナー、キャディーまたはそのいずれかの携帯品
                            によって偶然に動かされても罰はない。
                          </p>
                          <p>
                            注意:パッティンググリーン上のプレーヤーの球が風、水あるいは重力などの他の自然現象の結果として動か
                            されたものと判断された場合、その球はその新しい位置からあるがままの状態でプレーされなければならない。
                          </p>
                        </li>
                        <li>
                          <p>バンカー内にあるルースインペディメント(葉、石などの自然物)を取り除ける</p>
                        </li>
                        <li>
                          <p>ライン上にあるスパイクマークの修理をすることができる。</p>
                        </li>
                      </ul>

                      <h1>注意事項</h1>

                      <ul>
                        <li>
                          <p>
                            ゴルフ場ごとに競技の条件やローカルルールに追加・変更がある場合は、当日クラブハウス内の大会公式掲示板に掲示して告示するので、各自ス
                            タート前に必ず確認しなければならない。
                          </p>
                        </li>
                        <li>
                          <p>
                            大会受付のチェックイン登録は、遅くともスタート50分前には済ませ、スタート時間の10分前には必ずティインググラウンド側にて待機すること。やむを
                            えずスタート時刻に遅れそうなときは、各々のスタート時間の40分前までに必ず、事務局携帯電話、またはゴルフ場まで連絡を入れること。
                          </p>
                        </li>
                        <li>
                          <p>スタート前の練習は練習場において備付けの球を使用し、1人1箱とする。</p>
                        </li>
                        <li>
                          <p>グリーンに著しく損傷を与えるシューズは使用禁止とすることがある。</p>
                        </li>
                        <li>
                          <p>特設ティ等は、ローカルルールで指定された場合を除き、使用しないものとする。</p>
                        </li>
                        <li>
                          <p>
                            ドローは諸般の事情により当日変更もありうる。なお、ペアリング(ドロー)は事務局が決定する。
                          </p>
                        </li>
                        <li>
                          <p>
                            プレー中はマナーを遵守するとともに、スロープレーは厳禁とする。前組との間隔を1ホール以上空けることのないよう注意すること。ハーフラウンドを
                            原則2時間15分とし、2時間45分を超えた場合にはペナルティを課すことがある。
                          </p>
                        </li>
                        <li>
                          <p>OBまたはロストボールの可能性がある場合には、必ず暫定球の処置を行うこと。</p>
                        </li>
                        <li>
                          <p>近年主流の素振り棒・スタンス棒などは異常な用具に該当するので、使用できない。</p>
                        </li>
                        <li>
                          <p>ギャラリー(応援者)はコース内に立ち入ることができない。</p>
                        </li>
                        <li>
                          <p>
                            エントリーフィー入金後はいかなる理由でも返金できない。既に申込みの会場が締切り後の場合は、会場変更はできない。
                          </p>
                        </li>
                        <li>
                          <p>最終的な参加会場等の決定は事務局の判断となる。</p>
                        </li>
                        <li>
                          <p>18歳以下のジュニアゴルファー(高校生以下)は親の承諾を得てから、申し込むこととする。</p>
                        </li>
                        <li>
                          <p>
                            各決勝大会において、予選通過者が出場を辞退した場合、およびエントリー締切りまでにエントリーされなかった場合、出場を辞退したものと見なし、
                            次チーム(該当地区およびスコアを考慮)から順次繰上げとする。
                          </p>
                        </li>
                        <li>
                          <p>
                            主催者の責に帰すべき事由によるもの以外に、大会中に発生した傷害・事故等については
                            一切責任を負わない。
                          </p>
                        </li>
                        <li>
                          <p>
                            本競技の品位を損なう行為があった場合は、プレー中であっても即刻、競技への参加を取り消すこともある。
                          </p>
                        </li>
                      </ul>

                      <h1>その他</h1>

                      <ul>
                        <li>
                          <p>最終結果は競技終了後、大会公式掲示板・大会公式アプリに掲示する。</p>
                        </li>
                        <li>
                          <p>変更・追加が出た場合は、大会公式掲示板・大会公式アプリに告示する。</p>
                        </li>
                      </ul>
                    </div>

                    <div className="input input__set input__set--checkbox">
                      <label htmlFor="accept-entry-terms" className="input input__label input__label--big">
                        規約に同意する<i className="input input__tag">必須</i>
                      </label>
                      <input
                        type="checkbox"
                        name="accept-entry-terms"
                        id="accept-entry-terms"
                        className="input input__checkbox"
                        onChange={(e) => setValue({ ...value, acceptTerms: e.target.checked })}
                      />
                    </div>

                    <button
                      type="submit"
                      id="send-form-and-payment"
                      className="button button__primary c-mt32"
                      name="send-form-and-payment"
                      disabled={!value.acceptTerms || value.technicalTestArea === '' || value.loading}
                      onClick={onClickRegister}
                    >
                      選手登録をして参加表明する
                    </button>
                  </fieldset>
                </div>

                <fieldset id="entry-notice" className="entry-notice c-mt32">
                  <p className="c-paragraph c-paragraph--extra">
                    関⻄ブロックJCGプレシーズンツアー2021への参加表明は、募集期間内ならいつでも可能です。参加希望の方は関⻄ブロックJCGプレシーズンツアー2021の項目から参加表明ください。
                  </p>

                  <button
                    className="button button__primary c-mb56"
                    onClick={() => {
                      setSelection('');
                      history.push('/main');
                    }}
                  >
                    メインページへ戻る
                  </button>
                </fieldset>
              </div>
            </div>

            {/*<!--</form> ブラウザーでボタン動くために<form>タグをコメントアウトします。 -->*/}
          </div>
        </article>
      </main>

      <footer>
        <AppFooter />
      </footer>
    </body>
  );
};

export default MainEntrySelectionPage;
