import React, { useEffect, useState } from 'react';
import MainBeforeParticipatePage from './main/MainBeforeParticipatePage';
import UserStatusHelper from '../helpers/UserStatusHelper';
import { UserServiceApprovalStatus } from '../const/UserConst';
import LoadingScreen from '../components/LoadingScreen';
import MainTourPage from './main/MainTourPage';
import MainKantoPage from './main/MainKantoPage';
import {
  getStartedEntryTournamentData,
  tourGetParticipationTournamentService,
  tourGetTournamentEntryFlgService,
} from '../services/TournamentService';

// const
// メインページのID
const mainPages = {
  BEFORE_PARTICIPANT: 0, // ツアー参加表明前
  TOUR: 1, // ツアー参加表明後
  DISP_KANTO: 2, // 関東
};

/**
 * MainPage
 * @returns {JSX.Element}
 * @constructor
 */
const MainPage = () => {
  // loading
  const [isLoadingUserStatus, setIsLoadingUserStatusUserStatus] = useState(true);
  const [isLoadingTournamentData, setIsLoadingTournamentData] = useState(true);

  const [userApprovalStatus, setUserApprovalStatus] = useState(UserServiceApprovalStatus.NOT_ENTRY);
  const [tournamentData, setTournamentData] = useState(getStartedEntryTournamentData());

  /**
   * 初回表示時の処理
   */
  useEffect(() => {
    const _syncFunction = async () => {
      // ツアーデータ取得
      await fetchUserTournamentStatuses();

      // ユーザの状態を更新
      //NOTE: ツアーデータがないと正しく評価できないのでこちらが後。
      fetchUserServiceApprovalStatus();
    };

    _syncFunction().then();
  }, []);

  /**
   * ツアーの参加状況を取得してstateを更新する
   * @returns {Promise<void>}
   */
  const fetchUserTournamentStatuses = async () => {
    try {
      // ツアー情報取得
      const tourData = await tourGetParticipationTournamentService();
      const promiseArr = [];

      tourData.forEach((_item) => {
        // ツアー毎に参加フラグを取得
        promiseArr.push(tourGetTournamentEntryFlgService(_item.id));
      });

      await Promise.all(promiseArr).then(() => {
        // 全ての処理が終わったらstate更新
        setTournamentData(getStartedEntryTournamentData());
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingTournamentData(false);
    }
  };

  /**
   * ユーザの状態を取得してstateを更新する
   * @returns {void}
   */
  const fetchUserServiceApprovalStatus = () => {
    try {
      // ユーザのサービスの承認状態を取得
      const approvalStatus = UserStatusHelper.getUserServiceApprovalStatus();
      setUserApprovalStatus(approvalStatus);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingUserStatusUserStatus(false);
    }
  };

  /**
   * どのmainページを表示するか判定する
   * @returns {number}
   */
  const handleViewMainPage = () => {
    // 未エントリー、実技テスト中、非承認の場合
    if (
      [
        UserServiceApprovalStatus.NOT_ENTRY,
        UserServiceApprovalStatus.PRACTICAL_TEST,
        UserServiceApprovalStatus.DISAPPROVAL,
      ].includes(userApprovalStatus)
    ) {
      // 関東を表示
      return mainPages.DISP_KANTO;
    }

    // エントリー開始後のツアーがない場合
    if (tournamentData.length === 0) return mainPages.BEFORE_PARTICIPANT;

    return mainPages.TOUR;
  };

  // ローディング中の場合
  if (isLoadingUserStatus || isLoadingTournamentData) {
    return <LoadingScreen />;
  }

  // mainページ表示振り分け
  switch (handleViewMainPage()) {
    case mainPages.BEFORE_PARTICIPANT:
      return <MainBeforeParticipatePage />;
    case mainPages.TOUR:
      return <MainTourPage tournamentData={tournamentData} />;
    case mainPages.DISP_KANTO:
      return <MainKantoPage />;
  }
};

export default MainPage;
