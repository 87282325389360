import React from 'react';
import CopyRightFooter from '../components/footer/CopyRightFooter';
import MainFooter from '../components/footer/MainFooter';

export default function MainEntryPaymentSuccessedPage() {
  /**
   * メインページへが押された時
   */
  const onClickToMain = () => {
    // history.push('/main');
    window.location.href = '/main';
  };

  return (
    <body>
      <main>
        <article className="screen screen--message">
          <div className="message">
            <div className="message message__logo">
              <img src="images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
            </div>

            <div className="message__title">
              <h1>ツアーエントリー・決済完了</h1>
            </div>

            <div className="message__icon animation--zoomIn">
              <img src="images/okay.svg" alt="OK!" />
            </div>

            <div className="message__content">
              <p>ツアー開始までしばらくお待ちください。</p>
            </div>

            <button className="button button__primary c-mt56 c-mb88" onClick={onClickToMain}>
              メインページへ
            </button>
          </div>
        </article>
      </main>

      <footer>
        <CopyRightFooter />
        <MainFooter />
      </footer>

      <script></script>
    </body>
  );
}
