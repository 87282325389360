import React, { useState } from 'react';
import AppFooter from '../components/footer/AppFooter';
import { scrollTop } from '../helpers/common';
import InputUserForm from '../components/form/user_info/InputUserForm';
import { Button, Form } from 'antd';
import ConfirmUserForm from '../components/form/user_info/ConfirmUserForm';
import LoadingScreen from '../components/LoadingScreen';
import { useHistory } from 'react-router-dom';
import { getAppUserModel } from '../firebase/FireBaseManager';
import { convertToInsertUserInfoData } from '../firebase/FireBaseDataConverter';
import { cloudFunctionUpdateInfoUser } from '../firebase/FireBaseCloudFunction';
import { AppUserModel } from '../models/UserModel';

const MemberInfoEditPage = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  // const userStatusHook = useUserStatus();
  const [isLoading, setIsLoading] = useState(false);

  const [isConfirm, setIsConfirm] = useState(false);
  const [inputCurrentData, setInputCurrentData] = useState(null);
  // const firebaseUserModel = getFirebaseUserModel();
  const userInfo = getAppUserModel() || new AppUserModel();
  const [inputCurrentModel, setInputCurrentModel] = useState(userInfo);
  const isUpdate = true;

  /**
   * 確認ボタンが押された時
   * @param values
   */
  const handleConfirmRegister = (values) => {
    setInputCurrentData(values);
    setIsConfirm(true);
    const userModel = convertToInsertUserInfoData(values);
    userModel.order_id = userInfo.order_id;
    setInputCurrentModel(userModel);
    scrollTop();
  };

  /**
   * 登録がボタンが押されたとき
   */
  const onClickSubmitButton = () => {
    const userModel = convertToInsertUserInfoData(inputCurrentData, userInfo);

    // ユーザ情報更新
    cloudFunctionUpdateInfoUser(
      {
        /**
         * 処理開始時
         */
        onProcessStart: () => {
          console.log('MemberInfoEditPage cloudFunctionUpdateInfoUser()');
          setIsLoading(true);
        },

        /**
         * 成功時
         */
        onSuccess: (_appUserModel) => {
          setIsLoading(false);
          // moveWindowByUserStatus(history, userStatusHook());
          history.push('/member_info_edit_complete');
        },

        /**
         * 失敗時
         */
        onFailed: () => {
          setIsLoading(false);
        },
      },
      userModel
    );
  };

  if (isLoading) {
    return (
      <body>
        <LoadingScreen />;
      </body>
    );
  }
  return (
    <body id="all-body">
      <main className="register">
        <article className="screen screen--register">
          <div className="register register__logo">
            <img src="/images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
          </div>

          <h1 className="register register__title c-mt32">会員情報変更</h1>

          <Form
            form={form}
            onFinish={handleConfirmRegister}
            scrollToFirstError
            initialValues={{
              givenName: userInfo.given_name_kanji,
              familyNameKanji: userInfo.family_name_kanji,
              givenNameKana: userInfo.given_name_kana,
              familyNameKana: userInfo.family_name_kana,
              birthday: userInfo.birthday,
              sex: userInfo.sex,
              phoneNumber: userInfo.phone_number,
              email: userInfo.email,
              postalCode: userInfo.postal_code,
              addressLevel1: userInfo.address_level1,
              addressLevel2: userInfo.address_level2,
              addressLine1: userInfo.address_line1,
              addressLine2: userInfo.address_line2,
              addressLine3: userInfo.address_line3,
              occupation: userInfo.occupation,
              handicap: userInfo.handicap,

              // NOTE: 2021-08-18 大会が関西しかないので一旦固定にする
              technicalTestArea:
                userInfo.technical_test_area != null && userInfo.technical_test_area !== ''
                  ? userInfo.technical_test_area
                  : 0,
            }}
            className="h-adr form"
            id="user-input-form"
          >
            {/* 入力画面 */}
            {!isConfirm && (
              <>
                <InputUserForm isUpdate={isUpdate} currentUser={inputCurrentModel} form={form} />

                <div className={`text-center`} style={{ textAlign: 'center' }}>
                  <Button htmlType="submit" id="send-form" className="button button__primary c-mt56 c-mb88">
                    次へ
                  </Button>
                </div>
              </>
            )}

            {/* 確認画面 */}
            {isConfirm && (
              <>
                <div className={`${!isConfirm ? 'hidden' : ''}`}>
                  <ConfirmUserForm dataUser={inputCurrentData} isUpdate={isUpdate} />
                </div>

                <div className="c-button-set c-mt56">
                  <Button id="back" className="button button__secondary" onClick={() => setIsConfirm(false)}>
                    戻る
                  </Button>
                  <Button id="send-form" className="button button__primary" onClick={onClickSubmitButton}>
                    登録する
                  </Button>
                </div>
              </>
            )}
          </Form>
        </article>
      </main>

      <footer>
        <AppFooter />
      </footer>
    </body>
  );
};

export default MemberInfoEditPage;
