import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getIsAppPlayerModel, getNoticeCollection } from '../../firebase/FireBaseManager';

const DesktopMenuHeader = () => {
  const history = useHistory();
  const [unreadNoticeSize, setUnreadNoticeSize] = useState(0);

  /**
   * ホームボタンが押された時
   */
  const onClickHomeButton = () => {
    // e.preventDefault();
    history.push('/main');
  };

  /**
   * 会員情報ボタンが押された時
   */
  const onClickMemberInfoButton = () => {
    // e.preventDefault();
    history.push('/member_info');
  };

  /**
   * 選手情報ボタンが押された時
   */
  const onClickPlayerInfoButton = () => {
    history.push('/profile_view');
  };

  /**
   * お知らせボタンが押された時
   */
  const onClickNoticeButton = () => {
    history.push('/notice/inbox');
  };

  const isAppPlayerModel = getIsAppPlayerModel();

  useEffect(() => {
    const collection = getNoticeCollection();
    setUnreadNoticeSize(collection.countUnread());
  }, []);

  return (
    <nav className="main-desktop-menu">
      <ul className="main-desktop-menu__list">
        <li className="main-desktop-menu__item">
          <button className="main-desktop-menu__button" onClick={onClickHomeButton}>
            ホーム
          </button>
        </li>

        <li className="main-desktop-menu__item">
          <button className="main-desktop-menu__button" onClick={onClickNoticeButton}>
            お知らせ
          </button>
          {unreadNoticeSize > 0 && (
            <span className="main-desktop-menu__counter animation--jelly">{unreadNoticeSize}</span>
          )}
        </li>

        <li className="main-desktop-menu__item">
          <button className="main-desktop-menu__button" onClick={onClickMemberInfoButton}>
            会員情報
          </button>
        </li>

        {isAppPlayerModel && (
          <li className="main-desktop-menu__item">
            <button className="main-desktop-menu__button" onClick={onClickPlayerInfoButton}>
              選手情報
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default DesktopMenuHeader;
