import React from 'react';
import { useHistory } from 'react-router-dom';
import AppFooterHome from '../components/footer/AppFooterHome';

const IndexPage = () => {
  const history = useHistory();

  /**
   * 初めての方はこちらがクリックされた時
   */
  const onClickWelcomeButton = () => {
    history.push('/welcome');
  };

  /**
   * ログインがクリックされた時
   */
  const onClickLoginButton = () => {
    history.push('/login');
  };

  return (
    <body className="welcome">
      <main>
        <article className="screen screen--welcome">
          <div className="welcome__logo">
            <img src="images/logo.svg" width="232" height="76" alt="JCG Management" />
          </div>

          <p className="welcome__introduction">
            ツアープロへの夢を繋ぎ業界の
            <br />
            新たな基盤になる事、参加選⼿⼀⼈ひとりが多様な未来を描ける様に
            <br />
            なれる、そんなゴルフツアーを
            <br />
            ⽬指しています
          </p>

          <div className="welcome welcome__buttons">
            <button onClick={onClickWelcomeButton} className="button button__secondary button__secondary--dark">
              初めての方はこちら
            </button>
            <button onClick={onClickLoginButton} className="button button__primary button__primary--dark">
              ログイン
            </button>
          </div>
        </article>
      </main>
      <AppFooterHome />
    </body>
  );
};

export default IndexPage;
