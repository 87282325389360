import React, { useEffect, useState } from 'react';
import AppFooter from '../components/footer/AppFooter';
import { getAppPlayerModel, getAppUserModel } from '../firebase/FireBaseManager';
import { AppPlayerModel, AppUserModel } from '../models/UserModel';
import { Button, Form } from 'antd';
import InputPlayerForm from '../components/form/player/InputPlayerForm';
import { convertToInsertPlayerInfoData } from '../firebase/FireBaseDataConverter';
import { scrollTop } from '../helpers/common';
import ConfirmPlayerForm from '../components/form/player/ConfirmPlayerForm';
import {
  cloudFunctionUpdateInfoPlayer,
  isRegisterPlayer,
  cloudFunctionSetPassFlag,
} from '../firebase/FireBaseCloudFunction';
import { useHistory } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import { getFirebaseUserModel } from '../firebase/FireBaseManager';
import { uploadImageAsPromise } from '../firebase/FirebaseStorage';

const ProfileRegisterPage = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);

  const userInfo = getAppUserModel() || new AppUserModel();
  const playerInfo = getAppPlayerModel() || new AppPlayerModel();
  const loginUser = getFirebaseUserModel();
  const uid = loginUser?.uid;

  const [isConfirm, setIsConfirm] = useState(false);
  const [inputCurrentData, setInputCurrentData] = useState(null);
  const [inputCurrentModel, setInputCurrentModel] = useState(playerInfo);
  const isUpdate = false;

  useEffect(() => {
    scrollTop();
  });

  /**
   * 確認ボタンが押された時
   * @param values
   */
  const handleConfirmRegister = (values) => {
    setInputCurrentData(values);
    setIsConfirm(true);
    const playerModel = convertToInsertPlayerInfoData(values);
    setInputCurrentModel(playerModel);
    scrollTop();
  };

  /**
   * 登録がボタンが押されたとき
   */
  const onClickSubmitButton = async () => {
    const isRegister = await isRegisterPlayer();
    if (isRegister === -1) {
      alert('エラーが発生しました。再度やり直すかサイト管理者にお問い合わせください。');
      return;
    }
    if (isRegister === 0) {
      alert('大変申し訳ありませんが、参加表明が上限に達しました。次回開催の際にお申し込みください。');
      return;
    }

    if (imageFile) {
      const image_url = await uploadImageAsPromise(imageFile, `public/player_images/${uid}`, '1');
      inputCurrentData['imageUrl'] = image_url;
    }
    const playerModel = convertToInsertPlayerInfoData(inputCurrentData);

    // ユーザ情報更新
    cloudFunctionUpdateInfoPlayer(
      {
        /**
         * 処理開始時
         */
        onProcessStart: () => {
          console.log('ProfileRegisterPage cloudFunctionUpdateInfoPlayer()');
          setIsLoading(true);
        },

        /**
         * 成功時
         * @param _appPlayerModel
         */
        onSuccess: async (_appPlayerModel) => {
          await cloudFunctionSetPassFlag(_appPlayerModel);
          setIsLoading(false);
          // moveWindowByUserStatus(history, userStatusHook());
          history.push('/main_entry_payment_accepted');
        },

        /**
         * 失敗時
         */
        onFailed: () => {
          setIsLoading(false);
        },
      },
      playerModel
    );
  };

  if (isLoading) {
    return (
      <body>
        <LoadingScreen />;
      </body>
    );
  }

  return (
    <body className="c-screen">
      <header className="c-container-vertical c-mt16">
        <div className="c-logo c-logo--144">
          <img src="/images/logo--dark.svg" alt="JCG Management" className="c-logo__source" />
        </div>
        {!isConfirm && (
          <>
            <h1 className="c-heading--1 c-mt32">選手情報登録</h1>
          </>
        )}
        {isConfirm && (
          <>
            <h1 className="c-heading--1 c-mt32">選手情報登録確認</h1>
          </>
        )}
      </header>

      <main className="c-page-section">
        <h2 className="c-heading--2 c-mt32">
          {userInfo.given_name_kanji}
          {userInfo.family_name_kanji}
        </h2>

        <Form
          form={form}
          onFinish={handleConfirmRegister}
          scrollToFirstError
          initialValues={
            {
              // height: playerInfo.height,
            }
          }
          className="c-input-form"
          id="profile-input-form"
        >
          {/* 入力画面 */}
          {!isConfirm && (
            <>
              <InputPlayerForm
                isUpdate={isUpdate}
                currentPlayer={inputCurrentModel}
                form={form}
                setImageFile={setImageFile}
              />

              <div className="c-button-set c-mt56 c-mb88">
                <Button htmlType="submit" id="next" className="button button__primary">
                  次へ
                </Button>
              </div>
            </>
          )}

          {/* 確認画面 */}
          {isConfirm && (
            <>
              <div className={`${!isConfirm ? 'hidden' : ''}`}>
                <ConfirmPlayerForm dataUser={inputCurrentData} isUpdate={isUpdate} />
              </div>

              <div className="c-button-set c-mt56 c-mb88">
                <Button className="button button__secondary" id="back" onClick={() => setIsConfirm(false)}>
                  修正する
                </Button>
                <Button className="button button__primary" id="next" onClick={onClickSubmitButton}>
                  送信
                </Button>
              </div>
            </>
          )}
        </Form>
      </main>

      <footer>
        <AppFooter />
      </footer>
    </body>
  );
};

export default ProfileRegisterPage;
