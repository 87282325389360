export default class CourseDataModel {
  constructor(init = {}) {
    this.version = '';
    this.club_name = ''; // ゴルフ場名
    this.club_address = ''; // 住所
    this.club_phone = ''; // 	電話番号
    this.club_website = ''; // 	公式URL

    /**
     * key = コースID
     * atBat = 規定打数
     * name = ホール名
     * @type {{
     *   courseName: string,
     *   key: string,
     *   holeList: {
     *     atBat: string,
     *     name: string,
     *     teePositionList: {
     *       name: string,
     *       key: string,
     *       greenList: {
     *         name: string,
     *         key: string,
     *         yard: string
     *       }[]
     *     }[]
     *   }[]
     * }[]}
     */
    this.arrayCourseList = [];

    this.set(init);
  }

  /**
   * set
   * @param {Object} init
   */
  set(init) {
    // validate
    if (init === undefined || init === null) return;

    // assign
    Object.assign(this, { ...init });

    return this;
  }
}
