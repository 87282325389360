import React, { useState } from 'react';
import AppFooter from '../components/footer/AppFooter';
import LoadingScreen from '../components/LoadingScreen';
import { getFirebaseUserModel, getAppUserModel } from '../firebase/FireBaseManager';
import { AMOUNT_PAYMENT_ADULT, AMOUNT_PAYMENT_CHILD } from '../const/EnvConst';
import { tourSetTournamentPaymentUserDataService } from '../services/TournamentService';
import { PAYMENT_STATUSES, getPaymentUrlService } from '../services/PaymentService';
import { useHistory } from 'react-router';
import moment from 'moment';

/**
 *
 * @param {string} props.location.state.id 大会ID
 * @returns {JSX.Element}
 * @constructor
 */
const MainEntryParticipatePage = (props) => {
  const tournament = props.location.state;
  const firebaseUserInfo = getFirebaseUserModel();
  const userInfo = getAppUserModel();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  /**
   * 決済してエントリーするが押された時
   */
  const onClickEntry = async () => {
    setLoading(true);

    if (tournament?.id === undefined || tournament?.id === null || tournament?.id === '') {
      alert('無効なツアーです。');
      history.push('/main');
    }

    // 決済処理
    const params = {
      OrderID: userInfo?.serial_nb,
      fullName: userInfo?.given_name_kanji + ' ' + userInfo?.family_name_kanji,
      phoneNumber: firebaseUserInfo?.phoneNumber,
      mailaddress: firebaseUserInfo?.email || userInfo?.email,
      matchName: 'ツアーエントリー', // TODO:  Match Name
      retUrl: `${window.location.origin}/check-redirect-payment/${tournament.id}`,
      amount: userInfo?.isChild() ? AMOUNT_PAYMENT_CHILD : AMOUNT_PAYMENT_ADULT,
    };

    const response = await getPaymentUrlService(params);

    await tourSetTournamentPaymentUserDataService(tournament.id, {
      orderId: response.data.message.OrderID,
      status: PAYMENT_STATUSES.PROCESSING,
      isProccesing: true,
    });

    window.open(response.data.message.LinkUrl, '_self');
  };

  /**
   * ツアー内容はこちらが押された時
   */
  const onClickContents = () => {
    history.push('/test-content-confirmation');
  };

  /**
   * 戻るが押された時
   */
  const onClickBack = () => {
    history.push('/main');
  };

  /**
   * @param {TournamentDataModel} _tournament
   */
  const getTourStartTime = (tournament) => {
    if (tournament == null || tournament.startMillisecond == null || tournament.startMillisecond === '') return '';
    return moment(tournament.startMillisecond).format('YYYY年M月D日ah時');
  };

  if (loading) return <LoadingScreen />;

  return (
    <body>
      <main>
        <article className="screen screen--message">
          <div className="message">
            <div className="message message__logo">
              <img src="/images/logo--dark.svg" width="232" height="76" alt="JCG Management" />
            </div>

            <div className="message__title">
              <h1>ツアーエントリー確認</h1>
            </div>

            <div className="message__icon animation--zoomIn">
              <img src="/images/question.svg" alt="疑問符" />
            </div>

            <div className="message__content">
              <h2>{tournament?.title || ''}</h2>

              <p className="c-subtitle">{getTourStartTime(tournament)}</p>
              <p className="c-mt24">上記のツアーにエントリーします。</p>
              <p>よろしいですか？</p>
              <p className="c-warning c-mt8">
                ※ツアーのエントリーが確定すると
                <br />
                キャンセルは致しかねます。
              </p>
              <p className="c-warning c-mt8">
                ※エントリー締め切り日は各ツアー
                <br />
                開始の1週間前までです。
              </p>
              <p className="c-heading--3 c-mt32">ツアー内容を確認してからエントリーしてください。</p>
            </div>
            <div className="c-button-set">
              <button onClick={onClickContents} className="button button__secondary">
                ツアー内容はこちら
              </button>
              <button onClick={onClickBack} className="button button__secondary">
                戻る
              </button>
              <button onClick={onClickEntry} className="button button__primary c-mb88">
                決済してエントリーする
              </button>
            </div>
          </div>
        </article>
      </main>

      <footer>
        <AppFooter />
      </footer>
    </body>
  );
};

export default MainEntryParticipatePage;
