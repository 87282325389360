import firebase from 'firebase';
import { updateAppPlayerModel, updateAppUserModel, updateRegisterType } from './FireBaseManager';

/**
 * @param {String}
 * @param {Integer}
 */
function logStatusError(CF, reply) {
  console.error('CloudFunction:' + CF + ' returned: ' + reply);
}

/**
 * cloud function経由で、users_dataのDBを更新する
 * @param {{
 *   onProcessStart: () => void,
 *   onSuccess: (AppUserModel: AppUserModel) => void,
 *   onFailed: () => void
 * }} callbacks
 * @param {AppUserModel} data
 * @returns {HttpsCallable}
 */
export async function cloudFunctionUpdateInfoUser(callbacks, data) {
  // execute callback
  if (callbacks?.onProcessStart) {
    callbacks.onProcessStart();
  }

  const updateInfoUser = firebase.functions().httpsCallable('updateInfoUser');

  // data.created_at = !isUpdate ? new Date().toISOString() : data.created_at;
  // data.updated_at = isUpdate ? new Date().toISOString() : undefined;

  await updateInfoUser({
    data: data,
  })
    .then(async () => {
      if (callbacks?.onSuccess) {
        // appUserModelを更新する
        updateAppUserModel(data);
        await updateRegisterType();
        // execute callback
        if (callbacks?.onSuccess) {
          callbacks.onSuccess(data);
        }
      }
    })
    .catch((e) => {
      console.error(e);
      // execute callback
      if (callbacks?.onFailed) {
        callbacks.onFailed();
      }
    });
}

/**
 * cloud function経由で、player_dataのDBを更新する
 * @param {{
 *   onProcessStart: () => void,
 *   onSuccess: (AppPlayerModel: AppPlayerModel) => void,
 *   onFailed: () => void
 * }} callbacks
 * @param {AppPlayerModel} data
 * @returns {HttpsCallable}
 */
export async function cloudFunctionUpdateInfoPlayer(callbacks, data) {
  // execute callback
  if (callbacks?.onProcessStart) {
    callbacks.onProcessStart();
  }

  const updateInfoPlayer = firebase.functions().httpsCallable('updateInfoPlayer');

  // data.created_at = !isUpdate ? new Date().toISOString() : data.created_at;
  // data.updated_at = isUpdate ? new Date().toISOString() : undefined;

  await updateInfoPlayer({
    data: data,
  })
    .then(async () => {
      if (callbacks?.onSuccess) {
        // appUserModelを更新する
        updateAppPlayerModel(data);
        await updateRegisterType();
        // execute callback
        if (callbacks?.onSuccess) {
          callbacks.onSuccess(data);
        }
      }
    })
    .catch((e) => {
      console.error(e);
      // execute callback
      if (callbacks?.onFailed) {
        callbacks.onFailed();
      }
    });
}

/**
 * @returns {Integer}
 */
export async function isRegisterPlayer() {
  const isRegisterPlayer = firebase.functions().httpsCallable('isRegisterPlayer');

  const response = await isRegisterPlayer();

  // 未認証の場合
  if (response.data.status === 403) {
    return 0;
  }

  if (response.data.status !== 200) {
    logStatusError('isRegisterPlayer()', response.data);
    return -1;
  }

  return response.data.isRegister;
}

export async function cloudFunctionSetPassFlag(data) {
  const setPassFlag = firebase.functions().httpsCallable('setPassFlag');

  const response = await setPassFlag();

  if (response.data.status === 200) {
    data['technical_test_flg'] = 1;
    updateAppUserModel(data);
  } else {
    logStatusError('setPassFlag()', response.data);
  }
}

export async function cloudFunctionGetUsersNews() {
  const getUsersNews = firebase.functions().httpsCallable('getUsersNews');

  const response = await getUsersNews();

  if (response.data.status === 200) {
    return response.data.message.newsList;
  } else {
    logStatusError('getUsersNews()', response.data);
  }
}

export async function cloudFunctionsSetUsersNewsView(data) {
  const setNewsViewFlag = firebase.functions().httpsCallable('setNewsViewFlag');

  const response = await setNewsViewFlag({
    data: data,
  });

  if (response.data.status !== 200) {
    logStatusError('setNewsViewFlag()', response.data);
  }
}

export async function cloudFunctionsGetPaymentURL(params) {
  const getPaymentURL = firebase.functions().httpsCallable('getPaymentURL');

  return await getPaymentURL(params)
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}
