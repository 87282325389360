import React from 'react';
import CopyRightOnlyFooter from '../components/footer/CopyRightOnlyFooter';

const PrivacyPolicyPage = () => {
  /**
   * 閉じるが押された時
   */
  const onClickClose = () => {
    window.close();
  };

  return (
    <body>
      <header className="c-container-vertical c-container-vertical--center c-prl--16">
        <div className="c-logo--144 c-mt32">
          <img src="images/logo--dark.svg" className="c-logo__source" width="232" height="76" alt="JCG Management" />
        </div>

        <h1 className="c-heading--1 c-mt56">プライバシーポリシー</h1>
      </header>
      <main className="c-container-vertical c-container-vertical--608">
        <p className="c-paragraph c-mt32">
          株式会社JCGマネージメント（以下「当社」という）は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。
        </p>
        <h2 className="c-heading--2 c-mt32">1.個人情報の管理</h2>
        <p className="c-paragraph c-mt16">
          当社は、お客様より取得した個人情報を正確かつ最新の状態に保ち、当社業務に必要な範囲を超えて個人情報を利用せず、個人情報への不正アクセス・紛失・破損・改竄・漏洩等を防止する為、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の措置を講じ、安全対策を実施し個人情報の厳重な管理を行います。
        </p>
        <h2 className="c-heading--2 c-mt32">2.個人情報の利用目的</h2>
        <p className="c-paragraph c-mt16">
          当社は、お客様から取得した個人情報は、当社からの連絡や業務のご案内、及びご質問に対する回答として、電子メールや電話・郵送等の各通知方法を用いる際に利用いたします。
        </p>
        <h2 className="c-heading--2 c-mt32">3.個人情報の第三者への開示・提供について</h2>
        <p className="c-paragraph c-mt16">
          当社は、お客様より取得した個人情報を適切に管理し、下記のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。
        </p>
        <ul className="c-list c-mt16">
          <li className="c-list__item">お客様の同意がある場合</li>
          <li className="c-list__item">
            当社のサービスを改善・継続等を実施する際に当社が規定する個人情報の取扱いに関する管理体制・教育をした委託業者に対し開示する場合
          </li>
          <li className="c-list__item">法令に基づき開示することが必要である場合</li>
        </ul>
        <h2 className="c-heading--2 c-mt32">4.個人情報の安全対策</h2>
        <p className="c-paragraph c-mt16">
          当社は、個人情報の正確性及び安全確保の為に、セキュリティに万全の対策を講じます。
        </p>
        <h2 className="c-heading--2 c-mt32">5.ご本人の照会</h2>
        <p className="c-paragraph c-mt16">
          お客様がご本人の個人情報の照会・修正・削除等をご希望される場合には、ご本人であることを確認の上、対応させていただきます。
        </p>
        <h2 className="c-heading--2 c-mt32">6.個人情報に関する法令及び規範の遵守と見直し</h2>
        <p className="c-paragraph c-mt16">
          当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
        </p>
        <h2 className="c-heading--2 c-mt32">7.お問合せ</h2>
        <p className="c-paragraph c-mt16">当社の個人情報の取扱に関するお問い合わせは下記までにご連絡ください。</p>

        <p className="c-paragraph c-mt56">株式会社JCGマネージメント</p>
        <p className="c-subtitle">〒541-0055</p>
        <p className="c-subtitle">大阪市中央区船場中央３－３－９</p>
        <p className="c-subtitle">船場センタービル９号館1階</p>
        <p className="c-subtitle">TEL: 06-6125-5778</p>
        <p className="c-subtitle">Mail: info@jcg-management.co.jp</p>

        <div className="c-button-set c-mt56 c-mb88">
          <button id="back" onClick={onClickClose} className="button button__primary">
            閉じる
          </button>
        </div>
      </main>

      <footer>
        <CopyRightOnlyFooter />
      </footer>
    </body>
  );
};

export default PrivacyPolicyPage;
